import React, { FC } from 'react'

import { Typography, useTranslate, FNOLPageWrapper, FNOLButton } from '@dg-shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events, FNOLClaimTypes } from '../../../FSM/shared/constants'
import { TRANSLATIONS as T } from '../../../constants'
import { useFNOLStyles } from '../shared/styles/useStyles'

const PageList: FC<CommonPageProps> = ({ service }) => {
  const FNOLClasses = useFNOLStyles({})

  const { send } = service
  const { t } = useTranslate(T.CLAIMS)

  const claimTypeSelect = ({
    claimTypeEvent,
    label,
    claimType,
  }: {
    claimTypeEvent: Events
    label: string
    claimType: FNOLClaimTypes
  }) => (
    <FNOLButton
      key={claimType}
      handleClick={() => send({ type: claimTypeEvent, claimType: claimType })}
      buttonText={label}
    />
  )

  const claimTypeEvents = [
    {
      claimTypeEvent: Events.FNOL_WINDSHIELD,
      label: `${t('selectType.button.windshield')}`,
      claimType: FNOLClaimTypes.FNOL_WINDSHIELD,
    },
    {
      claimTypeEvent: Events.FNOL_COLLISION,
      label: `${t('selectType.button.collision')}`,
      claimType: FNOLClaimTypes.FNOL_COLLISION,
    },
    {
      claimTypeEvent: Events.FNOL_THEFT,
      label: `${t('selectType.button.theft')}`,
      claimType: FNOLClaimTypes.FNOL_THEFT,
    },
    {
      claimTypeEvent: Events.FNOL_ROADSIDE_ASSISTANCE,
      label: `${t('selectType.button.roadsideAssistance')}`,
      claimType: FNOLClaimTypes.FNOL_ROADSIDE_ASSISTANCE,
    },
    {
      claimTypeEvent: Events.FNOL_OTHER,
      label: `${t('selectType.button.other')}`,
      claimType: FNOLClaimTypes.FNOL_OTHER,
    },
  ]

  const ClaimTypesList = () => (
    <>
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('selectType.title')}
      </Typography>
      <Typography testId='subtitle' html={true} className={FNOLClasses.description}>
        {t('selectType.subtitle')}
      </Typography>
      {claimTypeEvents.map((item) => claimTypeSelect(item))}
    </>
  )

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t(`${T.COMMON}/back`)} breadcrumbsStepPercentage={20}>
      <ClaimTypesList />
    </FNOLPageWrapper>
  )
}

export default PageList

import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { GET_DOCUMENT_FILE } from '../document/document.query'

//FIXME: Provide proper return type
export const policyFileGetter = async (
  client: ApolloClient<NormalizedCacheObject>,
  fileId: number,
  rName = 'policyFile'
): Promise<Record<string, string>> => {
  const response = await client.query({
    query: GET_DOCUMENT_FILE,
    fetchPolicy: 'no-cache',
    variables: { fileId },
  })

  return response.data[rName]
}

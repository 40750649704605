import { TranslatedTextType, withHidden } from '@dg-shared/index'
import React, { FC, ReactElement, useCallback } from 'react'
import { FlowService } from '../../FSM'
import { LMCommonProps } from '../../types/MLCommonProps'
import {
  LMBasicV3FSMProps,
  LDCardTwoColV2FSMProps,
  LDContainerV2FSMProps,
} from '../../types/interface/layouts.interface'
import { LMBgHeaderProps } from '@dg-shared/layouts/LMBgHeader'
import { LDCardTwoColProps } from '@dg-shared/layouts/LDCardTwoCol'
import { LDCardTwoColV2Props } from '@dg-shared/layouts/LDCardTwoColV2'
import { LDContainerV2PropsType } from '@dg-shared/layouts/LDContainerV2'
import { LDContentCardPropsType } from '@dg-shared/layouts/LDContentCard'

export type LayoutDesktopType =
  | LDCardTwoColProps
  | LDCardTwoColV2Props
  | LDCardTwoColV2FSMProps
  | LDContainerV2PropsType
  | LDContainerV2FSMProps
  | LDContentCardPropsType

export type LayoutMobileType = LMCommonProps | LMBgHeaderProps | LMBasicV3FSMProps

export type PageWrapperPropsType = {
  children: ReactElement | ReactElement[]
  breadcrumbsBtnText?: TranslatedTextType
  breadcrumbsStepPercentage?: number
  mobile: React.FC<LayoutMobileType>
  desktop: React.FC<LayoutDesktopType>
  // Other props
  [x: string]: unknown
}

// This page wrapper combines both Desktop & Mobile view page
// only in case of the same children content (it is hard to implement more generic approach for all cases)
const PageWrapper: FC<PageWrapperPropsType> = ({
  children,
  breadcrumbsBtnText,
  breadcrumbsStepPercentage,
  mobile,
  desktop,
  ...props
}) => {
  const { send } = FlowService
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const MobileScreen = useCallback(withHidden(mobile, true), [mobile])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const DesktopScreen = useCallback(withHidden(desktop, false), [desktop])

  return (
    <>
      <MobileScreen
        breadcrumbsBtnText={breadcrumbsBtnText}
        send={send}
        breadcrumbs={!!breadcrumbsBtnText}
        breadcrumbsStepPercentage={breadcrumbsStepPercentage}
        {...props}
      >
        {children}
      </MobileScreen>

      <DesktopScreen
        breadcrumbsBtnText={breadcrumbsBtnText}
        send={send}
        breadcrumbs={!!breadcrumbsBtnText}
        breadcrumbsStepPercentage={breadcrumbsStepPercentage}
        {...props}
      >
        {children}
      </DesktopScreen>
    </>
  )
}

export default PageWrapper

import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { SET_QUOTE_COMPETITOR_PRICE } from '../quote/mutation'

export const quoteCompetitorPriceMutator = async (
  client: ApolloClient<NormalizedCacheObject>,
  quoteId: number,
  price: number
): Promise<boolean> => {
  const response = await client.mutate({
    mutation: SET_QUOTE_COMPETITOR_PRICE,
    variables: { quoteId, price },
  })

  return response.data
}

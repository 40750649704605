import { isObject, isEqual } from 'lodash'
import { State, StateValueMap } from 'xstate'

import { FSMStorageKey } from './shared/constants'
import { getPID } from '@dg-util'
import { FlowMachineType, FSMStateType } from '../types/interface/fsm.interface'

const removePersistedState = (): void => localStorage.removeItem(FSMStorageKey)

const getPersistedState = (machine: FlowMachineType) => {
  const stateFetched = JSON.parse(localStorage.getItem(FSMStorageKey))

  if (!stateFetched) return machine.resolveState(State.create(machine.initialState))

  const isEqualPID = getPID() === stateFetched.productId

  if (!isEqualPID) {
    removePersistedState()
    return
  }
  if (isEqualPID) return machine.resolveState(State.create(stateFetched))
}

const persistState = async (stateFSM: FSMStateType) => {
  const productId = getPID()
  const jsonState = JSON.stringify({ ...stateFSM, productId })

  try {
    await localStorage.setItem(FSMStorageKey, jsonState)
  } catch (e) {
    throw new Error('Inability to save the state')
  }
}

const getObjectValue = (value: StateValueMap | string): string => {
  return isObject(value) ? getObjectValue(value[Object.keys(value)[0]]) : value
}

const setClientHistory = (value: string | StateValueMap): void => {
  const title = getObjectValue(value)
  // "history.state" equals to "value" only in case user press browser's back button
  // and browser's history state already updated. In this case there is no need to update history.
  if (!isEqual(history.state, value)) {
    history.pushState(value, title as string, title as string)
  }
}

export { getPersistedState, removePersistedState, persistState, setClientHistory }

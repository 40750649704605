import React, { FC } from 'react'
import { Trans } from 'react-i18next'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { CardActionArea, CardMedia, CardContent, Card } from '@material-ui/core'

import { ClaimItem } from '../../types/interface/claims.interface'
import { Typography } from '@dg-shared'
import { exampleCarImg } from 'BrandedImages'

const usePolicyCardStyles = makeStyles((theme) =>
  createStyles({
    itemCardMediaImage: {
      display: 'block',
      padding: '40px 40px 5px',
    },
    cardTextContainer: {
      width: '100%',
    },
    vehicleInformationText: {
      fontSize: '1.25rem',
      wordBreak: 'break-word',
      fontWeight: 500,
    },
    cardHover: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'start',
      '&:hover': {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: '10px',
        backgroundColor: theme.palette.primary.light,
      },
    },
  })
)

interface ClaimCardProps {
  data: ClaimItem
  handleClick(): void
}

const PolicyCard: FC<ClaimCardProps> = ({ data, handleClick }) => {
  const classes = usePolicyCardStyles()
  const isNotEmptyTitle = !!data.registrationYear || !!data.carMake || !!data.carModel

  return (
    <Card elevation={3} onClick={() => handleClick()} data-testid={'policy-card'}>
      <CardActionArea className={classes.cardHover}>
        <CardMedia
          component='img'
          className={classes.itemCardMediaImage}
          alt='Example Car'
          image={exampleCarImg}
          title='Example Car'
        />
        <CardContent className={classes.cardTextContainer}>
          {isNotEmptyTitle && (
            <Typography
              type={'h2'}
              testId={'vehicle-info'}
            >{`${data?.registrationYear} ${data?.carMake} ${data?.carModel}`}</Typography>
          )}

          {!!data.licensePlateNumber && (
            <Typography testId={'reg-num'}>
              <Trans i18nKey='common.regNum' values={{ regNum: data.licensePlateNumber }} />
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default PolicyCard

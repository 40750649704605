import { gql } from 'graphql-tag'

//TODO: Not used! Not tested! Not schema generated!
export const GET_PRODUCT_PROPS = gql`
  query GetProductProps {
    getProductProps {
      ... on GraphOptionsVehicle {
        productType
        deductible {
          value
          text
        }
        coverageType {
          value
          text
        }
        bonus {
          value
          text
        }
        mileageAnnual {
          value
          text
        }
      }
      ... on GraphOptionsTravel {
        productType
        coverage {
          value
          text
        }
        coverArea {
          value
          text
        }
        claimsPrev {
          value
          text
        }
      }
    }
  }
`
//TODO: Not tested! Not schema generated!
export const GET_SINGLE_DATASOURCE = gql`
  query GetSingleDatasource($datasourceId: Float!) {
    getSingleDatasource(datasourceId: $datasourceId) {
      list {
        text
        value
      }
    }
  }
`

export const GET_INSURANCE_COMPANIES_LIST = gql`
  query GetInsuranceCompaniesList {
    getInsuranceCompaniesList {
      insuranceCompanies {
        value
        text
      }
      productType
    }
  }
`

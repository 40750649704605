import React, { FC } from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { FORM_ID, TRANSLATIONS as T } from '../../../constants'
import { getStepPercentage } from '@dg-util'
import useLogic from './useLogic'
import { Typography, LDContainerV2FSM, LMBasicV3FSM, useTranslate } from '@dg-shared'
import BrandConfig from 'BrandConfig'
import FormBuilder from '@dg-shared/forms/FormBuilder'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import PageWrapper from '@dg-shared/PageWrapper'

const useFindStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      margin: `${theme.spacing(3)}px auto`,
      width: '100%',
      height: '100%',
      maxWidth: '424px',
      [theme.breakpoints.down('sm')]: {
        margin: 'auto',
        marginTop: theme.spacing(3),
        marginBottom: 0,
      },
    },
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(7),
    },
  })
)

const OffersFind: FC<CommonPageProps> = ({ service, currentState }) => {
  const { t } = useTranslate(T.FIND)
  const classes = useFindStyles()
  const client = useApolloClient()
  const stepPercentage = getStepPercentage(currentState)
  const { isProductEmpty, title, btnBackText, handleSubmit } = useLogic(client, service)

  const body = (
    <section className={classes.body}>
      <FormBuilder
        id={FORM_ID.FIND}
        submitHandler={handleSubmit}
        errorText={isProductEmpty && t(`${T.ERROR}:vehicleEmpty`, { email: BrandConfig.EMAIL })}
      />
    </section>
  )

  return (
    <PageWrapper
      mobile={LMBasicV3FSM}
      desktop={LDContainerV2FSM}
      breadcrumbsBtnText={t(btnBackText)}
      breadcrumbsStepPercentage={stepPercentage}
      bgColor={`#FAFAFA`} // FIXME: Remove this prop when Background color will be the same on all pages
    >
      <Typography type='h1' className={classes.title}>
        {t(title)}
      </Typography>
      {body}
    </PageWrapper>
  )
}
export default OffersFind

import * as Sentry from '@sentry/react'
import { GraphQLError } from 'graphql'

import { PartnerName } from '../types/interface/quote.interface'
import { ServiceError } from '../types/generated/graphql'

const errorLoggerService = {
  partner: '',
  setPartner: (partnerName: PartnerName) => {
    errorLoggerService.partner = partnerName
  },
  captureMessage: (message: string, extra: Record<string, unknown>) => {
    Sentry.withScope((scope) => {
      scope.setTag('partner', errorLoggerService.partner)
      scope.setExtras(extra)

      Sentry.captureMessage(message, extra)
    })
  },
  captureException: (
    exception: GraphQLError | Error | ServiceError,
    extra: Record<string, unknown>
  ) => {
    Sentry.withScope((scope) => {
      scope.setTag('partner', errorLoggerService.partner)
      scope.setExtras(extra)

      if (exception instanceof GraphQLError) {
        exception.message = `[${exception.code}] Error on ${extra.operation} with message: '${extra.message}'`
      }

      if ('code' in exception) {
        scope.setFingerprint([JSON.stringify(extra.operation), exception?.code.toString()])
      }

      Sentry.captureEvent({
        message: 'message' in exception ? exception.message : 'NetworkError',
        level: 'error',
      })
    })
  },
}

export default errorLoggerService

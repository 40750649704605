import React, { FC } from 'react'

import { Typography, useTranslate, FNOLPageWrapper, FNOLButton } from '@dg-shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events } from '../../../FSM/shared/constants'
import { TRANSLATIONS as T } from '../../../constants'
import { useFNOLStyles } from '../shared/styles/useStyles'

const PageCollision: FC<CommonPageProps> = ({ service }) => {
  const { send } = service
  const { t } = useTranslate(T.COLLISION)
  const FNOLClasses = useFNOLStyles({ externalLink: true })

  const collisionTypeSelect = ({
    collisionType,
    label,
  }: {
    collisionType: Events
    label: string
  }) => (
    <FNOLButton
      testId={label}
      key={collisionType}
      handleClick={() => send(collisionType)}
      buttonText={t(label)}
    />
  )

  const collisionTypeEvents = [
    { collisionType: Events.FNOL_COLLISION_HOW_MANY, label: 'mainPageButtons.howMany' },
    { collisionType: Events.FNOL_COLLISION_ANIMAL, label: 'mainPageButtons.animal' },
    {
      collisionType: Events.FNOL_COLLISION_OBJECT,
      label: 'mainPageButtons.object',
    },
    { collisionType: Events.FNOL_COLLISION_INJURED, label: 'mainPageButtons.building' },
    {
      collisionType: Events.FNOL_COLLISION_INJURED,
      label: 'mainPageButtons.pedestrianOrCyclist',
    },
    { collisionType: Events.FNOL_COLLISION_INJURED, label: 'mainPageButtons.other' },
  ]

  const Content = () => (
    <>
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('title')}
      </Typography>
      <Typography testId='subtitle' html={true} className={FNOLClasses.description}>
        {t('subtitle')}
      </Typography>
      {collisionTypeEvents.map((item) => collisionTypeSelect(item))}
    </>
  )

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t(`${T.COMMON}/back`)} breadcrumbsStepPercentage={30}>
      <Content />
    </FNOLPageWrapper>
  )
}

export default PageCollision

import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { TranslatedTextType } from '@dg-shared/hooks/useTranslate'

interface ButtonNext {
  buttonText: TranslatedTextType
  customClassName?: string
  disabled?: boolean
  outlined?: boolean
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  handleClick?(): void
  testId?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonNext: (outlined) => ({
      fontSize: '1rem',
      border: outlined ? `1px solid ${theme.palette.primary.main}` : 'none',
      boxShadow: outlined && 'none',
      maxWidth: '345px',
      textTransform: 'none',
    }),
    button: {
      justifyContent: 'left',
      margin: theme.spacing(2),
      padding: theme.spacing(2),
      color: theme.brand.colors.main,
      backgroundColor: theme.palette.background.paper,
      '&:hover': {
        color: theme.palette.primary,
        backgroundColor: theme.brand.colors.main,
        opacity: 1,
      },
    },
  })
)

const FNOLButton: FC<ButtonNext> = ({
  buttonText,
  customClassName,
  disabled = false,
  outlined = false,
  startIcon,
  endIcon,
  handleClick,
  testId = 'FNOLButton',
}) => {
  const classes = useStyles(outlined)

  return (
    <Button
      data-testid={testId}
      disabled={disabled}
      onClick={handleClick}
      variant='contained'
      size='medium'
      className={`${classes.buttonNext} ${classes.button} ${customClassName}`}
      fullWidth
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {buttonText}
    </Button>
  )
}

export default FNOLButton

import React, { FC } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

import { TRANSLATIONS as T } from '../../../constants'
import { Events, FSMCommonTransitions as FSMT } from '../../../FSM/shared/constants'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Typography, FSMBtnNext, useTranslate, FNOLPageWrapper } from '@dg-shared'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnNext: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.dark,
      textAlign: 'left',
      textTransform: 'none',
      justifyContent: 'flex-start',
      fontWeight: 400,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(5),
      gap: theme.spacing(4),
    },
  })
)

const PageAnnet: FC<CommonPageProps> = ({ service }) => {
  const { t } = useTranslate(T.FNOL_OTHER_START)
  const classes = useStyles()
  const { send } = service

  const content = (
    <section className={classes.wrapper}>
      <Typography type='h1'>{t('title')}</Typography>
      <Typography>{t('subtitle')}</Typography>
      <FSMBtnNext
        customClassName={classes.btnNext}
        handleClick={() => send({ type: Events.NEXT, flowName: FSMT.FNOL_OTHER_VANDALISM })}
        buttonText={t('button.vandalism')}
      />
      <FSMBtnNext
        customClassName={classes.btnNext}
        handleClick={() => send({ type: Events.NEXT, flowName: FSMT.FNOL_OTHER_MACHINE_DAMAGE })}
        buttonText={t('button.machineDamage')}
      />
      <FSMBtnNext
        customClassName={classes.btnNext}
        handleClick={() => send({ type: Events.NEXT, flowName: FSMT.FNOL_OTHER_ERROR_FILLING })}
        buttonText={t('button.errorFilling')}
      />
      <FSMBtnNext
        customClassName={classes.btnNext}
        handleClick={() => send({ type: Events.NEXT, flowName: FSMT.FNOL_OTHER_LOST_KEY })}
        buttonText={t('button.lostKey')}
      />
      <FSMBtnNext
        customClassName={classes.btnNext}
        handleClick={() => send({ type: Events.NEXT, flowName: FSMT.FNOL_OTHER_LEGAL_AID })}
        buttonText={t('button.legalAid')}
      />
      <FSMBtnNext
        customClassName={classes.btnNext}
        handleClick={() => send({ type: Events.NEXT, flowName: FSMT.FNOL_OTHER_FIRE })}
        buttonText={t('button.fire')}
      />
    </section>
  )

  return (
    <FNOLPageWrapper breadcrumbsStepPercentage={30} breadcrumbsBtnText={t(`${T.COMMON}/back`)}>
      {content}
    </FNOLPageWrapper>
  )
}

export default PageAnnet

import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { persistCache } from 'apollo-cache-persist'

import { IS_LOCAL } from '../config'
import introspectionQueryResultData from '../types/generated/fragmentTypes.json'

const initCache = (): InMemoryCache => {
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  })

  return new InMemoryCache({ fragmentMatcher })
}

export const initPersistentCache = async (
  cache: InMemoryCache,
  storageKey: string
): Promise<void> =>
  await persistCache({
    cache,
    storage: window.localStorage,
    key: storageKey,
    debug: IS_LOCAL,
    trigger: 'write',
  })

export default initCache

import React, { FC } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { TranslatedTextType, Typography } from '@dg-shared'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    event: {
      position: 'relative',
      counterIncrement: 'li',
      marginBottom: theme.spacing(5),
      '&.active:before': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.success.contrastText,
        border: 'none',
      },
      '&:before': {
        display: 'flex',
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        top: '5px',
        left: '-66px',
        width: '50px',
        height: '50px',
        content: 'counter(li)',
        border: `1px solid ${theme.palette.secondary.dark}`,
        backgroundColor: theme.palette.success.contrastText,
        borderRadius: theme.spacing(1),
        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
        color: theme.palette.secondary.dark,
        fontWeight: 700,
        fontSize: '18px',
      },
    },
    stepSubHeader: {
      margin: `0 0 ${theme.spacing(1)}px`,
    },
    stepHeader: {
      margin: '0 0 6px',
      '&.active': {
        color: `${theme.brand.colors.main} !important`,
      },
    },
    stepContent: {},
  })
)

interface TimelineItemProps {
  headerTxt: TranslatedTextType
  contentTxt: TranslatedTextType
  subHeaderTxt: TranslatedTextType
  active: boolean
}

const TimelineItem: FC<TimelineItemProps> = ({ headerTxt, contentTxt, subHeaderTxt, active }) => {
  const classes = useStyles()
  const activeClass = `${active ? 'active' : ''}`

  return (
    <li className={`${classes.event} ${activeClass}`}>
      <Typography type='h6' className={classes.stepSubHeader} color='textSecondary'>
        {subHeaderTxt}
      </Typography>
      <Typography
        type='h3'
        className={`${classes.stepHeader} ${activeClass}`}
        color='textSecondary'
      >
        {headerTxt}
      </Typography>
      <Typography className={classes.stepContent}>{contentTxt}</Typography>
    </li>
  )
}

export default TimelineItem

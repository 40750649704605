import { isNil } from 'lodash'
import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import { ERROR_CODE_QUOTE_GENERAL } from '../../constants'

import { ErrorCode } from '../../types/interface/error.interface'
import { ADD_CLAIM_LOCAL } from './mutation'

export const updateMutator = async (
  client: ApolloClient<NormalizedCacheObject>,
  body: Record<string, unknown>,
  rName = 'addClaimLocal'
): Promise<[Record<string, unknown> | null, ErrorCode | null]> => {
  const response = await client.mutate({
    mutation: ADD_CLAIM_LOCAL,
    variables: {
      body,
    },
  })

  if (!isNil(response.errors)) return [null, ERROR_CODE_QUOTE_GENERAL]

  return [response.data[rName], null]
}

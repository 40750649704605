import { gql } from 'graphql-tag'

import { FRAGMENT_SESSION_ITEMS } from '../fragments/SessionItems.fragment'

export const AUTH_USER = gql`
  mutation AuthUser($data: UserAuthInput!, $reCaptchaToken: String) {
    authUser(data: $data, reCaptchaToken: $reCaptchaToken) {
      ... on Session {
        ...SessionItems
      }
    }
  }
  ${FRAGMENT_SESSION_ITEMS}
`

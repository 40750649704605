import React, { FC, ReactElement } from 'react'

import { withHidden, LMBasicV3FSM, LDContentCardFSM, TranslatedTextType } from '@dg-shared'
import { FlowService } from '../../FSM'
import { createStyles, makeStyles } from '@material-ui/core/styles'

const MobileScreen = withHidden(LMBasicV3FSM, true)
const DesktopScreen = withHidden(LDContentCardFSM, false)

export type FNOLPageWrapper = {
  children: ReactElement | ReactElement[]
  breadcrumbsBtnText: TranslatedTextType
  breadcrumbsStepPercentage?: number
}

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      alignItems: 'center',
      flexDirection: 'column',
      display: 'flex',
      margin: '0 auto',
      maxWidth: 425,
    },
  })
)

// This page wrapper combines both Desktop & Mobile view page view for FNOL
const FNOLPageWrapper: FC<FNOLPageWrapper> = ({
  children,
  breadcrumbsBtnText,
  breadcrumbsStepPercentage,
}) => {
  const { send } = FlowService
  const classes = useStyles()

  return (
    <>
      <MobileScreen
        breadcrumbsBtnText={breadcrumbsBtnText}
        send={send}
        breadcrumbs={!!breadcrumbsBtnText}
        breadcrumbsStepPercentage={breadcrumbsStepPercentage}
      >
        <div className={classes.wrapper}>{children}</div>
      </MobileScreen>

      <DesktopScreen
        breadcrumbsBtnText={breadcrumbsBtnText}
        send={send}
        breadcrumbs={!!breadcrumbsBtnText}
        breadcrumbsStepPercentage={breadcrumbsStepPercentage}
      >
        <div className={classes.wrapper}>{children}</div>
      </DesktopScreen>
    </>
  )
}

export default FNOLPageWrapper

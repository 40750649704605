import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { UPLOAD_FILES } from './mutation'

export const uploadFiles = async (
  client: ApolloClient<NormalizedCacheObject>,
  files: Array<string>,
  rName = 'uploadFiles'
): Promise<boolean> => {
  const response = await client.mutate({
    mutation: UPLOAD_FILES,
    variables: {
      data: {
        files,
      },
    },
  })

  return response.data[rName]
}

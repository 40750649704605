import { Events } from '../../../../../../FSM/shared/constants'
import { FSMTransitionsFNOLVehicleSE } from '../constants'
import { has, get } from 'lodash'
import { ContextType } from '../../../../../../FSM/shared/config'

const {
  FLOW_MACHINE_ID,
  AUTH_BANK_ID,
  FNOL_CLAIMS,
  FNOL_CLAIMS_LIST,
  FNOL_COLLISION_FLOW,
  FNOL_COLLISION_WHAT_HAPPENED,
  FNOL_COLLISION_HOW_MANY,
  FNOL_COLLISION_ANIMAL,
  FNOL_COLLISION_OBJECT,
  FNOL_COLLISION_RESPONSIBLE,
  FNOL_COLLISION_INJURED,
  FNOL_COLLISION_CASE_OF_ACCIDENT,
  FNOL_COLLISION_REGISTRATION_NUM,
  FNOL_COLLISION_WHEN_WHERE,
  FNOL_COLLISION_ACCIDENT_FORM,
  FNOL_COLLISION_DAMAGE_REPORT,
  FNOL_ADDITIONAL_INFORMATION,
  FNOL_COLLISION_MORE_DETAILS,
  FNOL_TIME_LINE,
} = FSMTransitionsFNOLVehicleSE

export default {
  [FNOL_COLLISION_FLOW]: {
    initial: FNOL_COLLISION_WHAT_HAPPENED,
    states: {
      [FNOL_COLLISION_WHAT_HAPPENED]: {
        on: {
          [Events.BACK]: `#${FLOW_MACHINE_ID}.${FNOL_CLAIMS}.${FNOL_CLAIMS_LIST}`,
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
          [Events.FNOL_COLLISION_HOW_MANY]: FNOL_COLLISION_HOW_MANY,
          [Events.FNOL_COLLISION_ANIMAL]: FNOL_COLLISION_ANIMAL,
          [Events.FNOL_COLLISION_OBJECT]: FNOL_COLLISION_OBJECT,
          [Events.FNOL_COLLISION_INJURED]: FNOL_COLLISION_INJURED,
        },
      },
      [FNOL_COLLISION_HOW_MANY]: {
        on: {
          [Events.BACK]: FNOL_COLLISION_WHAT_HAPPENED,
          [Events.FNOL_COLLISION_RESPONSIBLE]: FNOL_COLLISION_RESPONSIBLE,
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_COLLISION_ANIMAL]: {
        on: {
          [Events.BACK]: FNOL_COLLISION_WHAT_HAPPENED,
          [Events.NEXT]: { target: FNOL_COLLISION_INJURED, actions: 'setBackPath' },
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_COLLISION_OBJECT]: {
        on: {
          [Events.BACK]: FNOL_COLLISION_WHAT_HAPPENED,
          [Events.NEXT]: { target: FNOL_COLLISION_INJURED, actions: 'setBackPath' },
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_COLLISION_RESPONSIBLE]: {
        on: {
          [Events.BACK]: FNOL_COLLISION_HOW_MANY,
          [Events.FNOL_COLLISION_INJURED]: {
            target: FNOL_COLLISION_INJURED,
            actions: 'setBackPath',
          },
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_COLLISION_INJURED]: {
        on: {
          [Events.BACK]: [
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_COLLISION_FLOW}.${FNOL_COLLISION_ANIMAL}`,
              cond: (ctx: ContextType) =>
                has(ctx.backPath, FNOL_COLLISION_FLOW) &&
                get(ctx.backPath, FNOL_COLLISION_FLOW) === FNOL_COLLISION_ANIMAL,
            },
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_COLLISION_FLOW}.${FNOL_COLLISION_RESPONSIBLE}`,
              cond: (ctx: ContextType) =>
                has(ctx.backPath, FNOL_COLLISION_FLOW) &&
                get(ctx.backPath, FNOL_COLLISION_FLOW) === FNOL_COLLISION_RESPONSIBLE,
            },
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_COLLISION_FLOW}.${FNOL_COLLISION_OBJECT}`,
              cond: (ctx: ContextType) =>
                has(ctx.backPath, FNOL_COLLISION_FLOW) &&
                get(ctx.backPath, FNOL_COLLISION_FLOW) === FNOL_COLLISION_OBJECT,
            },
          ],
          [Events.FNOL_COLLISION_CASE_OF_ACCIDENT]: {
            target: FNOL_COLLISION_CASE_OF_ACCIDENT,
            action: 'setBackPath',
          },
          [Events.FNOL_COLLISION_MORE_DETAILS]: {
            target: FNOL_COLLISION_MORE_DETAILS,
            actions: 'setBackPath',
          },
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_COLLISION_CASE_OF_ACCIDENT]: {
        on: {
          [Events.BACK]: FNOL_COLLISION_INJURED,
          [Events.FNOL_COLLISION_REGISTRATION_NUM]: FNOL_COLLISION_REGISTRATION_NUM,
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_COLLISION_REGISTRATION_NUM]: {
        on: {
          [Events.BACK]: FNOL_COLLISION_CASE_OF_ACCIDENT,
          [Events.NEXT]: { target: FNOL_COLLISION_WHEN_WHERE, actions: 'setBackPath' },
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_COLLISION_WHEN_WHERE]: {
        on: {
          [Events.BACK]: [
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_COLLISION_FLOW}.${FNOL_COLLISION_REGISTRATION_NUM}`,
            },
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_COLLISION_FLOW}.${FNOL_COLLISION_MORE_DETAILS}`,
              cond: (ctx: ContextType) =>
                has(ctx.backPath, FNOL_COLLISION_FLOW) &&
                get(ctx.backPath, FNOL_COLLISION_FLOW) === FNOL_COLLISION_MORE_DETAILS,
            },
          ],
          [Events.NEXT]: [
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_COLLISION_FLOW}.${FNOL_COLLISION_ACCIDENT_FORM}`,
              cond: (ctx: ContextType) =>
                has(ctx.backPath, FNOL_COLLISION_FLOW) &&
                get(ctx.backPath, FNOL_COLLISION_FLOW) === FNOL_COLLISION_REGISTRATION_NUM,
            },
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_COLLISION_FLOW}.${FNOL_ADDITIONAL_INFORMATION}`,
              cond: (ctx: ContextType) =>
                has(ctx.backPath, FNOL_COLLISION_FLOW) &&
                get(ctx.backPath, FNOL_COLLISION_FLOW) === FNOL_COLLISION_MORE_DETAILS,
            },
          ],
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_COLLISION_ACCIDENT_FORM]: {
        on: {
          [Events.BACK]: FNOL_COLLISION_WHEN_WHERE,
          [Events.NEXT]: FNOL_COLLISION_DAMAGE_REPORT,
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_COLLISION_DAMAGE_REPORT]: {
        on: {
          [Events.BACK]: FNOL_COLLISION_ACCIDENT_FORM,
          [Events.NEXT]: { target: FNOL_ADDITIONAL_INFORMATION, actions: 'setBackPath' },
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_ADDITIONAL_INFORMATION]: {
        on: {
          [Events.BACK]: [
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_COLLISION_FLOW}.${FNOL_COLLISION_DAMAGE_REPORT}`,
              cond: (ctx: ContextType) =>
                has(ctx.backPath, FNOL_COLLISION_FLOW) &&
                get(ctx.backPath, FNOL_COLLISION_FLOW) === FNOL_COLLISION_DAMAGE_REPORT,
            },
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_COLLISION_FLOW}.${FNOL_COLLISION_WHEN_WHERE}`,
              cond: (ctx: ContextType) =>
                has(ctx.backPath, FNOL_COLLISION_FLOW) &&
                get(ctx.backPath, FNOL_COLLISION_FLOW) === FNOL_COLLISION_WHEN_WHERE,
            },
          ],
          [Events.NEXT]: FNOL_TIME_LINE,
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_COLLISION_MORE_DETAILS]: {
        on: {
          [Events.BACK]: FNOL_COLLISION_DAMAGE_REPORT,
          [Events.NEXT]: { target: FNOL_COLLISION_WHEN_WHERE, actions: 'setBackPath' },
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_TIME_LINE]: {
        on: {
          [Events.BACK]: FNOL_ADDITIONAL_INFORMATION,
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
    },
  },
}

import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

import { TRANSLATIONS as T } from '../../../../constants/translations-constants'
import { PartnerName, ProductName } from '../../../../types/interface/quote.interface'
import { VehicleNo, VehicleSe } from '../../../../types/generated/graphql'
import { Typography, useTranslate, ProductWrapper } from '@dg-shared'
import CardPrice from './CardPrice'
import { ServiceSend } from '../../../../types/interface/fsm.interface'
import { InsurelyResultType } from '../../../../types/insurelyTypes'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerCards: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(2),
    },
  })
)

interface PriceTwinCardsProps {
  quote: VehicleNo | VehicleSe
  offerToCompare: InsurelyResultType
  send?: ServiceSend
}

const PriceTwinCards: FC<PriceTwinCardsProps> = ({ offerToCompare, quote, send }) => {
  const { t } = useTranslate(T.OFFER_COMPARE_PRICE)
  const classes = useStyles()

  return (
    <section className={classes.containerCards} data-testid='price-twin-cards'>
      <CardPrice
        send={send}
        annotation={t('card.title.0')}
        btnText={t('card.button')}
        price={quote.priceAnnual.toString()}
        priceText={t(`card.priceText`)}
      />

      <CardPrice
        price={offerToCompare.insurance.premiumAmountYearRounded.toString()}
        annotation={t('card.title.1')}
        priceText={t(`card.priceText`)}
        coverageName={offerToCompare.insurance.insuranceName}
        regNumMap={{
          quote: quote.productId,
          offer: offerToCompare.insurance.registrationNo,
        }}
      />

      <ProductWrapper partners={[PartnerName.instabank]} products={[ProductName.VEHICLE]}>
        <Typography color='textDisclaimer' size='small'>
          {t(`${T.COMMON}:priceWarning.2`)}
        </Typography>
      </ProductWrapper>
    </section>
  )
}

export default PriceTwinCards

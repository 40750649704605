import React, { FC } from 'react'
import { useApolloClient } from '@apollo/react-hooks'

import Loading from '../Loading/Page'
import useLogic from './useLogic'
import { ServiceSend } from '../../../types/interface/fsm.interface'

const AcceptPage: FC<{ send: ServiceSend }> = ({ send }) => {
  const client = useApolloClient()
  const { loading } = useLogic(client, send)

  if (loading) return <Loading />

  //TODO: We work with FSM. There shouldn't be either any return path or error-handling.
  // if (error) return <ErrorPage type={ERROR_PAYMENT} returnPath={OFFER_VERIFY} />

  return null
}

export default AcceptPage

import { useCallback, useContext, useEffect, useState } from 'react'
import { InsurelyEventType, InsurelyResultType } from '../../../types/insurelyTypes'
import { quoteCompetitorPriceMutator, selectedQuoteGetterLocal } from '../../../graphql/quote'
import { isVehicleQuoteType } from '../../../types/util.types'
import { filterVehicleInsurelyResults, getCoverageData } from '@dg-util'
import { Events } from '../../../FSM/shared/constants'
import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import { QuoteVehicleType } from '../../../types/Vehicles'
import Context from '../../../Context'
import { CoverageMapping } from '@dg-util/getCoverageData'
import { FlowServiceType } from '../../../types/interface/fsm.interface'

const useLogic = (client: ApolloClient<NormalizedCacheObject>, service: FlowServiceType) => {
  const [quote, setQuote] = useState<QuoteVehicleType | null>(null)
  const [offerDifference, setOfferDifference] = useState<number>(0)
  const [coverageData, setCoverageData] = useState<CoverageMapping | null>(null)
  const [contextState, setContextState] = useContext(Context)
  const {
    selectedInsurelyQuote,
    insurelyResults,
  }: { selectedInsurelyQuote?: InsurelyResultType; insurelyResults?: InsurelyResultType[] } =
    contextState

  const setQuoteCompetitorPrice = useCallback(
    async (quoteId: number, price: number): Promise<boolean> => {
      return await quoteCompetitorPriceMutator(client, quoteId, price)
    },
    [client]
  )

  useEffect(() => {
    if (quote) {
      // Handling of Insurely Iframe messages data
      const listener = async ({ data }: MessageEvent) => {
        const { name, value } = data

        if (name === InsurelyEventType.RESULTS) {
          // Filtering only for vehicle quotes
          if (isVehicleQuoteType(quote)) {
            const coverageData = await getCoverageData(quote)

            setCoverageData(coverageData)

            const results: InsurelyResultType[] = filterVehicleInsurelyResults(value, quote) || []

            if (results.length) {
              setContextState({ insurelyResults: results })

              // Only one offer to compare is available. Select it immediately
              if (results.length === 1) {
                const selectedQuote = results[0]
                setContextState({ selectedInsurelyQuote: selectedQuote })
              }
            } else {
              // No Insurance results after filtering
              service.send(Events.INSURELY_NO_OFFERS_FOUND)
            }
          }
        }
      }

      // Subscribe to the Insurely Iframe messages
      window.addEventListener('message', listener)

      return () => {
        // Remove subscriptions
        window.removeEventListener('message', listener)
      }
    }
  }, [client, quote, service, setContextState])

  useEffect(() => {
    if (quote && selectedInsurelyQuote) {
      const offerDifference =
        selectedInsurelyQuote.insurance.premiumAmountYearRounded - quote.priceAnnual
      setOfferDifference(offerDifference)
    }
  }, [quote, selectedInsurelyQuote, setQuoteCompetitorPrice])

  useEffect(() => {
    ;(async () => {
      const quoteLocal = await selectedQuoteGetterLocal(client)

      // Filtering only for vehicle quotes
      if (isVehicleQuoteType(quoteLocal)) {
        const coverageData = await getCoverageData(quoteLocal)

        setQuote(quoteLocal)
        setCoverageData(coverageData)
      }
    })()
  }, [client])

  return {
    insurelyResults,
    quote,
    offerDifference,
    selectedInsurelyQuote,
    coverageData,
    setQuoteCompetitorPrice,
  }
}

export default useLogic

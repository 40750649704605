import React from 'react'
import { InsurelyResultType } from './types/insurelyTypes'

export type ContextStateType = {
  insurelyResults?: InsurelyResultType[]
  selectedInsurelyQuote?: InsurelyResultType
}

const Context = React.createContext<
  [ContextStateType, (newContextState: ContextStateType) => void]
>([
  {},
  () => {
    /* no operation */
  },
])

export default Context

import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

import { IconType } from '../../../types/interface/icon.interface'
import { FSMBtnNext, BrandIconShared, TranslatedTextType } from '@dg-shared'

const useBtnEditStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(2),
      },
    },
    button: {
      maxWidth: '325px',
      [theme.breakpoints.down('sm')]: {
        borderStyle: 'solid none solid none',
        borderRadius: 0,
        borderColor: theme.palette.info.dark,
        color: `${theme.palette.secondary.main} !important`,
      },
      [theme.breakpoints.up('md')]: {
        borderWidth: '3px',
      },
    },
    btnEdit: {
      marginRight: -6,
      marginTop: -4,
    },
  })
)

interface BtnEditProps {
  text: TranslatedTextType
  handleClick(): void
}

const BtnEdit: FC<BtnEditProps> = ({ text, handleClick }) => {
  const classes = useBtnEditStyles()

  return (
    <div className={classes.container}>
      {/* //NOTE: Need block container for Safari when use forms */}
      <FSMBtnNext
        handleClick={handleClick}
        buttonText={text}
        customClassName={classes.button}
        outlined
        startIcon={<BrandIconShared type={IconType.CREATE} customClass={classes.btnEdit} />}
      />
    </div>
  )
}

export default BtnEdit

import { Events } from '../../../../../../FSM/shared/constants'

import { FSMTransitionsFNOLVehicleSE } from '../constants'

const {
  FLOW_MACHINE_ID,
  AUTH_BANK_ID,
  FNOL_CLAIMS,
  FNOL_CLAIMS_LIST,
  FNOL_OTHER_FLOW: FNOL_ANNET_FLOW,
  FNOL_OTHER_START: FNOL_ANNET_START,
  FNOL_OTHER_DAMAGE_LOCATION_AND_TIME: FNOL_ANNET_DAMAGE_LOCATION_AND_TIME,
  FNOL_OTHER_ADDITIONAL_INFORMATION: FNOL_ANNET_ADDITIONAL_INFORMATION,
  FNOL_OTHER_TIMELINE: FNOL_ANNET_TIMELINE,
  FNOL_OTHER_LEGAL_DATE: FNOL_ANNET_LEGAL_DATE,
  FNOL_OTHER_LEGAL_AMOUNT: FNOL_ANNET_LEGAL_AMOUNT,
  FNOL_OTHER_FIRE_CAUSE: FNOL_ANNET_FIRE_CAUSE,
} = FSMTransitionsFNOLVehicleSE

export default {
  [FNOL_ANNET_FLOW]: {
    initial: FNOL_ANNET_START,
    states: {
      [FNOL_ANNET_START]: {
        on: {
          [Events.BACK]: { target: `#${FLOW_MACHINE_ID}.${FNOL_CLAIMS}.${FNOL_CLAIMS_LIST}` },
          [Events.NEXT]: { target: FNOL_ANNET_DAMAGE_LOCATION_AND_TIME, actions: 'setBackPath' },
          [Events.FNOL_OTHER_DAMAGE_LOCATION_AND_TIME]: FNOL_ANNET_DAMAGE_LOCATION_AND_TIME,
          [Events.FNOL_OTHER_LEGAL_DATE]: FNOL_ANNET_LEGAL_DATE,
          [Events.FNOL_OTHER_FIRE_CAUSE]: FNOL_ANNET_FIRE_CAUSE,
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_ANNET_DAMAGE_LOCATION_AND_TIME]: {
        on: {
          [Events.BACK]: {
            target: FNOL_ANNET_START,
          },
          [Events.NEXT]: {
            target: FNOL_ANNET_ADDITIONAL_INFORMATION,
            action: 'setBackPath',
          },
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_ANNET_ADDITIONAL_INFORMATION]: {
        on: {
          [Events.BACK]: {
            target: FNOL_ANNET_DAMAGE_LOCATION_AND_TIME,
            actions: 'setBackPath',
          },
          [Events.NEXT]: {
            target: FNOL_ANNET_TIMELINE,
            action: 'setBackPath',
          },
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_ANNET_TIMELINE]: {
        on: {
          [Events.BACK]: {
            target: FNOL_ANNET_ADDITIONAL_INFORMATION,
            actions: 'setBackPath',
          },
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_ANNET_LEGAL_DATE]: {
        on: {
          [Events.BACK]: {
            target: FNOL_ANNET_START,
            actions: 'setBackPath',
          },
          [Events.NEXT]: {
            target: FNOL_ANNET_LEGAL_AMOUNT,
            actions: 'setBackPath',
          },
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_ANNET_LEGAL_AMOUNT]: {
        on: {
          [Events.BACK]: {
            target: FNOL_ANNET_LEGAL_DATE,
            actions: 'setBackPath',
          },
          [Events.NEXT]: {
            target: FNOL_ANNET_DAMAGE_LOCATION_AND_TIME,
            actions: 'setBackPath',
          },
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_ANNET_FIRE_CAUSE]: {
        on: {
          [Events.BACK]: {
            target: FNOL_ANNET_START,
            actions: 'setBackPath',
          },
          [Events.NEXT]: {
            target: FNOL_ANNET_DAMAGE_LOCATION_AND_TIME,
            actions: 'setBackPath',
          },
          [Events.FNOL_OTHER_DAMAGE_LOCATION_AND_TIME]: {
            target: FNOL_ANNET_DAMAGE_LOCATION_AND_TIME,
          },
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
    },
  },
}

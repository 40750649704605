// eslint-disable-next-line import/no-named-as-default
import ApolloClient from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import { QuoteType } from './generated/graphql'
import { QuoteVehicleType } from './Vehicles'

declare module '@apollo/react-hooks' {
  function useApolloClient(): ApolloClient<NormalizedCacheObject>
}

export enum QuoteTypenameType {
  vehicleSe = 'VehicleSe',
  vehicleNo = 'VehicleNo',
  travelNo = 'TravelNo',
}

export function isVehicleQuoteType(quote: QuoteType): quote is QuoteVehicleType {
  const quoteTypeName = quote.__typename as QuoteTypenameType
  return [QuoteTypenameType.vehicleNo, QuoteTypenameType.vehicleSe].includes(quoteTypeName)
}

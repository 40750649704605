import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { ApolloClient } from 'apollo-client'

import history from '../../../routes/history'
import { Events } from '../../../FSM/shared/constants'
import { acceptMutatorLocal } from '../../../graphql/quote/local-accept.mutator'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import { ServiceSend } from '../../../types/interface/fsm.interface'

interface HookAccept {
  loading: boolean
  error: boolean
}

const useLogic = (client: ApolloClient<NormalizedCacheObject>, send: ServiceSend): HookAccept => {
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const paymentStatus = location.pathname.split('/').filter(Boolean)[1]
  const isFailure = paymentStatus === 'failure'
  const startDate = new URLSearchParams(useLocation().search).get('startDate')
  const [quoteId, policyNumber] = location.pathname.split('/').slice(-2)

  const checkPaymentStatus = async (
    client: ApolloClient<NormalizedCacheObject>,
    error: boolean
  ): Promise<boolean | void> => {
    await acceptMutatorLocal(client, {
      id: parseInt(quoteId, 10),
      policyNumber: parseInt(policyNumber, 10),
      startDate: startDate as string,
    })

    setLoading(false)

    if (error) {
      history.push('/') //FIXME: need to push to the right location
      send(Events.PAYMENT_FAILURE)
      return
    }

    history.push('/') //FIXME: need to push to the right location
    send(Events.PAYMENT_SUCCESS)
  }

  useEffect(() => {
    checkPaymentStatus(client, isFailure)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    loading,
    error: isFailure,
  }
}
export default useLogic

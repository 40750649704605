import React, { FC } from 'react'

import { Typography, FNOLPageWrapper, useTranslate } from '@dg-shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import WhenWhereForm from '../../../components/Claims/WhenWhereForm'
import { TRANSLATIONS as T } from '../../../constants'

const PageStolenWhenWhere: FC<CommonPageProps> = ({ service }) => {
  const { t } = useTranslate(`${T.FNOL_THEFT}.stolenWhenWhere`)

  const Content = () => (
    <>
      <Typography testId='title' type='h2'>
        {t('title')}
      </Typography>
      <WhenWhereForm send={service.send} />
    </>
  )

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t('common/back')} breadcrumbsStepPercentage={70}>
      <Content />
    </FNOLPageWrapper>
  )
}

export default PageStolenWhenWhere

import { isNil } from 'lodash'
import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { GET_QUOTE_DRAFT_LOCAL } from './query'
import { QuoteType } from '../../types/generated/graphql'

export const quoteInitGetterLocal = async (
  client: ApolloClient<NormalizedCacheObject>,
  rName: Array<string> = ['quoteInitiate', 'getQuoteDraftLocal']
): Promise<QuoteType> => {
  const response = await client.query({ query: GET_QUOTE_DRAFT_LOCAL })

  if (isNil(response.data)) return response.data // Cached variant of the quote can be empty in the init state

  return response.data[rName[1]]
}

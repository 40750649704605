import React, { FC } from 'react'

import { Typography, useTranslate, FNOLPageWrapper, FNOLButton } from '@dg-shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events } from '../../../FSM/shared/constants'
import { TRANSLATIONS as T } from '../../../constants'
import { useFNOLStyles } from '../shared/styles/useStyles'

const PageHowMany: FC<CommonPageProps> = ({ service }) => {
  const FNOLClasses = useFNOLStyles({})

  const { send } = service
  const { t } = useTranslate(T.COLLISION)

  const collisionTypeSelect = ({
    collisionType,
    label,
  }: {
    collisionType: Events
    label: string
  }) => (
    <FNOLButton
      testId={label}
      key={collisionType}
      handleClick={() => send(collisionType)}
      buttonText={t(label)}
    />
  )

  const collisionTypeEvents = [
    { collisionType: Events.FNOL_COLLISION_RESPONSIBLE, label: 'howMany.buttons.oneCar' },
    { collisionType: Events.FNOL_THEFT_STOLEN_IN_CAR, label: 'howMany.buttons.twoCars' },
    {
      collisionType: Events.FNOL_THEFT_STOLEN_FROM_CAR,
      label: 'howMany.buttons.moreThenTwoCars',
    },
  ]

  const Content = () => (
    <>
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('howMany.title')}
      </Typography>
      <Typography testId='subtitle' html={true} className={FNOLClasses.description}>
        {t('howMany.subtitle')}
      </Typography>
      {collisionTypeEvents.map((item) => collisionTypeSelect(item))}
    </>
  )

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t(`${T.COMMON}/back`)} breadcrumbsStepPercentage={30}>
      <Content />
    </FNOLPageWrapper>
  )
}

export default PageHowMany

import { gql } from 'graphql-tag'

import { FRAGMENT_VEHICLE_NO_ITEMS } from '../fragments/QuoteVehicleItems.fragment'
import { FRAGMENT_VEHICLE_SE_ITEMS } from '../fragments/QuoteVehicleSeItems.fragment'
import { FRAGMENT_TRAVEL_NO_ITEMS } from '../fragments/QuoteTravelItems.fragment'

//FIXME: Remove these imports
import { FRAGMENT_QUOTE_ERROR_VALIDATION } from '../fragments/QuoteErrorValidation.fragment'
import { FRAGMENT_QUOTE_ERROR_GENERAL } from '../fragments/QuoteErrorGeneral.fragment'

export const VERIFY_QUOTE = gql`
  mutation VerifyQuote($verifyData: QuoteVerificationInput!, $id: Float!) {
    verifyQuote(verifyData: $verifyData, id: $id) {
      ... on QuoteVerified {
        quoteId
      }
    }
  }
`

export const UPDATE_QUOTE = gql`
  mutation Update($quoteInput: QuoteUpdateInput!, $id: Float!) {
    update(quoteInput: $quoteInput, id: $id) {
      ... on VehicleNo {
        ...VehicleNoItems
      }
      ... on VehicleSe {
        ...VehicleSeItems
      }
      ... on TravelNo {
        ...TravelNoItems
      }
    }
  }
  ${FRAGMENT_VEHICLE_NO_ITEMS}
  ${FRAGMENT_VEHICLE_SE_ITEMS}
  ${FRAGMENT_TRAVEL_NO_ITEMS}
`

export const UPDATE_QUOTE_DRAFT_LOCAL = gql`
  mutation UpdateQuoteDraftLocal($quote: QuoteType!) {
    updateQuoteDraftLocal(quote: $quote) @client
  }
`
export const SET_QUOTE_DRAFT_SELECTED_LOCAL = gql`
  mutation SetQuoteSelectedLocal($quote: QuoteType!) {
    setQuoteSelectedLocal(quote: $quote) @client
  }
`

export const SET_PRODUCT_ID_SELECTED_LOCAL = gql`
  mutation SetProductIdSelectedLocal($productId: String!) {
    setProductIdSelectedLocal(productId: $productId) @client
  }
`

export const ADD_QUOTE_LOCAL = gql`
  mutation AddQuoteLocal($quote: QuoteType!) {
    addQuoteLocal(quote: $quote) @client
    setQuoteSelectedLocal(quote: $quote) @client
  }
`

export const SET_OWNERSHIP = gql`
  mutation SetOwnership($newOwnerSSN: String, $newOwnerName: String, $id: Float!) {
    setOwnership(newOwnerSSN: $newOwnerSSN, newOwnerName: $newOwnerName, id: $id)
  }
`

export const SET_QUOTE_ACCEPTED_LOCAL = gql`
  mutation AddQuoteAcceptedLocal($quoteAccepted: QuoteAccepted!) {
    addQuoteAcceptedLocal(quoteAccepted: $quoteAccepted) @client
  }
`

export const SET_QUOTE_COMPETITOR_PRICE = gql`
  mutation SetQuoteCompetitorPrice($quoteId: Float!, $price: Float!) {
    setQuoteCompetitorPrice(quoteId: $quoteId, price: $price) {
      ... on QuoteState {
        state
      }
      ... on ServiceError {
        ...ServiceErrorItems
      }
      ... on ServiceErrorValidation {
        ...ServiceErrorValidationItems
      }
    }
  }
  ${FRAGMENT_QUOTE_ERROR_VALIDATION}
  ${FRAGMENT_QUOTE_ERROR_GENERAL}
`

import QuoteAndBuyFormConfig from './qb/config'
import { FormConfigType } from '../../../types/interface/form.interface'
import FNOLFormConfig from './fnol/config'

interface BrandFormConfig {
  qb: FormConfigType
  fnol: FormConfigType
}

const FormConfig: BrandFormConfig = {
  qb: QuoteAndBuyFormConfig,
  fnol: FNOLFormConfig,
}

export default FormConfig

import React, { FC } from 'react'

import { Typography, FNOLPageWrapper, useTranslate, FNOLButton } from '@dg-shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events } from '../../../FSM/shared/constants'
import { TRANSLATIONS as T } from '../../../constants'
import { useFNOLStyles } from '../shared/styles/useStyles'

const PageMoreInfo: FC<CommonPageProps> = ({ service, currentState }) => {
  const { t } = useTranslate(`${T.FNOL_THEFT}.moreInfo`)
  const FNOLClasses = useFNOLStyles({ externalLink: true })

  const { send } = service

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t('common/back')} breadcrumbsStepPercentage={50}>
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('title')}
      </Typography>
      <Typography testId='subtitle' className={FNOLClasses.description}>
        {t('subtitle')}
      </Typography>

      <FNOLButton
        testId='carStolen'
        handleClick={() => send({ type: Events.NEXT, state: currentState })}
        buttonText={t('button.carStolen')}
      />
      <FNOLButton
        testId='stolenInCar'
        handleClick={() => send(Events.FNOL_THEFT_STOLEN_IN_CAR)}
        buttonText={t('button.stolenInCar')}
      />
      <FNOLButton
        testId='stolenFromCar'
        handleClick={() => send(Events.FNOL_THEFT_STOLEN_FROM_CAR)}
        buttonText={t('button.stolenFromCar')}
      />
    </FNOLPageWrapper>
  )
}

export default PageMoreInfo

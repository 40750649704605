import { createMachine, interpret } from 'xstate'

import CONFIG from '../config'
import { getFlowName, getProductName } from '@dg-util'
import BrandFSMInput from 'BrandFSMInput'
import { get } from 'lodash'
import { FlowServiceType } from '../types/interface/fsm.interface'

const initMachine = () => {
  const flowName = getFlowName()
  const productName = getProductName()
  const input = get(BrandFSMInput, [flowName, productName])

  const flowMachine = createMachine(input.config, input.options)

  // TODO: Fix the type conversion `as unknown as FlowServiceType`
  const FlowService = interpret(flowMachine, {
    devTools: CONFIG.DEV_TOOLS,
  }) as unknown as FlowServiceType

  return {
    FlowService,
    flowMachine,
    routes: input.routes,
  }
}

const { FlowService, flowMachine, routes } = initMachine()

export { FlowService, flowMachine, routes }

import React, { FC } from 'react'
import format from 'date-fns/format'
import { Card, CardActionArea } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'

import { DocumentCardProps } from '../../../types/interface/claims.interface'
import { FORMAT_DAY_MONTH_YEAR } from '../../../constants'
import { Typography } from '@dg-shared'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      display: 'flex',
      textAlign: 'left',
      borderRadius: '3px',
      '&:not(:last-child)': {
        marginBottom: theme.spacing(3),
      },
      [theme.breakpoints.down('sm')]: {
        '&:first-child': {
          marginTop: theme.spacing(1),
        },
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',

        '& p, svg': {
          color: theme.palette.secondary.contrastText,
        },
      },
    },
    cardContent: {
      padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      color: theme.palette.primary.main,
      marginRight: theme.spacing(1.5),
    },
    content: {
      marginTop: theme.spacing(2),
    },
    text: {
      marginBottom: '0',
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  })
)

const DocumentCard: FC<DocumentCardProps> = ({ data, docType, openFile }) => {
  const classes = useStyles()
  const dateFrom = !!data.insuredFrom && format(new Date(data.insuredFrom), FORMAT_DAY_MONTH_YEAR)
  const dateTo = !!data.insuredTo && format(new Date(data.insuredTo), FORMAT_DAY_MONTH_YEAR)

  return (
    <Card className={classes.card} onClick={() => openFile(data.id)} data-testid={'document-card'}>
      <CardActionArea className={classes.cardContent}>
        <header className={classes.header}>
          <PictureAsPdfIcon className={classes.icon} />
          <Typography className={classes.text}>{docType}</Typography>
        </header>
        <section className={classes.content}>
          <Typography className={classes.text} testId={'document-title'}>
            {data.title}
          </Typography>
          <Typography className={classes.text} testId={'document-licPlateNum'}>
            {data.licensePlateNumber}
          </Typography>
        </section>
        <footer className={classes.footer}>
          <Typography className={classes.text} testId={'document-date'}>
            {`${dateFrom} - ${dateTo ? dateTo : '...'}`}
          </Typography>
        </footer>
      </CardActionArea>
    </Card>
  )
}

export default DocumentCard

import { Events } from '../../../../../../FSM/shared/constants'
import { FSMTransitionsFNOLVehicleSE } from '../constants'
import fsmConfigClaimsWindshield from '../windshield/fsmConfigClaimsWindshield'
import fsmConfigClaimsTheft from '../theft/fsmConfigClaimsTheft'
import fsmConfigClaimsRoadsideAssistance from '../roadsideAssistance/fsmConfigClaimsRoadsideAssistance'
import fsmConfigClaimsCollision from '../collision/fsmConfigClaimsCollision'
import fsmConfigClaimsAnnet from '../other/fsmConfigClaimsOther'

const {
  FLOW_MACHINE_ID,
  AUTH_BANK_ID,
  FNOL_MY_PAGE,
  FNOL_CLAIMS,
  FNOL_CLAIMS_LIST,
  FNOL_POLICIES_LIST,
  FNOL_WINDSHIELD_FLOW,
  FNOL_COLLISION_FLOW,
  FNOL_ROADSIDE_ASSISTANCE_FLOW,
  FNOL_THEFT_FLOW,
  FNOL_OTHER_FLOW: FNOL_ANNET_FLOW,
} = FSMTransitionsFNOLVehicleSE

export default {
  [FNOL_CLAIMS]: {
    initial: FNOL_POLICIES_LIST,
    states: {
      [FNOL_POLICIES_LIST]: {
        on: {
          [Events.BACK]: { target: `#${FLOW_MACHINE_ID}.${FNOL_MY_PAGE}` },
          [Events.NEXT]: { target: FNOL_CLAIMS_LIST },
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_CLAIMS_LIST]: {
        on: {
          [Events.BACK]: { target: FNOL_POLICIES_LIST },
          [Events.FNOL_WINDSHIELD]: { target: `#${FLOW_MACHINE_ID}.${FNOL_WINDSHIELD_FLOW}` },
          [Events.FNOL_COLLISION]: { target: `#${FLOW_MACHINE_ID}.${FNOL_COLLISION_FLOW}` },
          [Events.FNOL_THEFT]: { target: `#${FLOW_MACHINE_ID}.${FNOL_THEFT_FLOW}` },
          [Events.FNOL_ROADSIDE_ASSISTANCE]: {
            target: `#${FLOW_MACHINE_ID}.${FNOL_ROADSIDE_ASSISTANCE_FLOW}`,
          },
          [Events.FNOL_OTHER]: {
            target: `#${FLOW_MACHINE_ID}.${FNOL_ANNET_FLOW}`,
          },
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
    },
  },
  ...fsmConfigClaimsWindshield,
  ...fsmConfigClaimsCollision,
  ...fsmConfigClaimsRoadsideAssistance,
  ...fsmConfigClaimsTheft,
  ...fsmConfigClaimsAnnet,
}

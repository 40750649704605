import React, { FC } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { StylesProps } from '../../types/interface/styles.interface'
import { TranslatedTextType, Typography } from '@dg-shared'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerWrapper: (props: StylesProps) => ({
      padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
      background: `center url(${props.backgroundImage}) no-repeat`,
      borderRadius: '0 0 30% 0',
    }),
    logoWrapper: {
      display: 'flex',
      flex: 0,
      marginTop: theme.spacing(3),
    },
    logoImage: (props: StylesProps) => ({
      alignSelf: 'start',
      height: props.logoImageHeight ? props.logoImageHeight : '28px',
    }),
    title: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(3),
    },
  })
)

export interface PageImageHeaderMobileProps {
  logoImage: string
  logoText: string
  headerTitle?: TranslatedTextType
  backgroundImage: string
  logoCustomClass?: string
}

const PageImageHeaderMobile: FC<PageImageHeaderMobileProps> = ({
  logoImage,
  headerTitle,
  logoText,
  backgroundImage,
  logoCustomClass,
}) => {
  const classes = useStyles({ backgroundImage })

  return (
    <>
      <section className={classes.headerWrapper}>
        <section className={logoCustomClass ? logoCustomClass : classes.logoWrapper}>
          <img className={classes.logoImage} src={logoImage} alt={logoText} />
        </section>

        {!!headerTitle && (
          <>
            <Typography className={classes.title}>{headerTitle}</Typography>
          </>
        )}
      </section>
    </>
  )
}

export default PageImageHeaderMobile

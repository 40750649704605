import { ApolloClient } from 'apollo-client'
import { DatasourceValue } from '../../types/interface/datasource.interface'
import { GET_SINGLE_DATASOURCE } from './query'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

export const datasourceGetter = async (
  client: ApolloClient<NormalizedCacheObject>,
  datasourceId: number,
  rName: Array<string> = ['getSingleDatasource', 'list']
): Promise<Array<DatasourceValue>> => {
  const response = await client.query({
    query: GET_SINGLE_DATASOURCE,
    variables: {
      datasourceId,
    },
    errorPolicy: 'all',
  })

  return response.data[rName[0]][rName[1]]
}

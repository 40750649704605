import React from 'react'

enum IconType {
  LOADING = 'loading',
  ERROR = 'error',
  CHECK = 'check',
  CALENDAR = 'calendar',
  ARROW_DOWN = 'arrow-down',
  DOT = 'dot',
  CREATE = 'create',
  CHEVRON_LEFT = 'chevron-left',
  BEACH_ACCESS = 'beach-access',
  FILE_COPY = 'file-copy',
  ADD = 'add',
  REMOVE = 'remove',
}

interface DefaultIconComponentsMap {
  [iconType: string]: React.ElementType
}

interface CircleKIconComponentsMap {
  [iconType: string]: React.ElementType
}

interface DefaultIconProps {
  size?: number
  thickness?: 2.5
}

interface CircleKIconProps {
  type: IconType
  width?: number
  height?: number
  color?: string
}

interface DefaultIconPropsMap {
  [iconType: string]: DefaultIconProps
}

interface CircleKIconPropsMap {
  [iconType: string]: CircleKIconProps
}

interface PartnersIcons {
  [partnerName: string]: {
    components: DefaultIconComponentsMap | CircleKIconComponentsMap
    props: DefaultIconPropsMap | CircleKIconPropsMap
  }
}

export {
  DefaultIconProps,
  CircleKIconProps,
  DefaultIconComponentsMap,
  CircleKIconComponentsMap,
  DefaultIconPropsMap,
  CircleKIconPropsMap,
  PartnersIcons,
  IconType,
}

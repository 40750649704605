import { FSMTransitionsFNOLVehicleSE } from './constants'
import { Events, FSMCommonTransitions } from '../../../../../FSM/shared/constants'
import actions from '../../../../../FSM/shared/actions'
import guards from './guards'
import { MachineConfigType } from '../../../../../FSM/shared/config'
import fsmConfigClaims from './claims/fsmConfigClaims'
import fsmConfigDocuments from './documents/fsmConfigDocuments'

const { FLOW_MACHINE_ID, AUTH_BANK_ID, FNOL_MY_PAGE, ERROR, FNOL_CLAIMS, FNOL_DOCUMENTS } =
  FSMTransitionsFNOLVehicleSE

const FSMConfig: MachineConfigType = {
  id: FLOW_MACHINE_ID,
  initial: AUTH_BANK_ID,
  context: {
    authorized: false,
  },
  states: {
    [AUTH_BANK_ID]: {
      on: {
        NEXT: FNOL_MY_PAGE,
      },
    },
    [FNOL_MY_PAGE]: {
      on: {
        [Events.BACK]: { target: AUTH_BANK_ID, actions: 'signOut' },
        [Events.FNOL_CLAIMS]: FNOL_CLAIMS,
        [Events.FNOL_DOCUMENTS_LIST]: FNOL_DOCUMENTS,
        [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
      },
    },
    ...fsmConfigDocuments,
    ...fsmConfigClaims,
    [ERROR]: {
      on: {
        [Events.BACK]: [
          {
            target: AUTH_BANK_ID,
            cond: (ctx) =>
              [FNOL_CLAIMS, FNOL_DOCUMENTS].includes(<FSMCommonTransitions>ctx.backPath),
          },
        ],
      },
    },
  },
}

const FSMConfigFNOLVehicleSE = FSMConfig
const FSMOptionsFNOLVehicleSE = { guards, actions }

export { FSMConfigFNOLVehicleSE, FSMOptionsFNOLVehicleSE }

import React, { FC } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { TRANSLATIONS as T } from '../../constants/translations-constants'
import { Typography, useTranslate } from '@dg-shared'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(0.8)}px`,
      backgroundColor: theme.brand.colors.headingMain,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: theme.spacing(0.5),
    },
    title: {
      alignSelf: 'center',
      marginBottom: 0,
    },
    priceText: {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: '1 !important',
      marginBottom: 0,
    },
    detailsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    priceDisclaimer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    detailsMeta: {
      alignSelf: 'flex-end',
      margin: 0,
      marginTop: '-5px',
    },
  })
)

interface CustomCardHeaderProps {
  priceMonthly: number
  priceDetails?: number
}

const CustomCardHeader: FC<CustomCardHeaderProps> = ({ priceMonthly, priceDetails }) => {
  const { t } = useTranslate(T.OFFER_REVIEW)
  const classes = useStyles()

  return (
    <div className={classes.container} data-testid={'card-price-element'}>
      <div className={classes.row}>
        <Typography type='h2' className={classes.title} color='clean'>
          {t(`${T.COMMON}/offer`)}
        </Typography>
        <div className={classes.detailsContainer}>
          <Typography color='clean' type='h2' className={classes.priceText}>
            {String(priceMonthly)}
          </Typography>
          <Typography color='clean' size='small' className={classes.detailsMeta}>
            {t('card.priceMonthly')}
          </Typography>
        </div>
      </div>
      <section className={classes.priceDisclaimer}>
        <Typography color='clean' size='small'>
          {t(`${T.COMMON}:card.priceDetails`, { value: priceDetails })}
        </Typography>
      </section>
    </div>
  )
}

export default CustomCardHeader

import Joi from '@hapi/joi'

import { FormValidationType } from '../../../../types/interface/form.interface'
import { FNOL_FORM_ID as FORM_ID } from '../../../../constants/forms-constants'
import { legalAidDateValidation } from '../../../../validation/fieldsVS'

const FNOLFormValidation: FormValidationType = {
  [FORM_ID.ACCIDENT]: {
    vehicle: ({ minDate, maxDate }) =>
      Joi.object().keys({
        address: Joi.string().required().empty().messages({
          'string.base': `accident.form.address.validation.base`,
          'string.empty': `accident.form.address.validation.base`,
          'any.required': `accident.form.address.validation.base`,
        }),
        city: Joi.string().required().empty().messages({
          'string.base': `accident.form.city.validation.base`,
          'string.empty': `accident.form.city.validation.base`,
          'any.required': `accident.form.city.validation.base`,
        }),
        country: Joi.string().required().empty().messages({
          'string.base': `accident.form.country.validation.base`,
          'string.empty': `accident.form.country.validation.base`,
          'any.required': `accident.form.country.validation.base`,
        }),
        occurDate: legalAidDateValidation(minDate, maxDate),
      }),
  },
  [FORM_ID.COMPENSATION]: {
    vehicle: Joi.object().keys({
      compensation: Joi.string()
        .required()
        .regex(/^[0-9 ]*$/)
        .min(1)
        .max(20)
        .messages({
          'string.base': `Vennligst legg inn et tall.`,
          'string.min': `Vennligst legg inn et tall.`,
          'string.max': `Vennligst legg inn et tall.`,
          'string.pattern.base': `Vennligst legg inn et tall.`,
          'any.required': ``,
        }),
    }),
  },
  [FORM_ID.ADDITIONAL_INFO]: {
    vehicle: Joi.object()
      .optional()
      .keys({
        additionalInfo: Joi.string().optional().allow(null),
      }),
  },
  [FORM_ID.DATE]: {
    vehicle: ({ minDate, maxDate }) =>
      Joi.object().keys({
        date: legalAidDateValidation(minDate, maxDate),
      }),
  },
}

export default FNOLFormValidation

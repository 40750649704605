import { PartnerName, ProductName, ProductType } from '../types/interface/quote.interface'

export const APP_VERSION = '1.1.5'

export enum EnvType {
  ENV_LOCAL = 'local',
  ENV_DEV = 'development',
  ENV_ACC = 'acceptance',
  ENV_PROD = 'production',
}

export const ENV = process.env.ENV as EnvType

export const IS_LOCAL = ENV === EnvType.ENV_LOCAL
export const IS_DEVELOPMENT = ENV === EnvType.ENV_DEV
export const IS_ACCEPTANCE = ENV === EnvType.ENV_ACC
export const IS_PRODUCTION = ENV === EnvType.ENV_PROD

const ALL_BRANDS_TEST_GTM_ID = 'GTM-K89HQ3N'
const INSTABANK_PROD_GTM_ID = 'GTM-NZ339RM'
const INSTABANK_PROD_TRAVEL_GTM_ID = 'GTM-WX8ZQJ4'
const CIRCKEK_PROD_GTM_ID = 'GTM-5WVJNH2'

const GTM_CONFIG_MAP: Record<
  PartnerName,
  Record<string, Record<ProductName, Record<EnvType, string>>>
> = {
  [PartnerName.digisure]: {
    TRACKING_ID: {
      [ProductName.VEHICLE]: {
        [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_DEV]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_ACC]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_PROD]: ALL_BRANDS_TEST_GTM_ID,
      },
      [ProductName.TRAVEL]: {
        [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_DEV]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_ACC]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_PROD]: ALL_BRANDS_TEST_GTM_ID,
      },
    },
  },
  [PartnerName.instabank]: {
    TRACKING_ID: {
      [ProductName.VEHICLE]: {
        [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_DEV]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_ACC]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_PROD]: INSTABANK_PROD_GTM_ID,
      },
      [ProductName.TRAVEL]: {
        [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_DEV]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_ACC]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_PROD]: INSTABANK_PROD_TRAVEL_GTM_ID,
      },
    },
  },
  [PartnerName.circlek]: {
    TRACKING_ID: {
      [ProductName.VEHICLE]: {
        [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_DEV]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_ACC]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_PROD]: CIRCKEK_PROD_GTM_ID,
      },
      [ProductName.TRAVEL]: {
        [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_DEV]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_ACC]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_PROD]: CIRCKEK_PROD_GTM_ID,
      },
    },
  },
}

// GTM Tracking per brand (partner)
const TRACKING_ID = GTM_CONFIG_MAP[PARTNER_NAME as PartnerName].TRACKING_ID

const ALL_BRANDS_TEST_INSURELY_CLIENT_ID = 'afbe3c4c-573d-4b1b-88d7-90a479699f5e'
const INSTABANK_PROD_INSURELY_CLIENT_ID = '76113ec0-1999-42b6-b31a-5dcda768cdf0'
const CIRCKEK_PROD_INSURELY_CLIENT_ID = 'f151d67b-60e8-41d4-abe8-96a33b78c094'

const INSURELY_CLIENT_ID_MAP: Record<PartnerName, Record<EnvType, string>> = {
  [PartnerName.digisure]: {
    [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_INSURELY_CLIENT_ID,
    [EnvType.ENV_DEV]: ALL_BRANDS_TEST_INSURELY_CLIENT_ID,
    [EnvType.ENV_ACC]: ALL_BRANDS_TEST_INSURELY_CLIENT_ID,
    [EnvType.ENV_PROD]: ALL_BRANDS_TEST_INSURELY_CLIENT_ID,
  },
  [PartnerName.instabank]: {
    [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_INSURELY_CLIENT_ID,
    [EnvType.ENV_DEV]: ALL_BRANDS_TEST_INSURELY_CLIENT_ID,
    [EnvType.ENV_ACC]: ALL_BRANDS_TEST_INSURELY_CLIENT_ID,
    [EnvType.ENV_PROD]: INSTABANK_PROD_INSURELY_CLIENT_ID,
  },
  [PartnerName.circlek]: {
    [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_INSURELY_CLIENT_ID,
    [EnvType.ENV_DEV]: ALL_BRANDS_TEST_INSURELY_CLIENT_ID,
    [EnvType.ENV_ACC]: ALL_BRANDS_TEST_INSURELY_CLIENT_ID,
    [EnvType.ENV_PROD]: CIRCKEK_PROD_INSURELY_CLIENT_ID,
  },
}

// Insurely ClientID per environment
const INSURELY_CLIENT_ID = INSURELY_CLIENT_ID_MAP[PARTNER_NAME as PartnerName][ENV]

export enum LanguageCode {
  SV = 'sv',
  NO = 'no',
}

const LANGUAGE_CODE_MAP: Record<PartnerName, LanguageCode> = {
  [PartnerName.digisure]: LanguageCode.NO,
  [PartnerName.instabank]: LanguageCode.NO,
  [PartnerName.circlek]: LanguageCode.SV,
}

const CONFIG = {
  TRACKING_ID,
  INSURELY_CLIENT_ID,
  LANGUAGE_CODE: LANGUAGE_CODE_MAP[PARTNER_NAME as PartnerName],
  RECAPTCHA_SITE_KEY: '6LfAHAoaAAAAADh2gDgJ4yu1RKe3u0A8-WsB0zi3',
  USER_INFO_KEY: 'ds-user-data',
  USER_INFO_KEY_BID: 'ds-user-data-bid',
  APOLLO_STORAGE_KEY: 'ds-storage',
  PRODUCT_ID_KEY: 'PID',
  SERVICE: {
    avatalegiro: {
      instabank: {
        NUMBER: process.env.AVATALEGIRO_COMPANY_ACCOUNT_NUMBER_INSTABANK || '1111111111',
        TEST: {
          URL: process.env.AVATALEGIRO_URL || 'no-url',
        },
        PRODUCTION: {
          URL: process.env.AVATALEGIRO_URL || 'no-url',
        },
      },
    },
  },
  DEV_TOOLS: [true, 'true'].includes(process.env.DEV_TOOLS),
}

export const PRODUCT_NAME_TO_ID: { [key: string]: ProductType } = {
  [ProductName.VEHICLE]: ProductType.VEHICLE,
  [ProductName.TRAVEL]: ProductType.TRAVEL,
}

export enum FlowName {
  QUOTE_AND_BUY = 'qb',
  FNOL = 'fnol',
}

export default CONFIG

import { FormValidationType } from '../../../types/interface/form.interface'
import QuoteAndBuyFormValidation from './qb/validation'
import FNOLFormValidation from './fnol/validation'

interface BrandFormValidation {
  qb: FormValidationType
  fnol: FormValidationType
}

const FormValidation: BrandFormValidation = {
  qb: QuoteAndBuyFormValidation,
  fnol: FNOLFormValidation,
}

export default FormValidation

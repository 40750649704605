import React, { FC } from 'react'
import { get } from 'lodash'

import { TRANSLATIONS as T } from '../../../../constants/translations-constants'
import { VEHICLE_COVERAGE_TYPE_MAP } from '../../../../constants/insurance-constants'
import { VehicleNo } from '../../../../types/generated/graphql'
import { Typography, useTranslate } from '@dg-shared'
import useContentStyles from './useContentStyles'

interface ContentProps {
  offer: VehicleNo
}

const Content: FC<ContentProps> = ({
  offer: { priceMonthly, coverageType, carBrand, productId, carModel, carYear, taxMonthly },
}) => {
  const { t } = useTranslate(`${T.OFFER_VERIFY}.card`)
  const classes = useContentStyles()
  //FIXME: This should be done on BFF
  const taxMonth = taxMonthly || 0

  return (
    <>
      <Typography type='h3'>{t(`${T.COMMON}:car`)}</Typography>
      <Typography className={classes.textDetails}>
        {`${carYear} ${carBrand} ${carModel}`}
      </Typography>
      <Typography className={classes.textDetails}>
        {t(`${T.COMMON}:registrationNum`, { regNum: productId })}
      </Typography>

      <section className={classes.body}>
        <section className={`${classes.row} ${classes.spaceTop}`}>
          <Typography>
            {get(VEHICLE_COVERAGE_TYPE_MAP, [PARTNER_NAME, coverageType], null)}
          </Typography>
          <Typography>{t('priceMonth', { priceMonthly: priceMonthly })}</Typography>
        </section>
        {taxMonth && (
          <section className={`${classes.row} ${classes.spaceTop}`}>
            <Typography>{t('insuranceStateFee')}</Typography>
            <Typography>{t('priceMonth', { priceMonthly: taxMonth })}</Typography>
          </section>
        )}
      </section>

      <section className={`${classes.row} ${classes.spaceTop}`}>
        <Typography type='h3'>{t(`${T.COMMON}/total`)}</Typography>
        <section className={classes.priceRow}>
          {/* //FIXME: This should be done on BFF */}
          <Typography type='h3' className={classes.priceText}>
            {`${priceMonthly + taxMonth}`}
          </Typography>
          <Typography className={classes.currency}>{t('priceMonth')}</Typography>
        </section>
      </section>
    </>
  )
}

export default Content

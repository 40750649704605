import { gql } from 'graphql-tag'

import { FRAGMENT_QUOTE_ERROR_VALIDATION } from '../fragments/QuoteErrorValidation.fragment'
import { FRAGMENT_QUOTE_ERROR_GENERAL } from '../fragments/QuoteErrorGeneral.fragment'

export const GET_CLAIM_LIST = gql`
  query GetPolicies {
    claims {
      ... on ClaimList {
        list {
          id
          insuredFrom
          insuredTo
          licensePlateNumber
          coverageType
          registrationYear
          carModelType
          carMake
          carModel
        }
      }
      ... on ServiceErrorValidation {
        ...ServiceErrorValidationItems
      }
      ... on ServiceError {
        ...ServiceErrorItems
      }
    }
  }
  ${FRAGMENT_QUOTE_ERROR_VALIDATION}
  ${FRAGMENT_QUOTE_ERROR_GENERAL}
`

export const GET_CLAIM_INITIATED = gql`
  query Claims {
    claims @client(always: true) {
      initatedClaim {
        flow
      }
    }
  }
`

export const GET_CLAIM_SELECTED = gql`
  query SelectedClaim {
    claims @client(always: true) {
      selectedClaim {
        licensePlateNumber
        policyNumber
        coverageType
      }
    }
  }
`

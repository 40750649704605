import { get } from 'lodash'
import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { INIT_QUOTE, GET_QUOTE_PRODUCT_ID_SELECTED } from './query'
import { QuoteType } from '../../types/generated/graphql'

export const quoteInitGetter = async (
  client: ApolloClient<NormalizedCacheObject>,
  rName = 'initQuote'
): Promise<QuoteType> => {
  const { data } = await client.query({
    query: GET_QUOTE_PRODUCT_ID_SELECTED,
  })

  const response = await client.query({
    query: INIT_QUOTE,
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    variables: {
      productId: get(data, ['quotes', 'productId'], null),
    },
  })

  return response.data[rName]
}

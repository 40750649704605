import { FSMTransitionsFNOLVehicleSE } from './constants'
import { FNOLMyPage, AuthBankId } from '../../../../../pages/FNOL'
import { StateRoute } from '../../../../../types/interface/fsm.interface'
import { FSMCommonStatePaths } from '../../../../../FSM/shared/routes'
import routesClaimsOther from './other/routesClaimsOther'
import routesClaims from './claims/routesClaims'
import routesDocuments from './documents/routesDocuments'
import routesClaimsWindshield from './windshield/routesClaimsWindshield'
import routesClaimsCollision from './collision/routesClaimsCollision'
import routesClaimsTheft from './theft/routesClaimsTheft'
import routesClaimsRoadsideAssistance from './roadsideAssistance/routesClaimsRoadsideAssistance'

const FSMFNOLVehicleStatePathsSE: Array<StateRoute> = [
  ...FSMCommonStatePaths,
  {
    is: FSMTransitionsFNOLVehicleSE.AUTH_BANK_ID,
    page: AuthBankId,
  },
  {
    is: FSMTransitionsFNOLVehicleSE.FNOL_MY_PAGE,
    page: FNOLMyPage,
  },
  ...routesClaims,
  ...routesClaimsWindshield,
  ...routesClaimsCollision,
  ...routesClaimsRoadsideAssistance,
  ...routesDocuments,
  ...routesClaimsRoadsideAssistance,
  ...routesClaimsTheft,
  ...routesClaimsOther,
]

export { FSMFNOLVehicleStatePathsSE }

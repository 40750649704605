export const AUTH = '/auth' //Used in BackButtonShared component
export const AUTH_REGULAR = '/auth/regular'
export const OFFER_VERIFY = '/verify'
export const OFFER_PURCHASED_DONE = 'done'
export const OFFER_PURCHASED_FAIL = 'fail'
export const OFFERS_FIND_EMPTY = 'empty'
export const OFFERS_FIND_CUSTOM = 'custom'
export const PRICE_COMPARE_PAGE = '/compare'
export const LOAD_VEHICLES = '/loading' //FIXME: Should be removed - just for testing

export const LOG_OUT = '/sign-in'
export const SIGN_IN_BANK_ID = '/authBankId'
export const SIGN_IN_BANK_ID_SUCCESS = '/auth-bank-id/success'
export const DEV_SIGN_IN_BANK_ID_FAILURE = '/auth-bank-id/failure'
export const COMPARE_SELECT_POLICY = '/select-policy'
export const DOCUMENTS = '/documents'
export const CLAIMS = '/claims'
export const FNOL_WINDSHIELD = '/fnol/windshield'
export const FNOL_COLLISION = '/fnol/collision'
export const FNOL_ROADSIDE_ASSISTANCE = '/fnol/roadside-assistance'
export const FNOL_OTHER = '/fnol/other'
export const FNOL_DAMAGE_LIST = '/fnol/other/damage-list'
export const FNOL_LEGAL_AID_DATE = '/fnol/other/damage-legal-aid-date'
export const FNOL_LEGAL_AID_COMPENSATION = '/fnol/other/damage-legal-aid-compensation'
export const FNOL_CAUSE_OF_EMERGENCY = '/fnol/other/damage-brann'
export const FNOL_ACCIDENT_FORM = '/fnol/other/damage-accident-form'
export const FNOL_ACCIDENT_COMMENTS = '/fnol/other/damage-accident-comments'
export const FNOL_TIMELINE = '/fnol/other/timeline'

export enum FlowType {
  qb,
  FNOL,
}

import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { QuoteType } from '../../types/generated/graphql'
import { UPDATE_QUOTE } from '../quote/mutation'
import { QuoteUpdateValues } from '../../types/interface/quote.interface'

export const updateQuoteSetter = async (
  client: ApolloClient<NormalizedCacheObject>,
  variables: QuoteUpdateValues,
  rName = 'update'
): Promise<QuoteType> => {
  const response = await client.mutate({
    mutation: UPDATE_QUOTE,
    variables,
  })

  return response.data[rName]
}

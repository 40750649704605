import { useEffect, useState } from 'react'
import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { Events } from '../../../FSM/shared/constants'
import { FSMStateType, ServiceSend } from '../../../types/interface/fsm.interface'
import { OwnerFormData } from '../../../types/interface/form.interface'
import { VehicleNo } from '../../../types/generated/graphql'
import { selectedQuoteGetterLocal, setOwnershipMutator } from '../../../graphql/quote'
import { getStepPercentage } from '@dg-util'

interface HookOwnership {
  quote: VehicleNo | null
  stepPercentage: number
  loading: boolean
  btnDisabled: boolean
  setBtnDisabled: (value: ((prevState: boolean) => boolean) | boolean) => void
  handleSetOwnership: (values: OwnerFormData) => void
}

const useLogic = (
  client: ApolloClient<NormalizedCacheObject>,
  send: ServiceSend,
  currentState: FSMStateType
): HookOwnership => {
  const [loading, setPageLoading] = useState<boolean>(true)
  const [quote, setQuote] = useState<VehicleNo | null>(null)
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true)

  const stepPercentage = getStepPercentage(currentState)
  const SHOULD_SET_NEW_OWNER = '2'

  const setOwnership = async (
    quoteId: number,
    ssn: string | null = null,
    fullName: string | null = null
  ) => {
    setPageLoading(true)

    await setOwnershipMutator(client, quoteId, ssn, fullName)

    send(Events.NEXT)
  }

  const handleSetOwnership = async (values: OwnerFormData) => {
    //FIXME: This object should be changed in case the values are changing
    const shouldBeNewOwner = values.owner === SHOULD_SET_NEW_OWNER

    if (shouldBeNewOwner) {
      await setOwnership(quote?.id, values.ssn, values.fullName)
    } else {
      await setOwnership(quote?.id)
    }
  }

  const fetchQuote = async (client: ApolloClient<NormalizedCacheObject>) => {
    const localData = await selectedQuoteGetterLocal(client)

    setQuote(localData as VehicleNo)
  }

  useEffect(() => {
    ;(async () => {
      window.scrollTo(0, 0)
      await fetchQuote(client)
      setPageLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    quote,
    stepPercentage,
    loading,
    btnDisabled,
    setBtnDisabled,
    handleSetOwnership,
  }
}

export default useLogic

import React, { FC } from 'react'
import CONFIG from '../../config'
import { DEFAULT_INSURELY_TEST_COMPANY } from '../../constants'

export type InsurelyIframePropsType = {
  style?: Partial<React.CSSProperties>
  companyId: string
}

const InsurelyIframe: FC<InsurelyIframePropsType> = ({ style, companyId }) => {
  const insurelyClientId = CONFIG.INSURELY_CLIENT_ID
  const insurelyIframeSrc = `https://dc.insurely.se/v2/?clientId=${insurelyClientId}`
  const setupClient = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: global scope definition in Insurely library
    globalThis.setClientParams({
      // example input
      fontType: 'secondary',
      language: CONFIG.LANGUAGE_CODE,
      prefilledInput: {
        // Auto select default environment / brand test company or use the real one for production
        company: DEFAULT_INSURELY_TEST_COMPANY || companyId,
      },
    })
  }

  return (
    <iframe
      style={style}
      title='Insurely Iframe'
      src={insurelyIframeSrc}
      frameBorder='0'
      sandbox='allow-scripts
      allow-same-origin
      allow-popups
      allow-forms
      allow-popups-to-escape-sandbox
      allow-top-navigation'
      data-testid='insurely-iframe'
      onLoad={setupClient}
    />
  )
}

export default InsurelyIframe

import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import { Typography, useTranslate, CustomLinearProgress } from '@dg-shared'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: () => ({
      alignItems: 'center',
      minHeight: '100vh',
      alignContent: 'start',
      padding: theme.spacing(2),
      [theme.breakpoints.down('md')]: theme.brand.assets.pageBackgrounds.loadingMobile,
      [theme.breakpoints.up('md')]: theme.brand.assets.pageBackgrounds.loading,
      [theme.breakpoints.up('lg')]: {
        padding: 0,
      },
    }),
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        alignItems: 'center',
        maxWidth: '425px',
      },
    },
    logoMobileHeader: theme.brand.assets.images.logoMobileHeader,
    title: {
      marginBottom: theme.spacing(8),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(3),
      },
    },
    progress: {
      width: '100%',
      padding: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        padding: 0,
      },
    },
  })
)

const LoadingPage: FC = () => {
  const { t } = useTranslate()
  const classes = useStyles()

  return (
    <Grid container justifyContent='center' className={classes.container}>
      <Grid item xs={12} className={classes.wrapper}>
        <div data-testid='loading' className={classes.logoMobileHeader} />
        <Typography type='h1' className={classes.title}>
          {t('loading.title')}
        </Typography>
        <div className={classes.progress}>
          <CustomLinearProgress />
        </div>
      </Grid>
    </Grid>
  )
}

export default LoadingPage

import React, { FC, ChangeEvent } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { CheckboxProps, Checkbox } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
      '&$checked': {
        color: theme.palette.primary.main,
      },
    },
    checked: {},
  })
)

interface MuiCustomCheckbox extends CheckboxProps {
  changeHandler?(event: ChangeEvent): void
}

const GreenCheckbox: FC<MuiCustomCheckbox> = ({ changeHandler, ...props }) => {
  const classes = useStyles()

  return (
    <Checkbox
      color='default'
      classes={{ root: classes.root, checked: classes.checked }}
      onChange={changeHandler}
      {...props}
    />
  )
}

export default GreenCheckbox

import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { ACCEPT_QUOTE } from './query'
import { QuoteAccepted } from '../../types/generated/graphql'

export const acceptQuoteSetter = async (
  client: ApolloClient<NormalizedCacheObject>,
  rName = 'accept'
): Promise<QuoteAccepted> => {
  const response = await client.query({
    query: ACCEPT_QUOTE,
  })

  return response.data[rName]
}

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpBackend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

import { getFlowName, getProductName } from './util'
import BrandConfig from 'BrandConfig'

const productName = getProductName()
const flowName = getFlowName()

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      lng: BrandConfig.TRANSLATION_LANGUAGES[0],
      supportedLngs: BrandConfig.TRANSLATION_LANGUAGES,
      fallbackLng: false,
      backend: {
        loadPath: (lng: Array<string>, ns: Array<string>) => {
          if (ns[0] === 'common') {
            return `/assets/i18n/${PARTNER_NAME}/{{lng}}/common/translation.json`
          }
          return `/assets/i18n/${PARTNER_NAME}/{{lng}}/{{ns}}/${productName}/translation.json`
        },
      },
      debug: false,
      ns: [flowName, 'common'],
      returnObjects: true,
      load: 'currentOnly',
      defaultNS: flowName,
      keySeparator: '.',
      interpolation: {
        escapeValue: false,
        formatSeparator: ',',
      },
      react: {
        useSuspense: false,
      },
    },
    (err, t) => {
      if (err) return console.log('something went wrong loading', err)
      t('key') // -> same as i18next.t
    }
  )

export default i18n

import React, { FC } from 'react'

import { Typography, FSMBtnNext, FNOLPageWrapper, useTranslate } from '@dg-shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events } from '../../../FSM/shared/constants'
import { TRANSLATIONS as T } from '../../../constants'
import { useFNOLStyles } from '../shared/styles/useStyles'

const PageObject: FC<CommonPageProps> = ({ service }) => {
  const FNOLClasses = useFNOLStyles({})
  const { t } = useTranslate(`${T.COLLISION}.object`)
  const { send } = service

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t(`${T.COMMON}/back`)} breadcrumbsStepPercentage={50}>
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('title')}
      </Typography>
      <Typography testId='subtitle' html={true} className={FNOLClasses.description}>
        {t('subtitle')}
      </Typography>
      <FSMBtnNext
        testId='nextButton'
        handleClick={() => send(Events.FNOL_COLLISION_OBJECT)}
        buttonText={t(`${T.COMMON}/next`)}
      />
    </FNOLPageWrapper>
  )
}

export default PageObject

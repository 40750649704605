import React, { FC } from 'react'
import { useApolloClient } from '@apollo/react-hooks'

import { Typography, FNOLPageWrapper, useTranslate } from '@dg-shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events } from '../../../FSM/shared/constants'
import { useFNOLStyles } from '../shared/styles/useStyles'
import { FNOL_FORM_ID, TRANSLATIONS as T } from '../../../constants'
import FormBuilder from '@dg-shared/forms/FormBuilder'
import { updateMutator } from '../../../graphql/claim/local-update.mutator'

interface RegistrationNumberFormValues {
  registrationNum?: string
}

const PageRegistrationNumber: FC<CommonPageProps> = ({ service, currentState }) => {
  const FNOLClasses = useFNOLStyles({})
  const { t } = useTranslate(`${T.COLLISION}.regNumber`)
  const client = useApolloClient()

  const handleSubmit = async ({ registrationNum }: RegistrationNumberFormValues) => {
    await updateMutator(client, {
      // TODO: Check these fields for a real request
      registrationNum,
    })

    service.send({ type: Events.NEXT, state: currentState })
  }

  const Content = () => (
    <>
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('title')}
      </Typography>
      <Typography testId='subtitle' html={true} className={FNOLClasses.description}>
        {t('subtitle')}
      </Typography>

      <FormBuilder
        id={FNOL_FORM_ID.REGISTRATION_NUMBER}
        classes={FNOLClasses.formContainer}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        submitHandler={handleSubmit}
      />
    </>
  )

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t(`${T.COMMON}/back`)} breadcrumbsStepPercentage={60}>
      <Content />
    </FNOLPageWrapper>
  )
}

export default PageRegistrationNumber

import { isNil } from 'lodash'
import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { ERROR_CODE_QUOTE_GENERAL } from '../../constants'
import { ErrorCode } from '../../types/interface/error.interface'
import { SIGN_OUT } from '../auth.query'

export const signOutGetter = async (
  client: ApolloClient<NormalizedCacheObject>,
  rName = 'signOut'
): Promise<[Record<string, unknown> | null, ErrorCode | null]> => {
  const response = await client.query({
    query: SIGN_OUT,
    fetchPolicy: 'no-cache',
  })

  if (!isNil(response.errors) || (!isNil(response.data) && response.data[rName].error)) {
    return [null, ERROR_CODE_QUOTE_GENERAL]
  }

  return [response.data[rName], null]
}

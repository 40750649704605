import { FNOLDocumentReview, FNOLDocumentsList } from '../../../../../../pages/FNOL'

export default [
  {
    is: { documents: 'documentsList' },
    page: FNOLDocumentsList,
  },
  {
    is: { documents: 'documentReview' },
    page: FNOLDocumentReview,
  },
]

export const TRANSLATIONS = {
  // NB! Should be the same as key in translation.json
  COMMON: 'common',
  COMMON_RECAPTCHA: 'common.reCaptcha',
  AUTH: 'auth',
  ERROR: 'error',
  FIND: 'find',
  OFFERS: 'offers',
  FNOL_TIMELINE: 'timeline',
  OFFER_REVIEW: 'review',
  OFFER_COMPARE: 'compare',
  OFFER_COMPARE_AUTH: 'compare.auth',
  OFFER_COMPARE_PROGRESS: 'compare.progress',
  OFFER_COMPARE_PRICE: 'compare.price',
  OFFER_COMPARE_POLICY: 'compare.policy',
  OFFER_OWNERSHIP: 'ownership',
  OFFER_VERIFY: 'verify',
  OFFER_PURCHASED: 'purchased',
  OFFER_PURCHASED_FAIL: 'purchased.fail',
  OFFER_CHANGE: 'change',
  LOADING: 'loading',
  MY_PAGE: 'myPage',
  DOCUMENTS: 'documents',
  CLAIMS: 'claims',
  FNOL_OTHER: 'other',
  FNOL_OTHER_START: 'other.start',
  FNOL_OTHER_WHEN_WHERE: 'other.whenWhere',
  FNOL_OTHER_FIRE: 'other.fire',
  FNOL_OTHER_LEGAL_AID: 'other.legalAid',
  FNOL_OTHER_ADDITIONAL_INFO: 'other.additionalInfo',
  WINDSHIELD: 'claims.windshield',
  COLLISION: 'claims.collision',
  ROADSIDE_ASSISTANCE: 'claims.roadsideAssistance',
  FNOL_THEFT: 'claims.theft',
}

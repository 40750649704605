import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { SET_QUOTE_DRAFT_SELECTED_LOCAL } from './mutation'
import { QuoteType } from '../../types/generated/graphql'

export const quoteInitSetterLocal = async (
  client: ApolloClient<NormalizedCacheObject>,
  quote: QuoteType,
  rName: Array<string> = ['setQuoteSelectedLocal']
): Promise<QuoteType> => {
  const response = await client.mutate({
    mutation: SET_QUOTE_DRAFT_SELECTED_LOCAL,
    variables: { quote },
  })

  return response.data[rName[0]]
}

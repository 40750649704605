export const httpClientGet = async (url: string, params?: Record<string, unknown>) => {
  try {
    const resp = await fetch(url, {
      method: 'GET',
      ...params,
    })

    return await resp.json()
  } catch (e) {
    return e
  }
}

export const httpClientPost = async (
  url: string,
  body?: Record<string, unknown>,
  params?: Record<string, unknown>
) => {
  try {
    const resp = await fetch(url, {
      method: 'POST',
      ...params,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })

    return await resp.json()
  } catch (e) {
    return e
  }
}

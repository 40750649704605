import React, { FC, useContext } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { ProductType } from '../../../types/interface/quote.interface'
import { Events } from '../../../FSM/shared/constants'
import { Typography, useTranslate, FSMBtnNext, TranslatedTextType } from '@dg-shared'
import { ServiceSend } from '../../../types/interface/fsm.interface'
import Context from '../../../Context'

const useCTAStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButtons: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(1),
        '& > button:not(:last-child)': {
          marginBottom: theme.spacing(3),
        },
      },
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyItems: 'center',
        justifyContent: 'center',
        paddingTop: theme.spacing(5),
        padding: theme.spacing(2),
        '& > button:not(:last-child)': {
          marginRight: theme.spacing(2),
        },
      },
    },
    button: {
      [theme.breakpoints.up('md')]: {
        width: '325px',
      },
    },
    actionButtonsDesc: {
      textAlign: 'center',
    },
    ctaAnnotation: {
      marginBottom: theme.spacing(3),
      color: theme.brand.colors.main,
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(1),
      },
    },
    ctaAnnotationDesc: {
      margin: 0,
    },
    errorMessageStyle: {
      textAlign: 'center',
    },
  })
)

interface CTAProps {
  productType: ProductType
  showCompareBtn: boolean
  send: ServiceSend
  isVerify: boolean
  errorInsurely: TranslatedTextType | null
}

const CTA: FC<CTAProps> = ({ productType, showCompareBtn, send, isVerify, errorInsurely }) => {
  const { t } = useTranslate(T.OFFER_REVIEW)
  const [, setContextState] = useContext(Context)
  const classes = useCTAStyles()
  const isVehicleProduct = productType === ProductType.VEHICLE

  // Compare button should be always visible on DEV and ACC environments for vehicle product
  // For PROD env - only in case of vehicle product and showCompareBtn = true
  const isCompareButtonVisible = isVehicleProduct && showCompareBtn
  const compareHandler = () => {
    // Cleanup previous Insurely compare data before new compare
    setContextState({
      insurelyResults: null,
      selectedInsurelyQuote: null,
    })
    send(Events.START_COMPARE)
  }

  return (
    <>
      <section className={classes.actionButtons}>
        {isCompareButtonVisible && (
          <FSMBtnNext
            handleClick={compareHandler}
            buttonText={t(`${T.COMMON}:button.compare`)}
            outlined
            customClassName={classes.button}
          />
        )}
        <FSMBtnNext
          handleClick={() => send({ type: Events.NEXT, isVerify: isVerify })}
          buttonText={t(`${T.COMMON}/goToPurchase`)}
          customClassName={classes.button}
        />
      </section>

      <section className={classes.actionButtonsDesc}>
        {isCompareButtonVisible && (
          <Typography className={classes.ctaAnnotation}>{t('cta.title')}</Typography>
        )}
        {isVehicleProduct && !showCompareBtn && !errorInsurely && (
          <Typography className={classes.ctaAnnotation}>{t('cta.noCompareBtn')}</Typography>
        )}
        {/* //TODO: Stopped */}
        {!!errorInsurely && (
          <Typography className={classes.errorMessageStyle} color='textError'>
            {errorInsurely}
          </Typography>
        )}
        <Typography color='textSecondary' size='small' className={classes.ctaAnnotationDesc}>
          {t('cta.desc')}
        </Typography>
      </section>
    </>
  )
}

export default CTA

import { get, has } from 'lodash'

import { formValidator } from '../../../service/util.service'
import FormConfig from 'FormConfig'
import FormValidation from 'FormValidation'
import { useTranslate } from '@dg-shared'
import { FormFieldV2 } from '../../../types/interface/form.interface'
import { getFlowName, getProductName } from '@dg-util'

const useFormConfig = (formId: string, validationArgs?: Record<string, unknown>) => {
  const { t } = useTranslate(formId)
  const flowName = getFlowName()
  const productName = getProductName()
  const config = get(FormConfig, [flowName, formId, productName])
  const validation = get(FormValidation, [flowName, formId, productName])

  const fields = get(config, ['fields']) as Record<string, FormFieldV2>

  fields &&
    Object.entries(fields).map(([fieldKey, field]) => {
      if (!field.customLabel) {
        field.label = t(`form.${fieldKey}.label`)
      }

      if (has(field, ['componentProps', 'defaultItemText'])) {
        field.componentProps.defaultItemText = t(`form.${fieldKey}.defaultItem`)
      }

      field.placeholder = t(`form.${fieldKey}.placeholder`)
      field.errorMsg = t(`form.${fieldKey}.error`)
    })

  return {
    fields,
    initialStaticValues: get(FormConfig, [formId, productName, 'initialValues']),
    validation: formValidator(validationArgs ? validation(validationArgs) : validation),
  }
}

export default useFormConfig

import React, { FC } from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { TRANSLATIONS as T, FORM_ID } from '../../../constants'
import { Events } from '../../../FSM/shared/constants'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { PartnerName, ProductName } from '../../../types/interface/quote.interface'
import Loading from '../Loading/Page'
import Card from './Card'
import useLogic from './useLogic'
import FormBuilder from '@dg-shared/forms/FormBuilder'
import {
  withHidden,
  LMBasicV3FSM,
  LDContainerV2FSM,
  ExternalSubmitBtn,
  Typography,
  useTranslate,
  FSMBtnNext,
  ProductWrapper,
} from '@dg-shared'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnExternal: {
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(5),
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(3),
        width: '100%',
      },
    },
    headerText: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(3),
      },
    },
    nextBtn: {
      width: '343px',
      height: '48px',
      margin: 'auto',
      marginTop: theme.spacing(8),
    },
    annotation: {
      marginBottom: theme.spacing(2),
    },
  })
)

const MobileScreen = withHidden(LMBasicV3FSM, true)
const DesktopScreen = withHidden(LDContainerV2FSM, false)

const OwnershipPage: FC<CommonPageProps> = ({ service, currentState }) => {
  const classes = useStyles()
  const { t } = useTranslate(T.OFFER_OWNERSHIP)
  const client = useApolloClient()
  const { quote, stepPercentage, loading, btnDisabled, setBtnDisabled, handleSetOwnership } =
    useLogic(client, service.send, currentState)

  const body = (
    <>
      <FormBuilder
        id={FORM_ID.OWNERSHIP}
        submitHandler={handleSetOwnership}
        handleBtnDisabled={setBtnDisabled}
        customSubmit
      />
      <Typography color='textSecondary'>{t('bodyInfoText')}</Typography>
    </>
  )

  const submitBtn =
    PARTNER_NAME === PartnerName.instabank ? (
      <ExternalSubmitBtn
        text={t(`${T.COMMON}/next`)}
        targetId={FORM_ID.OWNERSHIP}
        disabled={btnDisabled}
        customClassName={classes.btnExternal}
      />
    ) : (
      <FSMBtnNext
        handleClick={() => service.send(Events.NEXT)}
        buttonText={t(`${T.COMMON}/next`)}
        customClassName={classes.nextBtn}
      />
    )

  if (loading) {
    return <Loading />
  }

  const header = (
    <ProductWrapper partners={[PartnerName.circlek]} products={[ProductName.VEHICLE]}>
      <Typography type='h1'>{t('headerTitleMobile')}</Typography>
      <Typography className={classes.headerText}>{t('headerTextMobile')}</Typography>
    </ProductWrapper>
  )

  //TODO: We have a TypeScript error here on !!quote boolean check.
  // Need to investigate this kind of case later. Probably, we need
  // to set local state with the quote object received before the
  // page will be loaded. Need to test.
  return (
    quote && (
      <>
        <MobileScreen
          send={service.send}
          breadcrumbs
          breadcrumbsBtnText={t(`${T.COMMON}/back`)}
          titleHeader={t('title')}
          paragraphSubTitle={t('annotation')}
          breadcrumbsStepPercentage={stepPercentage}
        >
          {header}
          <Card itemData={quote} colLeft={body} />
          {submitBtn}
        </MobileScreen>
        <DesktopScreen send={service.send} breadcrumbs breadcrumbsStepPercentage={stepPercentage}>
          {header}
          <Typography type='h1'>{t('title')}</Typography>
          <Typography className={classes.annotation}>{t('annotation')}</Typography>
          <Card itemData={quote} colLeft={body} showDivider />
          {submitBtn}
        </DesktopScreen>
      </>
    )
  )
}

export default OwnershipPage

import React, { FC } from 'react'

import { Typography, FNOLButton, FNOLPageWrapper, useTranslate } from '@dg-shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events } from '../../../FSM/shared/constants'
import { TRANSLATIONS as T } from '../../../constants'
import { useFNOLStyles } from '../shared/styles/useStyles'

const PageStolenFromCar: FC<CommonPageProps> = ({ service, currentState }) => {
  const { send } = service
  const { t } = useTranslate(`${T.FNOL_THEFT}.stolenFromCar`)
  const FNOLClasses = useFNOLStyles({ externalLink: true })

  const itemSelect = ({ eventName, label }: { eventName: Events; label: string }) => (
    <FNOLButton
      testId={label}
      key={label}
      handleClick={() => send({ type: eventName, state: currentState })}
      buttonText={t(label)}
    />
  )

  const eventItems = [
    { eventName: Events.NEXT, label: 'button.rims' },
    { eventName: Events.NEXT, label: 'button.wheel' },
    { eventName: Events.NEXT, label: 'button.key' },
    { eventName: Events.NEXT, label: 'button.other' },
  ]

  const ItemsList = () => <>{eventItems.map((item) => itemSelect(item))}</>

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t('common/back')} breadcrumbsStepPercentage={60}>
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('title')}
      </Typography>
      <Typography testId='subtitle' className={FNOLClasses.description}>
        {t('subtitle')}
      </Typography>
      <ItemsList />
    </FNOLPageWrapper>
  )
}

export default PageStolenFromCar

import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { QuoteType } from '../../types/generated/graphql'
import { ADD_QUOTE_LOCAL } from './mutation'

export const quoteInitSetter = async (
  client: ApolloClient<NormalizedCacheObject>,
  quote: QuoteType,
  rName = 'getQuoteDraft'
): Promise<QuoteType> => {
  const response = await client.mutate({
    mutation: ADD_QUOTE_LOCAL,
    variables: { quote },
  })

  return response.data[rName]
}

import React, { FC } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

import { TRANSLATIONS as T } from '../../../constants'
import { Events } from '../../../FSM/shared/constants'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Typography, FSMBtnNext, useTranslate, FNOLPageWrapper } from '@dg-shared'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnNext: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.dark,
      textAlign: 'left',
      textTransform: 'none',
      justifyContent: 'flex-start',
      fontWeight: 400,
    },
  })
)

const PageFireCause: FC<CommonPageProps> = ({ service }) => {
  const classes = useStyles()
  const { t } = useTranslate(T.FNOL_OTHER_FIRE)
  const { send } = service

  return (
    <FNOLPageWrapper breadcrumbsStepPercentage={50} breadcrumbsBtnText={t(`${T.COMMON}/back`)}>
      <Typography type='h1'>{t('title')}</Typography>
      <Typography>{t('subtitle')}</Typography>
      <>
        {Object.values(t('button')).map((translation) => (
          <FSMBtnNext
            key={translation}
            buttonText={translation}
            customClassName={classes.btnNext}
            handleClick={() => send(Events.NEXT)}
          />
        ))}
      </>
    </FNOLPageWrapper>
  )
}

export default PageFireCause

import { Events } from '../FSM/shared/constants'
import { ERROR_TYPE as ET } from '../constants'
import { FSMStateType, ServiceSend } from '../types/interface/fsm.interface'

export default (send: ServiceSend, errorCode: number | string, state: FSMStateType): void => {
  let errorType
  if (typeof errorCode === 'string') {
    errorType = ET[errorCode]
  } else {
    errorType = Object.keys(ET).find((el) => ET[el].code === errorCode)
  }

  return send({ type: Events.ERROR, errorType, state })
}

import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'

import { LMCommonProps } from '../../../types/MLCommonProps'
import { HeaderShared, BtnBackV2 } from '@dg-shared'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100vh',
      alignContent: 'start',
    },
    page: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      textAlign: 'center',
      fontSize: '26px',
      fontWeight: 500,
    },
    backBtn: {
      alignSelf: 'start',
      marginBottom: theme.spacing(4),
    },
  })
)

const LMBasicV2: FC<LMCommonProps> = ({
  titleHeader,
  breadcrumbsBtnText,
  breadcrumbsBtnPath,
  children,
  stepPercentage,
  breadcrumbs,
  flow,
}) => {
  const classes = useStyles()
  const backButton = (
    <BtnBackV2
      customClass={classes.backBtn}
      goTo={breadcrumbsBtnPath}
      flow={flow}
      buttonText={breadcrumbsBtnText.toString()}
    />
  )

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.page}>
        {stepPercentage ? (
          <HeaderShared stepsPercentage={stepPercentage} renderBackButton={() => backButton} />
        ) : (
          //FIXME: Remove !!breadcrumbsBtnText from the logic expression in case
          // to simplify the logic. For now we need it to avoid regression
          (breadcrumbs || !!breadcrumbsBtnText) && backButton
        )}

        {!!titleHeader && (
          <Typography variant='h1' color='primary' className={classes.title}>
            {titleHeader}
          </Typography>
        )}
        <section className={classes.content}>{children}</section>
      </Grid>
    </Grid>
  )
}

export default LMBasicV2

import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Card } from '@material-ui/core'

import { TRANSLATIONS as T } from '../../../../constants/translations-constants'
import { InsurelyResultType } from '../../../../types/insurelyTypes'
import { formatWithThousandSpace } from '../../../../service/util.service'
import { ProductWrapper, Typography, useTranslate } from '@dg-shared'
import { PartnerName, ProductName } from '../../../../types/interface/quote.interface'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      padding: theme.spacing(3),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        maxWidth: '345px',
      },
      '&:hover p, &:visited p, &:active p': {
        color: theme.palette.secondary.contrastText,
      },
      '&:hover, &:visited': {
        backgroundColor: theme.palette.primary.main,
      },
      '&:active': {
        backgroundColor: theme.palette.info.contrastText,
      },
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(3),
    },
  })
)

interface ComparePolicyCardProps {
  chooseOffer(offerId: string): void
  item: InsurelyResultType
}

const ComparePolicyCard: FC<ComparePolicyCardProps> = ({ chooseOffer, item }) => {
  const { t } = useTranslate(T.OFFER_COMPARE)
  const classes = useStyles()

  return (
    <Card
      onClick={() => {
        chooseOffer(item.insurance.externalId)
      }}
      className={classes.card}
      data-testid={`test-compare-policy-card-${item.insurance.externalId}`}
    >
      <section className={classes.header}>
        <ProductWrapper partners={[PartnerName.instabank]} products={Object.values(ProductName)}>
          <Typography modifiers={['bold']}>{t(`${T.COMMON}:car`)}</Typography>
          <Typography
            modifiers={['bold']}
            testId={`test-compare-policy-price-text-${item.insurance.externalId}`}
          >
            {t('priceText', {
              price: formatWithThousandSpace(item.insurance.premiumAmountYearRounded),
            })}
          </Typography>
        </ProductWrapper>

        <ProductWrapper partners={[PartnerName.circlek]} products={Object.values(ProductName)}>
          <Typography>{t(`${T.COMMON}:car`)}</Typography>
          <Typography>
            {t('priceText', {
              price: formatWithThousandSpace(item.insurance.premiumAmountYearRounded),
            })}
          </Typography>
        </ProductWrapper>
      </section>
      <Typography>{t('date', { paidUntil: item.insurance.renewalDate })}</Typography>
    </Card>
  )
}

export default ComparePolicyCard

import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { SET_PRODUCT_ID_SELECTED_LOCAL } from '../quote/mutation'

export const selectedQuoteSetterLocal = async (
  client: ApolloClient<NormalizedCacheObject>,
  productId: string
): Promise<void> => {
  await client.mutate({
    mutation: SET_PRODUCT_ID_SELECTED_LOCAL,
    variables: {
      productId,
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })
}

import React, { FC, useEffect, useState } from 'react'
import { Switch } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { ApolloProvider } from '@apollo/react-hooks'

import CONFIG from './config'
import { StateRoute } from './types/interface/fsm.interface'
import { FlowType } from './constants'
import { routes, FSMSwitch, FSMMatch, FlowService } from './FSM'
import FSMProtectedRoute from './routes/FSMProtectedRoute'
import { QBCustomAuth, AcceptPage } from './pages/qb'
import AppWrapper from './AppWrapper'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory'
import initCache, { initPersistentCache } from './service/gqlCache.service'
import initClient from './service/gqlClient.service'
import { INITIAL_STATE } from './constants/state-constants'
import { signOutGetter } from './graphql/auth/signOut.getter'

const App: FC = () => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>()

  useEffect(() => {
    const cache: InMemoryCache = initCache()
    const client: ApolloClient<NormalizedCacheObject> = initClient(cache, FlowService)

    cache.writeData({ data: INITIAL_STATE })
    initPersistentCache(cache, CONFIG.APOLLO_STORAGE_KEY)
    client.onResetStore(async () => cache.writeData({ data: INITIAL_STATE }))
    setClient(client as ApolloClient<NormalizedCacheObject>)
  }, [])

  useEffect(() => {
    if (client) {
      // Do a signOut action if it was requested by the PID handler on app initialization
      // This should clean the previous redis auth key in case the PID was changed in the URL directly
      ;(async () => {
        if (window.requestSignOut) {
          await signOutGetter(client)
        }
      })()
    }
  }, [client])

  const content = client ? (
    <ApolloProvider client={client}>
      <FSMSwitch>
        {!isEmpty(routes) &&
          routes.map((route: StateRoute, idx: number) => (
            <FSMMatch key={`${idx}-route`} is={route.is} component={route.page} />
          ))}
      </FSMSwitch>
      <Switch>
        <FSMProtectedRoute path='/accept' component={AcceptPage} protectSrc={FlowType.qb} />
        <FSMProtectedRoute path='/custom-auth' component={QBCustomAuth} protectSrc={FlowType.qb} />
      </Switch>
    </ApolloProvider>
  ) : null

  return <AppWrapper>{content}</AppWrapper>
}

export default App

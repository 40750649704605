import React, { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { TranslatedTextType } from '@dg-shared/hooks/useTranslate'

interface ButtonNext {
  pathToRoute?: string
  buttonText: TranslatedTextType
  customClassName?: string
  disabled?: boolean
  outlined?: boolean
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  handleClick?(): void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonNext: (outlined) => ({
      fontSize: '1rem',
      backgroundColor: outlined ? theme.palette.secondary.contrastText : theme.palette.primary.main,
      color: outlined ? theme.palette.primary.main : theme.palette.secondary.contrastText,
      border: outlined ? `1px solid ${theme.palette.primary.main}` : 'none',
      boxShadow: outlined && 'none',
    }),
  })
)

const ButtonNext: FC<ButtonNext> = ({
  pathToRoute,
  buttonText,
  customClassName,
  disabled = false,
  outlined = false,
  startIcon,
  endIcon,
  handleClick,
}) => {
  const classes = useStyles(outlined)

  return pathToRoute ? (
    <Button
      component={RouterLink}
      disabled={disabled}
      to={pathToRoute || '/'}
      variant='contained'
      size='medium'
      className={`${classes.buttonNext} ${customClassName}`}
      fullWidth
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {buttonText}
    </Button>
  ) : (
    <Button
      disabled={disabled}
      variant='contained'
      size='medium'
      className={`${classes.buttonNext} ${customClassName}`}
      fullWidth
      onClick={handleClick}
    >
      {buttonText}
    </Button>
  )
}

export default ButtonNext

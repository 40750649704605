import { ComponentType } from 'react'
import i18next from 'i18next'
import { ObjectSchema } from '@hapi/joi'
import { get, isArray, isObject, mergeWith } from 'lodash'

import { SessionData } from '../types/interface/session.interface'
import { REGEX_NUMBER_EMPTY_SPACE, REGEX_THOUSANDS_SEPARATOR } from '../constants/regex-constants'
import { AddressComponent } from '../types/interface/map.interface'
import { LocalizedListItemType } from '@dg-shared/CoverageInfoList'
import { QuoteType } from '../types/generated/graphql'

export type FormValidator = <FormFields>(values: FormFields) => Record<string, unknown>
export type SchemaValidator = (schema: ObjectSchema) => FormValidator

interface DeepMergeFunction {
  <Key1, Key2>(target: Key1, source: Key2): Key1 & Key2
}

export const mergeDeep: DeepMergeFunction = (target, source) => {
  const customizer = (objValue: unknown, srcValue: unknown) => {
    if (isArray(objValue)) {
      return objValue.concat(srcValue)
    }
  }

  return mergeWith(target, source, customizer)
}

// Filter items which conditions don't match form conditions(value) out of a list.
export const filterTranslationByCondition = (
  list: Array<LocalizedListItemType>,
  translationCondition?: string
) => {
  return list?.filter((el: LocalizedListItemType) => {
    if (isObject(el) && el.condition && translationCondition) {
      return translationCondition === el.condition
    }
    return true
  })
}

//TODO: Think on better way of implementing this via JSON/regexp
export const translateValidationError = (error: string) => {
  const [translationKey, valueArr] = error.split('?')
  const valueObj: Record<string, string> = {}

  if (valueArr) {
    valueArr.split('&').map((el) => {
      const [key, value] = el.split('=')
      return (valueObj[key] = value)
    })
  }

  // eslint-disable-next-line import/no-named-as-default-member
  return i18next.t(translationKey, { ...valueObj, defaultValue: '' })
}

export const formValidator: SchemaValidator = (schema: ObjectSchema) => (values) => {
  const result = schema.validate(values, { abortEarly: false })
  let errors = {}

  if (result.error) {
    errors = result.error.details.reduce(
      (acc, error) => ({
        ...acc,
        [error.path.join('.')]: translateValidationError(error.message),
      }),
      {}
    )
  }

  return errors
}

export const getSessionStorageAuthData = (storageKey: string): SessionData => {
  const userInfo = sessionStorage.getItem(storageKey)
  return !!userInfo && JSON.parse(userInfo)
}

export const filterEqual = (quotesList: Array<QuoteType>, quote: QuoteType) => {
  if (quotesList.length) {
    // NB: When the functional for multi-products will be finished. The productId could be deleted.
    return quotesList.filter(
      (item: QuoteType) =>
        item.id !== quote.id &&
        'productId' in item &&
        'productId' in quote &&
        item.productId !== quote.productId
    )
  }

  return quotesList
}

export const getDisplayName = <P>(WrappedComponent: ComponentType<P>) => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export const isIOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor
  return /iPad|iPhone|iPod/.test(userAgent)
}

function isInAppBrowserOnIphone() {
  return (
    !!/fb.+fb.+fb.+iOS/gi.test(navigator.userAgent) ||
    !!/(iphone|ios).*instagram|instagram.*(iphone|ios)/i.test(navigator.userAgent) ||
    !!/(iphone|ios).*linkedin|linkedin.*(iphone|ios)/i.test(navigator.userAgent)
  )
}

export function openBankIdApp(autoStartToken?: string) {
  // Using a timeout just to make sure the url is updated with orderRef
  const WAIT_UNTIL_SAFARI_AND_FIREFOX_IS_READY = 1000

  setTimeout(() => {
    const { userAgent } = window.navigator
    const { href } = window.location

    let url = 'bankid:///?'
    let redirect = 'null'

    if (isIOS()) {
      const e = encodeURIComponent(href)
      redirect = userAgent.match('CriOS') ? e.replace(/^http/, 'googlechrome') : e
    }

    if (autoStartToken) {
      url += `autostarttoken=${autoStartToken}&redirect=${redirect}`
    } else {
      url += `redirect=${redirect}`
    }

    if (isInAppBrowserOnIphone()) {
      // window.location = url
    } else {
      // console.log(url)
      window.location.href = url
    }
  }, WAIT_UNTIL_SAFARI_AND_FIREFOX_IS_READY)
}

export const formatMapLocations = (locations: Array<AddressComponent>) => {
  const filterLocation = (locationType: string, locations: Array<AddressComponent>) =>
    locations.filter((el: AddressComponent) => el.type.includes(locationType))[0]

  const route = filterLocation('route', locations)
  const routeName = get(route, 'long_name', '')
  const buildingNumber = filterLocation('street_number', locations)
  const buildingNumberName = get(buildingNumber, 'long_name', '')
  const localityCity = filterLocation('locality', locations)
  const localityTown = filterLocation('postal_town', locations)
  const locality = localityCity || localityTown
  const country = filterLocation('country', locations)
  const address = buildingNumberName && routeName ? [buildingNumberName, routeName].join(' ') : ''

  return {
    address: address,
    city: get(locality, 'long_name', ''),
    country: get(country, 'long_name', ''),
  }
}

export const formatWithThousandSpace = (val: string | number, reverseTrim?: boolean): string => {
  if (!val) {
    return ''
  }

  let formattedValue
  if (reverseTrim) {
    formattedValue = val.toString().replace(REGEX_NUMBER_EMPTY_SPACE, '')
    return formattedValue.replace(REGEX_THOUSANDS_SEPARATOR, '$1 ')
  }

  if (typeof val === 'number') {
    formattedValue = val.toString().replace(REGEX_THOUSANDS_SEPARATOR, '$1 ')
  } else if (typeof val === 'string') {
    formattedValue = val.replace(REGEX_THOUSANDS_SEPARATOR, '$1 ')
  } else return ''

  return formattedValue
}

export const toUpperCase = (value: string): string => value && value.toUpperCase()

export const removeAllSpaces = (val: string): string => val && val.replace(/\s/g, '')

import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { SET_MILEAGE } from '../policy.mutation'

export const setMileageMutator = async (
  client: ApolloClient<NormalizedCacheObject>,
  mileage: number,
  readDate: string,
  policyNumber: number,
  rName = 'setMileage'
): Promise<string> => {
  const response = await client.mutate({
    mutation: SET_MILEAGE,
    variables: {
      mileageData: {
        mileage,
        readDate,
      },
      policyNumber,
    },
    errorPolicy: 'all',
  })

  return response.data[rName]
}

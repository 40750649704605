import React, { FC, useState } from 'react'

import { Typography, FSMBtnNext, useTranslate, FNOLPageWrapper } from '@dg-shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events } from '../../../FSM/shared/constants'
import { useFNOLStyles } from '../shared/styles/useStyles'
import { TRANSLATIONS as T } from '../../../constants'
import RFFCustomImageUpload, { Image } from '@dg-shared/forms/RFFCustomFileUpload'
import { uploadFiles } from '../../../graphql/claim/uploadFiles.mutator'
import { useApolloClient } from '@apollo/react-hooks'

const PageDamageReport: FC<CommonPageProps> = ({ service, currentState }) => {
  const FNOLClasses = useFNOLStyles({})
  const { t } = useTranslate(`${T.COLLISION}.damageReport`)
  const client = useApolloClient()

  const [images, setImages] = useState<Array<Image>>([])

  const submitImages = async () => {
    const imageList = images.map((el) => el.data_url)
    await uploadFiles(client, imageList)
    service.send({ type: Events.NEXT, state: currentState })
  }

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t(`${T.COMMON}/back`)} breadcrumbsStepPercentage={90}>
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('title')}
      </Typography>
      <RFFCustomImageUpload images={images} setImages={setImages} />
      <FSMBtnNext
        testId='nextButton'
        handleClick={submitImages}
        buttonText={t(`${T.COMMON}/next`)}
      />
    </FNOLPageWrapper>
  )
}

export default PageDamageReport

import React, { FC } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

import {
  withHidden,
  Typography,
  useTranslate,
  LMBgHeader,
  LDCardTwoCol,
  FSMBtnNext,
} from '@dg-shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { TRANSLATIONS as T } from '../../../constants'
import { windshieldIcon, logoMax, logoMin } from 'BrandedImages'
import { Events } from '../../../FSM/shared/constants'
import BrandConfig from 'BrandConfig'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobHeaderContainer: {
      justifyContent: 'space-between',
    },
    mobContent: {
      justifyContent: 'space-evenly !important',
    },
    button: {
      fontSize: '1rem',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.contrastText,
      maxWidth: '345px',
      margin: '0 auto',
    },
    iconLink: {
      margin: '20px auto',
    },
    icon: {
      width: '60px',
    },
  })
)

const MobileScreen = withHidden(LMBgHeader, true)
const DesktopScreen = withHidden(LDCardTwoCol, false)

const FNOLPageWindshield: FC<CommonPageProps> = ({ service }) => {
  const { send } = service
  const classes = useStyles()
  const { t } = useTranslate(T.WINDSHIELD)
  const iconLinkPath = BrandConfig.FNOL.PageWindshield.iconLink
  const additionalText = t('additionalText')

  const Content = () => (
    <>
      <Typography>{t('subtitle')}</Typography>
      <Typography>{t('body')}</Typography>
      <p>
        <Typography component='span'>{additionalText[0]} </Typography>
        <Typography
          component='a'
          href={`tel:${additionalText[1]}`}
          modifiers={['bold', 'unDecorated']}
        >
          {additionalText[1]}
        </Typography>
        <Typography component='span'> {additionalText[2]}</Typography>
      </p>
      <a href={iconLinkPath} className={classes.iconLink}>
        <img className={classes.icon} src={windshieldIcon} alt={t('title') as string} />
      </a>
      <FSMBtnNext
        testId='next-button'
        buttonText={t(`${T.COMMON}/finished`)}
        handleClick={() => send(Events.NEXT)}
      />
    </>
  )

  return (
    <>
      <MobileScreen
        headerTitle={t('title')}
        textLogoAlt={t('title')}
        headerContainerClass={classes.mobHeaderContainer}
        pageClass={classes.mobContent}
        brandPageBackground='roadsideAssistance'
        imageLogo={logoMin}
        imageGradient
      >
        <>
          <Content />
        </>
      </MobileScreen>

      <DesktopScreen
        imageLogo={logoMax}
        brandPageBackground='roadsideAssistance'
        brandPageBackgroundCard='roadsideAssistance'
        textLogoAlt={t('title')}
        isReverse
      >
        <Typography type='h2'>{t('title')}</Typography>
        <Content />
      </DesktopScreen>
    </>
  )
}

export default FNOLPageWindshield

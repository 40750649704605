import React, { FC, useState } from 'react'

import { Typography, FNOLPageWrapper, useTranslate } from '@dg-shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events } from '../../../FSM/shared/constants'
import { FNOL_FORM_ID, TRANSLATIONS as T } from '../../../constants'
import FormBuilder from '@dg-shared/forms/FormBuilder'
import { useApolloClient } from '@apollo/react-hooks'
import { updateMutator } from '../../../graphql/claim/local-update.mutator'
import { useFNOLStyles } from '../shared/styles/useStyles'

interface PoliceCaseFormValues {
  caseNumber?: number
}

const PagePoliceCase: FC<CommonPageProps> = ({ service }) => {
  const { send } = service
  const { t } = useTranslate(`${T.FNOL_THEFT}.policeCase`)
  const [selectedDataFields, setSelectedDataFields] = useState<PoliceCaseFormValues>({})
  const client = useApolloClient()
  const FNOLClasses = useFNOLStyles({ externalLink: true })

  const handleSubmit = async ({ caseNumber }: PoliceCaseFormValues) => {
    await updateMutator(client, {
      // TODO: Check these fields for a real request
      caseNumber,
    })

    send(Events.NEXT)
  }

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t('common/back')} breadcrumbsStepPercentage={90}>
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('title')}
      </Typography>
      <Typography testId='subtitle' className={FNOLClasses.description}>
        {t('subtitle')}
      </Typography>
      <FormBuilder
        id={FNOL_FORM_ID.POLICE_CASE}
        classes={FNOLClasses.formContainer}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setFormState={({ values }) => setSelectedDataFields({ ...selectedDataFields, ...values })}
        submitHandler={handleSubmit}
        filledFieldsData={selectedDataFields}
        isFormControllable
      />
    </FNOLPageWrapper>
  )
}

export default PagePoliceCase

import React, { FC, useContext } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import { TRANSLATIONS as T } from '../../../../constants'
import { PartnerName, ProductName } from '../../../../types/interface/quote.interface'
import { Typography, useTranslate, ProductWrapper } from '@dg-shared'
import ComparePolicyCard from './ComparePolicyCard'
import Context from '../../../../Context'
import { InsurelyResultType } from '../../../../types/insurelyTypes'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      margin: 'auto',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(5),
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '345px',
      },
    },
    disclaimer: {
      alignSelf: 'flex-end',
    },
  })
)

const OfferCompareOffers: FC = () => {
  const { t } = useTranslate(T.OFFER_COMPARE_POLICY)
  const classes = useStyles()
  const [contextState, setContextState] = useContext(Context)
  const { insurelyResults } = contextState
  const selectOffer = (selectedInsurelyQuote: InsurelyResultType) => {
    setContextState({ selectedInsurelyQuote })
  }

  return (
    <>
      <Grid container className={classes.wrapper}>
        {insurelyResults?.map((offer: InsurelyResultType) => (
          <ComparePolicyCard
            item={offer}
            key={`card_price_${offer.insurance.externalId}`}
            chooseOffer={() => selectOffer(offer)}
          />
        ))}

        <ProductWrapper partners={[PartnerName.instabank]} products={[ProductName.VEHICLE]}>
          <Typography className={classes.disclaimer} color='textDisclaimer' size='small'>
            {t(`${T.COMMON}:priceWarning.2`)}
          </Typography>
        </ProductWrapper>
      </Grid>
    </>
  )
}

export default OfferCompareOffers

import { Events } from '../../../../../../FSM/shared/constants'
import { FSMTransitionsFNOLVehicleSE } from '../constants'
import { has, get } from 'lodash'
import { ContextType } from '../../../../../../FSM/shared/config'

const {
  FLOW_MACHINE_ID,
  AUTH_BANK_ID,
  FNOL_CLAIMS,
  FNOL_CLAIMS_LIST,
  FNOL_THEFT_FLOW,
  FNOL_THEFT_MORE_INFO,
  FNOL_THEFT_STOLEN_IN_CAR,
  FNOL_THEFT_STOLEN_FROM_CAR,
  FNOL_THEFT_STOLEN_AGE_VALUE,
  FNOL_THEFT_STOLEN_WHEN_WHERE,
  FNOL_THEFT_POLICE_CASE,
  FNOL_ADDITIONAL_INFORMATION,
  FNOL_TIME_LINE,
} = FSMTransitionsFNOLVehicleSE

export default {
  [FNOL_THEFT_FLOW]: {
    initial: FNOL_THEFT_MORE_INFO,
    states: {
      [FNOL_THEFT_MORE_INFO]: {
        on: {
          [Events.BACK]: `#${FLOW_MACHINE_ID}.${FNOL_CLAIMS}.${FNOL_CLAIMS_LIST}`,
          [Events.NEXT]: { target: FNOL_THEFT_STOLEN_WHEN_WHERE, actions: 'setBackPath' },
          [Events.FNOL_THEFT_STOLEN_IN_CAR]: FNOL_THEFT_STOLEN_IN_CAR,
          [Events.FNOL_THEFT_STOLEN_FROM_CAR]: FNOL_THEFT_STOLEN_FROM_CAR,
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_THEFT_STOLEN_IN_CAR]: {
        on: {
          [Events.BACK]: FNOL_THEFT_MORE_INFO,
          [Events.NEXT]: { target: FNOL_THEFT_STOLEN_AGE_VALUE, actions: 'setBackPath' },
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_THEFT_STOLEN_FROM_CAR]: {
        on: {
          [Events.BACK]: FNOL_THEFT_MORE_INFO,
          [Events.NEXT]: { target: FNOL_THEFT_STOLEN_AGE_VALUE, actions: 'setBackPath' },
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_THEFT_STOLEN_AGE_VALUE]: {
        on: {
          [Events.BACK]: [
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_THEFT_FLOW}.${FNOL_THEFT_STOLEN_IN_CAR}`,
              cond: (ctx: ContextType) =>
                has(ctx.backPath, FNOL_THEFT_FLOW) &&
                get(ctx.backPath, FNOL_THEFT_FLOW) === FNOL_THEFT_STOLEN_IN_CAR,
            },
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_THEFT_FLOW}.${FNOL_THEFT_STOLEN_FROM_CAR}`,
              cond: (ctx: ContextType) =>
                has(ctx.backPath, FNOL_THEFT_FLOW) &&
                get(ctx.backPath, FNOL_THEFT_FLOW) === FNOL_THEFT_STOLEN_FROM_CAR,
            },
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_THEFT_FLOW}.${FNOL_THEFT_MORE_INFO}`,
              cond: (ctx: ContextType) =>
                has(ctx.backPath, FNOL_THEFT_FLOW) &&
                get(ctx.backPath, FNOL_THEFT_FLOW) === FNOL_THEFT_MORE_INFO,
            },
          ],
          [Events.NEXT]: { target: FNOL_THEFT_STOLEN_WHEN_WHERE },
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_THEFT_STOLEN_WHEN_WHERE]: {
        on: {
          [Events.BACK]: [
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_THEFT_FLOW}.${FNOL_THEFT_MORE_INFO}`,
              cond: (ctx: ContextType) =>
                has(ctx.backPath, FNOL_THEFT_FLOW) &&
                get(ctx.backPath, FNOL_THEFT_FLOW) === FNOL_THEFT_MORE_INFO,
            },
            { target: FNOL_THEFT_STOLEN_AGE_VALUE },
          ],
          [Events.NEXT]: { target: FNOL_THEFT_POLICE_CASE },
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_THEFT_POLICE_CASE]: {
        on: {
          [Events.BACK]: FNOL_THEFT_STOLEN_WHEN_WHERE,
          [Events.NEXT]: FNOL_ADDITIONAL_INFORMATION,
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_ADDITIONAL_INFORMATION]: {
        on: {
          [Events.BACK]: FNOL_THEFT_POLICE_CASE,
          [Events.NEXT]: FNOL_TIME_LINE,
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_TIME_LINE]: {
        on: {
          [Events.BACK]: FNOL_ADDITIONAL_INFORMATION,
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
    },
  },
}

import React, { FC } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { TRANSLATIONS as T } from '../../../../constants'
import { CTA_TYPE_INSTABANK_LINK } from '../../../../constants/cta-constants'
import { FSMBtnBack, CTA, Typography, useTranslate } from '@dg-shared'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backBtn: {
      paddingTop: '20px',
      [theme.breakpoints.up('md')]: {
        marginLeft: '-10px',
      },
      justifyContent: 'flex-start',
    },
    bodyText: {
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
      },
    },
    bodyContainer: {
      flexGrow: 1,
      justifyContent: 'flex-end',
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(6),
      },
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(4),
      },
    },
  })
)

interface FailProps {
  handleBack(): Promise<void>
}

const Fail: FC<FailProps> = ({ handleBack }) => {
  const { t } = useTranslate(T.OFFER_PURCHASED_FAIL)
  const classes = useStyles()

  return (
    <>
      <div className={classes.bodyContainer}>
        <FSMBtnBack
          handleBack={handleBack}
          buttonText={t(`${T.COMMON}/back`)}
          customClass={classes.backBtn}
        />
        {/* //FIXME: was an error with the .map and t fucn */}
        {t('annotation', {}, 'obj').map((el, key) => (
          <Typography key={key} type='h5' className={classes.bodyText}>
            {el}
          </Typography>
        ))}
      </div>
      <CTA context={CTA_TYPE_INSTABANK_LINK} />
    </>
  )
}

export default Fail

import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '../../shared/typography/Typography'

import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { useTranslate } from '../hooks'

const useAuthStyles = makeStyles((theme: Theme) =>
  createStyles({
    captchaText: {
      display: 'block',
      marginTop: theme.spacing(1),
      textAlign: 'center',
      '& a': {
        color: 'inherit',
      },
    },
  })
)

interface ReCaptchaPrivacyTermsProps {
  links: {
    PRIVACY: string
    TERMS: string
  }
}

const ReCaptchaPrivacyTerms: FC<ReCaptchaPrivacyTermsProps> = ({ links: { PRIVACY, TERMS } }) => {
  const { t } = useTranslate(T.COMMON_RECAPTCHA)

  const classes = useAuthStyles()

  return (
    <Typography
      component='caption'
      size='small'
      color='textDisclaimer'
      modifiers={['preFormatted']}
      className={classes.captchaText}
    >
      {t('text.0')}
      <a href={PRIVACY} target='_blank' rel='noreferrer'>
        {t('text.1')}
      </a>
      {t('text.2')}
      <a href={TERMS} target='_blank' rel='noreferrer'>
        {t('text.3')}
      </a>
      {t('text.4')}
    </Typography>
  )
}

export default ReCaptchaPrivacyTerms

import React, { FC } from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { TRANSLATIONS as T } from '../../../constants'
import { Events } from '../../../FSM/shared/constants'
import { QuoteType } from '../../../types/generated/graphql'
import useLogic from './useLogic'
import ChangeItem from './Item'
import Loading from '../Loading/Page'
import { LMBasicV3FSM, LDContainerV2FSM, FSMBtnNext, Typography, useTranslate } from '@dg-shared'
import { getStepPercentage } from '@dg-util'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import PageWrapper from '@dg-shared/PageWrapper'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    annotation: {
      marginBottom: theme.spacing(2),
    },
    buttonNext: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        margin: 'auto',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        maxWidth: '345px',
      },
    },
  })
)

const ChangePage: FC<CommonPageProps> = ({ service, currentState }) => {
  const { t } = useTranslate(T.OFFER_CHANGE)
  const client = useApolloClient()
  const classes = useStyles()
  const stepPercentage = getStepPercentage(currentState)

  const { loading, quote, formBtnDisabled, handleQuoteUpdate, initialFormValues, updateFormState } =
    useLogic(client)

  //FIXME: Task created: DFE-1584
  const offerItem = (
    <ChangeItem
      itemData={quote as QuoteType}
      initialFormValues={initialFormValues}
      updateFormState={updateFormState}
      updateOfferHandler={handleQuoteUpdate}
      submitDisabled={formBtnDisabled}
    />
  )

  const buttonNext = !!quote && (
    <FSMBtnNext
      handleClick={() => service.send(Events.NEXT)}
      buttonText={t(`${T.COMMON}/next`)}
      customClassName={classes.buttonNext}
      outlined
    />
  )

  if (loading) {
    return <Loading />
  }

  //FIXME: Refactor this quote check
  return (
    quote && (
      <PageWrapper
        mobile={LMBasicV3FSM}
        desktop={LDContainerV2FSM}
        breadcrumbsBtnText={t(`${T.COMMON}/back`)}
        breadcrumbsStepPercentage={stepPercentage}
      >
        <Typography type='h1'>{t('title')}</Typography>
        <Typography className={classes.annotation}>{t('subtitle')}</Typography>
        {offerItem}
        {buttonNext}
      </PageWrapper>
    )
  )
}

export default ChangePage

import React, { FC } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Card, CardContent, Grid, Hidden } from '@material-ui/core'

import { StylesProps } from '../../../types/interface/styles.interface'
import { FlowType } from '../../../constants'

import { BrandedHeaderShared, BreadcrumbsV2, Typography } from '../'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: (props: StylesProps) => ({
      minHeight: '100vh',
      backgroundColor:
        (!!props.backgroundColor && props.backgroundColor) || theme.palette.secondary.contrastText, // FIXME: Remove this prop when Background color will be the same on all pages
      justifyContent: 'center',
      alignContent: 'start',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        minWidth: '750px',
        paddingBottom: theme.spacing(5),
      },
    }),
    header: {
      display: 'flex',
      flexDirection: 'column',
      height: '80px',
      justifyContent: 'space-around',
    },
    wrapper: {
      width: '100%',
      display: 'flex',
      textAlign: 'center',
      flexDirection: 'column',
      padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(2)}px`,
      [theme.breakpoints.up('md')]: {
        maxWidth: '920px',
        margin: `${theme.spacing(2)}px auto auto`,
      },
    },
    title: {
      margin: '0',
      [theme.breakpoints.up('md')]: {
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(1)}px`,
      },
    },
    content: {
      margin: '0 auto',
      [theme.breakpoints.up('md')]: {
        maxWidth: '375px',
        width: '100%',
      },
    },
  })
)

export interface LDContentCardPropsType {
  flow: FlowType
  classCustom?: string
  titleHeader?: string
  paragraphSubTitle?: string
  breadcrumbsBtnText?: string
  breadcrumbsBtnPath?: string
  breadcrumbsStepPercentage?: number
  breadcrumbs?: boolean
  bgColor?: string
}

const LDContentCard: FC<LDContentCardPropsType> = ({
  flow,
  children,
  titleHeader,
  paragraphSubTitle,
  breadcrumbsBtnText,
  breadcrumbsBtnPath,
  breadcrumbsStepPercentage,
  breadcrumbs = false,
  bgColor,
}) => {
  const classes = useStyles({ backgroundColor: bgColor })

  return (
    <Grid container direction='row' component='article' className={classes.container}>
      <Hidden mdUp={false} smDown={true}>
        <Grid item xs={12} className={classes.header}>
          <BrandedHeaderShared />
        </Grid>
      </Hidden>

      <Grid item xs={12}>
        {/* //FIXME: Remove !!breadcrumbsBtnText from the logic expression in case
          // to simplify the logic. For now we need it to avoid regression */}
        {(breadcrumbs || !!breadcrumbsBtnText) && (
          <BreadcrumbsV2
            flow={flow}
            goTo={breadcrumbsBtnPath}
            buttonText={breadcrumbsBtnText}
            stepsPercentage={breadcrumbsStepPercentage}
          />
        )}
      </Grid>

      <Card className={classes.wrapper}>
        {!!titleHeader && (
          <Typography type='h1' className={classes.title}>
            {titleHeader}
          </Typography>
        )}
        {!!paragraphSubTitle && <Typography>{paragraphSubTitle}</Typography>}
        <CardContent className={classes.content}>{children}</CardContent>
      </Card>
    </Grid>
  )
}

export default LDContentCard

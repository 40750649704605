import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { GET_CLAIM_INITIATED, GET_CLAIM_SELECTED } from './query'

//TODO: Refactor these queries
export const initiatedClaimGetterLocal = async (
  client: ApolloClient<NormalizedCacheObject>,
  rName: Array<string> = ['claims', 'initatedClaim', 'flow']
): Promise<Record<string, string>> => {
  const response = await client.query({ query: GET_CLAIM_INITIATED })

  return response.data[rName[0]][rName[1]][rName[2]]
}

export const selectedClaimGetterLocal = async (
  client: ApolloClient<NormalizedCacheObject>,
  rName: Array<string> = ['claims', 'selectedClaim']
): Promise<{ licensePlateNumber: string; policyNumber: string; coverageType: number }> => {
  const response = await client.query({ query: GET_CLAIM_SELECTED })

  return response.data[rName[0]][rName[1]]
}

import React, { FC } from 'react'
import { FieldRenderProps } from 'react-final-form'
import clsx from 'clsx'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core'

//TODO: Remove this code from the current component
//TODO: Propagate the translations from the top
import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { useTranslate } from '@dg-shared'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `1px solid ${theme.palette.primary.main}`,
      position: 'relative',
      margin: 0,
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.primary.dark,
      fontSize: '1rem',
      '&:first-child': {
        borderRight: 'none',
      },
    },
    label: {
      width: '100%',
      textAlign: 'center',
      fontSize: '1rem',
      position: 'absolute',
      color: theme.palette.primary.dark,
    },
    legend: {
      fontSize: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    control: {
      paddingBottom: theme.spacing(2),
    },
    inputRadio: {
      borderRadius: 0,
      width: '70px',
      height: '30px',
      backgroundColor: theme.palette.primary.contrastText,
      padding: '5px',
      '&$checked': {
        color: 'red',
      },
    },
    icon: {
      borderRadius: 0,
    },
    checkedIcon: {
      borderRadius: 0,
    },
    checked: {
      '&, & + $label': {
        '& > span': {
          backgroundColor: theme.palette.primary.main,
          BorderRight: 0,
        },
        color: theme.palette.primary.contrastText,
      },
    },
  })
)

interface RFFFormControlRadioProps extends FieldRenderProps<unknown> {
  fieldLabel: string
  fieldName: string
}

const RFFFormControlRadio: FC<RFFFormControlRadioProps> = ({
  input,
  meta,
  fieldLabel,
  fieldName,
  ...props
}) => {
  const { t } = useTranslate(T.COMMON) //FIXME: Looks like we don't need it
  const classes = useStyles()
  const isError: boolean = (!!meta.error || meta.submitError) && !meta.pristine

  return (
    <FormControl component='fieldset' className={classes.control} error={isError}>
      <FormLabel component='legend' className={classes.legend}>
        {fieldLabel}
      </FormLabel>

      <RadioGroup
        name={fieldName}
        onChange={input.onChange}
        value={input.value}
        aria-label={fieldLabel}
        row
      >
        <FormControlLabel
          classes={{
            root: classes.root,
            label: classes.label,
          }}
          key={`${fieldName}_1`}
          label={t(`${T.COMMON}/${props.options.yes.text}`)}
          value={props.options.yes.value}
          // disabled={item.disabled}
          control={
            <Radio
              classes={{ root: classes.inputRadio, checked: classes.checked }}
              checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
              icon={<span className={classes.icon} />}
            />
          }
        />
        <FormControlLabel
          classes={{
            root: classes.root,
            label: classes.label,
          }}
          key={`${fieldName}_2`}
          label={t(`${T.COMMON}/${props.options.no.text}`)}
          value={props.options.no.value}
          // disabled={item.disabled}
          control={
            <Radio
              classes={{ root: classes.inputRadio, checked: classes.checked }}
              checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
              icon={<span className={classes.icon} />}
            />
          }
        />
      </RadioGroup>
      {isError && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  )
}

export default RFFFormControlRadio

import { gql } from 'graphql-tag'

import { FRAGMENT_QUOTE_ERROR_VALIDATION } from '../fragments/QuoteErrorValidation.fragment'
import { FRAGMENT_QUOTE_ERROR_GENERAL } from '../fragments/QuoteErrorGeneral.fragment'

export const GET_POLICIES_LIST = gql`
  query GetPolicies {
    policies {
      ... on PolicyList {
        list {
          id
          title
          insuredFrom
          insuredTo
          licensePlateNumber
        }
      }
      ... on ServiceErrorValidation {
        ...ServiceErrorValidationItems
      }
      ... on ServiceError {
        ...ServiceErrorItems
      }
    }
  }
  ${FRAGMENT_QUOTE_ERROR_VALIDATION}
  ${FRAGMENT_QUOTE_ERROR_GENERAL}
`

export const GET_DOCUMENT_FILE = gql`
  query PolicyFile($fileId: Float!) {
    policyFile(fileId: $fileId) {
      ... on DocumentFile {
        id
        name
        mime
        content
      }
      ... on ServiceErrorValidation {
        ...ServiceErrorValidationItems
      }
      ... on ServiceError {
        ...ServiceErrorItems
      }
    }
  }
  ${FRAGMENT_QUOTE_ERROR_VALIDATION}
  ${FRAGMENT_QUOTE_ERROR_GENERAL}
`

import { assign } from 'xstate'
import { get } from 'lodash'

const setUserAuthorized = assign({
  authorized: true,
})

const setBackPath = assign({
  backPath: (context, event) => get(event, 'state'),
})

const signOut = assign({ authorized: false, vippsAuth: false })

const setVippsAuth = assign({ vippsAuth: true })

export default { setUserAuthorized, setBackPath, signOut, setVippsAuth }

import React, { FC, useState } from 'react'

import { Typography, FNOLPageWrapper, useTranslate } from '@dg-shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events } from '../../../FSM/shared/constants'
import { FNOL_FORM_ID, TRANSLATIONS as T } from '../../../constants'
import FormBuilder from '@dg-shared/forms/FormBuilder'
import { updateMutator } from '../../../graphql/claim/local-update.mutator'
import { useApolloClient } from '@apollo/react-hooks'
import { useFNOLStyles } from '../shared/styles/useStyles'

interface AgeValueFormValues {
  age?: number
  value?: number
}

const PageStolenAgeValue: FC<CommonPageProps> = ({ service }) => {
  const { send } = service
  const { t } = useTranslate(`${T.FNOL_THEFT}.stolenAgeValue`)
  const [selectedDataFields, setSelectedDataFields] = useState<AgeValueFormValues>({})
  const client = useApolloClient()
  const FNOLClasses = useFNOLStyles({ externalLink: true })

  const handleSubmit = async ({ age, value }: AgeValueFormValues) => {
    await updateMutator(client, {
      // TODO: Check these fields for a real request
      age,
      value,
    })

    send(Events.NEXT)
  }

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t('common/back')} breadcrumbsStepPercentage={65}>
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('title')}
      </Typography>
      <FormBuilder
        classes={FNOLClasses.formContainer}
        id={FNOL_FORM_ID.STOLEN_AGE_VALUE}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setFormState={({ values }) => setSelectedDataFields({ ...selectedDataFields, ...values })}
        submitHandler={handleSubmit}
        filledFieldsData={selectedDataFields}
        isFormControllable
      />
    </FNOLPageWrapper>
  )
}

export default PageStolenAgeValue

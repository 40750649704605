import React, { FC } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Card, CardContent, CardHeader } from '@material-ui/core'

import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { PartnerName, ProductName } from '../../../types/interface/quote.interface'
import { TravelNo, VehicleNo, VehicleSe, QuoteType } from '../../../types/generated/graphql'
import { Typography, useTranslate, ProductWrapper } from '@dg-shared'
import VehicleCardContentNO from './Content/VehicleNO'
import VehicleCardContentSE from './Content/VehicleSE'
import TravelCardContent from './Content/Travel'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      margin: '0 auto',
      maxWidth: '345px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(2),
      },
    },
    header: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    title: {
      color: theme.palette.secondary.contrastText,
      fontSize: theme.spacing(3),
      fontWeight: 500,
    },
    disclaimerText: {
      marginBottom: 0,
    },
  })
)

interface CardVehicleProps {
  offer: QuoteType
}

const OfferCard: FC<CardVehicleProps> = ({ offer }) => {
  const { t } = useTranslate(`${T.OFFER_VERIFY}.card`)
  const classes = useStyles()

  return (
    <Card elevation={3} className={classes.card}>
      <CardHeader
        titleTypographyProps={{
          className: classes.title,
        }}
        className={classes.header}
        title={t('title')}
      />

      <CardContent>
        <ProductWrapper partners={[PartnerName.instabank]} products={[ProductName.VEHICLE]}>
          <VehicleCardContentNO offer={offer as VehicleNo} />
        </ProductWrapper>
        <ProductWrapper partners={[PartnerName.circlek]} products={[ProductName.VEHICLE]}>
          <VehicleCardContentSE offer={offer as VehicleSe} />
        </ProductWrapper>
        <ProductWrapper partners={Object.values(PartnerName)} products={[ProductName.TRAVEL]}>
          <TravelCardContent offer={offer as TravelNo} />
        </ProductWrapper>
        <Typography color='textDisclaimer' className={classes.disclaimerText} size='small'>
          {t('priceTotal', { priceTotal: offer.priceTotal })}
        </Typography>

        <ProductWrapper partners={[PartnerName.circlek]} products={[ProductName.VEHICLE]}>
          <Typography color='textDisclaimer' className={classes.disclaimerText} size='small'>
            {t('priceTotalDisclaimer')}
          </Typography>
        </ProductWrapper>
      </CardContent>
    </Card>
  )
}

export default OfferCard

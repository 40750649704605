import { ThemeOptions } from '@material-ui/core'
import { ThemeBrandColorsType, ThemeBrandTypographyType, ThemeBrandAssetsType } from './types'
import { defaultTypography, defaultColors, defaultAssets } from './defaultTheme'

export const getBrandedTheme = (
  brandColors: ThemeBrandColorsType = defaultColors,
  brandTypography: ThemeBrandTypographyType = defaultTypography,
  brandAssets: ThemeBrandAssetsType = defaultAssets,
  brandOverrides?: Record<string, unknown>
): ThemeOptions => {
  return {
    breakpoints: {
      values: {
        xs: 0,
        sm: 321,
        md: 426,
        lg: 1025,
        xl: 1920,
      },
    },
    // Default palette mapped with Brand Related Theme Options
    palette: {
      primary: {
        main: brandColors.main,
        contrastText: brandColors.background,
        dark: brandColors.textMain,
        light: brandColors.background,
      },
      secondary: {
        main: brandColors.headingMain,
        dark: brandColors.textSecondary,
        contrastText: brandColors.clean,
        light: brandColors.clean,
      },
      info: {
        main: brandColors.textPlaceholder,
        contrastText: brandColors.textDisclaimer,
        dark: brandColors.textSecondary,
        light: brandColors.textDisclaimer,
      },
      error: {
        main: brandColors.textError,
        contrastText: brandColors.textDisclaimer,
        dark: brandColors.textSecondary,
        light: brandColors.textDisclaimer,
      },
      success: {
        main: brandColors.main,
        contrastText: brandColors.background,
        dark: brandColors.main,
        light: brandColors.background,
      },
      warning: {
        main: brandColors.textError,
        contrastText: brandColors.textDisclaimer,
        dark: brandColors.textSecondary,
        light: brandColors.textDisclaimer,
      },
    },
    // Brand Related Theme Options
    brand: {
      colors: brandColors,
      typography: brandTypography,
      assets: brandAssets,
    },
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        'sans-serif',
        ...brandTypography.extraFonts,
      ].join(','),
      h1: brandTypography.heading.h1,
      h2: brandTypography.heading.h2,
      h3: brandTypography.heading.h3,
      h4: brandTypography.heading.h4,
      h5: brandTypography.heading.h5,
      h6: brandTypography.heading.h6,
      body1: {
        color: brandColors.textMain,
        ...brandTypography.text.normal,
      },
      body2: {
        color: brandColors.textSecondary,
        ...brandTypography.text.small,
      },
      caption: {
        color: brandColors.textDisclaimer,
        ...brandTypography.text.small,
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': brandTypography.customFontFaces,
          code: {
            fontFamily: [
              'source-code-pro',
              'Menlo, Monaco',
              'Consolas',
              'Courier New',
              'monospace',
              ...brandTypography.extraFonts,
            ].join(','),
          },
        },
      },
      MuiCard: {
        root: {
          borderRadius: '8px',
          boxShadow: '0px 6px 15px rgba(13, 51, 32, 0.1)',
        },
      },
      MuiButton: {
        root: {
          borderRadius: '8px',
          padding: '10px',
          '&:hover': {
            color: brandColors.clean,
            backgroundColor: brandColors.buttonActive,
            opacity: 0.75,
          },
        },
        contained: {
          '&:hover': {
            color: brandColors.clean,
            backgroundColor: brandColors.buttonActive,
            opacity: 0.75,
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          border: 0,
          borderRadius: '8px',
          boxShadow: '0px 6px 20px rgba(13, 51, 32, 0.1)',
        },
        notchedOutline: {
          border: 0,
        },
        focused: {},
      },
      MuiInputLabel: {
        shrink: {
          transform: 'translate(0, 1.5px) scale(1)',
        },
      },
      MuiInputBase: {
        root: {
          fontSize: '16px',
        },
      },
      MuiSelect: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiMenuItem: {
        root: {
          color: brandColors.textMain,
          fontSize: '1rem',
        },
      },
      MuiRadio: {
        root: {
          color: brandColors.main,
          '&$checked': {
            color: brandColors.main,
          },
          '&$checked + $label': {
            backgroundColor: 'yellow',
          },
        },
        colorSecondary: {
          color: brandColors.main,
          '&$checked': {
            color: brandColors.main,
          },
        },
        checked: {},
      },
      MuiSwitch: {
        colorSecondary: {
          '&$checked': {
            color: brandColors.main,
          },
          '&$checked + $track': {
            backgroundColor: brandColors.main,
          },
        },
        checked: {},
        track: {},
      },
      MuiFormLabel: {
        root: {
          color: brandColors.textSecondary,
          '&$focused': {
            color: brandColors.textSecondary,
          },
        },
      },
      ...brandOverrides,
    },
  }
}

import React, { FC, useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { FORM_ID } from '../../../../constants/forms-constants'
import { MileageFormValues } from '../../../../types/interface/form.interface'
import { FSMBtnNext, ExternalSubmitBtn, Typography, useTranslate } from '@dg-shared'
import FormBuilder from '@dg-shared/forms/FormBuilder'

interface MileageProps {
  handleSubmit(values: MileageFormValues): void
  handleSkip(): void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headTextForm: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
      },
    },
    formContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
    form: {
      height: '100%',
      paddingTop: theme.spacing(2),
    },
    nextBtn: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1),
      maxWidth: 'initial',
    },
    externalBtn: {
      marginTop: theme.spacing(0),
      fontSize: '1rem',
      maxWidth: 'initial',
    },
  })
)

const Mileage: FC<MileageProps> = ({ handleSubmit, handleSkip }) => {
  const { t } = useTranslate('mileage')
  const classes = useStyles()
  const [submitBtnState, setBtnState] = useState<boolean>(true)

  return (
    <>
      <section className={classes.formContainer}>
        <Typography className={`${classes.headTextForm}`}>{t('form.annotation')}</Typography>
        <FormBuilder
          id={FORM_ID.MILEAGE}
          submitHandler={handleSubmit}
          handleBtnDisabled={setBtnState}
          classes={classes.form}
          customSubmit
        />
      </section>
      <section>
        <ExternalSubmitBtn
          disabled={submitBtnState}
          targetId={FORM_ID.MILEAGE}
          text={t('form.submit')}
          customClassName={classes.externalBtn}
        />
        <FSMBtnNext
          handleClick={handleSkip}
          buttonText={t('skipBtn')}
          customClassName={classes.nextBtn}
          outlined
        />
      </section>
    </>
  )
}

export default Mileage

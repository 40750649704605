import { useState, useEffect } from 'react'
import { ApolloClient } from 'apollo-client'
import { get } from 'lodash'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { Events } from '../../../../../FSM/shared/constants'
import { ServiceSend } from '../../../../../types/interface/fsm.interface'
import { REGEX_NUMBER_EMPTY_SPACE } from '../../../../../constants/regex-constants'
import { initiatedClaimGetterLocal } from '../../../../../graphql/claim/local-selected.getter'
import { updateMutator } from '../../../../../graphql/claim/local-update.mutator'

interface CompensationFormValue {
  compensation?: string
}

interface CompensationHook {
  selectedDataFields: CompensationFormValue
  handleSubmit(values: CompensationFormValue): Promise<void>
  updateForm(values: CompensationFormValue): void
}

const useLogic = (
  client: ApolloClient<NormalizedCacheObject>,
  send: ServiceSend
): CompensationHook => {
  const [selectedDataFields, setSelectedDataFields] = useState<CompensationFormValue | null>(null)

  useEffect(() => {
    const getClaimLocal = async () => {
      const dataSelected = await initiatedClaimGetterLocal(client)

      const valueFromStorage = get(dataSelected, 'compensation')

      if (valueFromStorage) {
        setSelectedDataFields({ compensation: valueFromStorage })
      }

      return dataSelected
    }

    getClaimLocal()
  }, [client])

  const handleSubmit = async (values: { compensation: string }) => {
    const compensation = parseInt(values.compensation.replace(REGEX_NUMBER_EMPTY_SPACE, ''), 10)
    await updateMutator(client, { compensation })

    send(Events.NEXT)
  }

  return {
    selectedDataFields,
    handleSubmit,
    updateForm: setSelectedDataFields,
  }
}

export default useLogic

import { ErrorTypes, ErrorCode } from '../types/interface/error.interface'
import { Events } from '../FSM/shared/constants'

export const ERROR_CODE_QUOTE_CONSTRAINTS: ErrorCode = 2000
export const ERROR_CODE_QUOTE_GENERAL: ErrorCode = 1000
export const ERROR_CODE_QUOTE_VALIDATION: ErrorCode = 1002
export const ERROR_CODE_UNKNOWN_CHARACTERISTICS: ErrorCode = 2001
export const ERROR_CODE_SESSION_EXPIRED: ErrorCode = 1001
export const ERROR_CODE_REG_NUM: ErrorCode = 3001

export const ERROR_QUOTE_CONSTRAINTS = 'ERROR_QUOTE_CONSTRAINTS'
export const ERROR_GENERAL = 'ERROR_GENERAL'
export const ERROR_PAYMENT = 'ERROR_PAYMENT'
export const ERROR_QUOTE_VALIDATION = 'ERROR_QUOTE_VALIDATION'

export const AUTH_ERROR_TRANSLATION_KEYS: { [type: string]: string } = {
  [Events.WRONG_PRODUCT_ID]: 'regNumNotFound',
  [Events.AUTH_ERROR]: `sessionGeneral`,
  [Events.SESSION_EXPIRED]: 'sessionExpired',
}

//TODO: Refactor this object to using error codes as keys
export const ERROR_TYPE: ErrorTypes = {
  ERROR_QUOTE_CONSTRAINTS: {
    code: ERROR_CODE_QUOTE_CONSTRAINTS,
    title: 'underwriting.title',
    content: 'underwriting.content',
    linkText: 'underwriting.linkText',
    emailText: 'underwriting.emailText',
    showBtn: true,
    errorCardDesktop: 'errorCardSecondary',
    errorCardMobile: 'errorCardMobileSecondary',
  },
  ERROR_QUOTE_VALIDATION: {
    code: ERROR_CODE_QUOTE_VALIDATION,
    title: 'underwriting.title',
    content: 'underwriting.content',
    linkText: 'underwriting.linkText',
    emailText: 'underwriting.emailText',
    showBtn: true,
    errorCardDesktop: 'errorCardSecondary',
    errorCardMobile: 'errorCardMobileSecondary',
  },
  ERROR_GENERAL: {
    code: ERROR_CODE_QUOTE_GENERAL,
    title: 'title',
    content: 'content',
    linkText: 'CTA.link',
    showBtn: true,
    errorCardDesktop: 'errorCardMain',
    errorCardMobile: 'errorCardMobileMain',
  },
}

import React, { FC, ReactElement, Suspense, useCallback, useState } from 'react'
import { Router } from 'react-router-dom'
import { CssBaseline } from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import TagManager from 'react-gtm-module'
import * as Sentry from '@sentry/react'
import { getProductName } from '@dg-util'
import { inspect } from '@xstate/inspect'

import './i18n'
import THEME from 'BrandTheme'
import history from './routes/history'
import Loading from './pages/qb/Loading/Page'
import { FlowService, flowMachine, FSMProvider } from './FSM'
import { version } from '../package.json'
import errorLoggerService from './service/errorLogger.service'
import { PartnerName } from './types/interface/quote.interface'
import CONFIG, { ENV, IS_DEVELOPMENT, IS_LOCAL } from './config'
import { IconType } from './types/interface/icon.interface'
import Context, { ContextStateType } from './Context'

errorLoggerService.setPartner(PARTNER_NAME as PartnerName)

// Sentry initialisation
Sentry.init({
  dsn: 'https://37a2461ea03542bda1f1a85d860df61e@o1129041.ingest.sentry.io/6172544',
  environment: process.env.ENV,
  debug: IS_LOCAL || IS_DEVELOPMENT,
})

// xState visualizer (inspector) switch
const xStateVisualizerEnabled = localStorage.getItem('xStateVisualizerEnabled')

// Enable XState inspector in debug mode with enabled DEV_TOOLS and xStateVisualizerEnabled true
if (CONFIG.DEV_TOOLS && xStateVisualizerEnabled) inspect({ iframe: false })

// Get product name + (Initial Product id (PID) initialization from URL query param under the hood)
const productName = getProductName()

// Initialize GTM with TRACKING_ID targeted on specific BRAND, PRODUCT, ENV
TagManager.initialize({
  gtmId: CONFIG.TRACKING_ID[productName][ENV],
})

const AppWrapper: FC<{
  children?: ReactElement[] | ReactElement
  themeProps?: Record<string, unknown>
  contextInitialState?: ContextStateType
}> = ({ children, themeProps = {}, contextInitialState = {} }) => {
  const theme = createTheme({ ...THEME, ...themeProps })
  const [contextState, setContextState] = useState<Record<string, unknown>>(contextInitialState)
  const setContext = useCallback((newContextState: Record<string, unknown>) => {
    setContextState((prevState) => ({ ...prevState, ...newContextState }))
  }, [])

  const content = children ? (
    // TODO: Fix TS history types here. Maybe after migrating to the `react-router-dom` v6 it will be easier
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Router history={history}>
      <FSMProvider service={FlowService} machine={flowMachine}>
        {children}
      </FSMProvider>
    </Router>
  ) : (
    <Loading />
  )

  return (
    <Suspense fallback={IconType.LOADING}>
      <Context.Provider value={[contextState, setContext]}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {content}
          <p hidden>{`app v${version}`}</p>
        </ThemeProvider>
      </Context.Provider>
    </Suspense>
  )
}

export default AppWrapper

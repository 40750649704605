import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { VERIFY_QUOTE } from '../quote/mutation'
import { QuoteVerificationInput } from '../../types/generated/graphql'

export const verifyMutator = async (
  client: ApolloClient<NormalizedCacheObject>,
  quoteId: number,
  verifyData: QuoteVerificationInput
): Promise<void> => {
  await client.mutate({
    mutation: VERIFY_QUOTE,
    variables: {
      verifyData,
      id: quoteId,
    },
  })
}

import React, { FC } from 'react'
import { Hidden, CardContent, Divider } from '@material-ui/core'
import { Events } from '../../../../FSM/shared/constants'
import { TRANSLATIONS as T } from '../../../../constants/translations-constants'
import { TravelNo } from '../../../../types/generated/graphql'
import { useTranslate, Typography, TranslatedTextType } from '@dg-shared'
import BtnEdit from '../BtnEdit'
//TODO: This image is too big! We need to optimize it.
import { ServiceSend } from '../../../../types/interface/fsm.interface'
import useContentStyles from './useContentStyles'

interface ContentTravelProps {
  send: ServiceSend
  quote: TravelNo
}

const ContentTravel: FC<ContentTravelProps> = ({ send, quote }) => {
  const classes = useContentStyles({ isImage: true, brandPageBackgroundCard: 'travelReview' })
  const { t } = useTranslate(`${T.OFFER_REVIEW}.card`)
  const translateProp = (key: string): TranslatedTextType => t(`${T.COMMON}:productProps.${key}`)

  return (
    <CardContent className={classes.twoCol}>
      <section className={classes.colLeft}>
        <Typography type='h2'>{t('heading')}</Typography>
        <Hidden mdUp>
          <Divider className={classes.divider} />
        </Hidden>

        <div className={classes.column}>
          <div className={classes.row}>
            <Typography>{t('coverage')}</Typography>
            <Typography className={classes.rightColItem}>
              {translateProp(quote.coverage)}
            </Typography>
          </div>
          <div className={classes.row}>
            <Typography>{t('coverArea')}</Typography>
            <Typography className={classes.rightColItem}>
              {translateProp(quote.coverArea)}
            </Typography>
          </div>
          <div className={classes.row}>
            <Typography>{t('claimsPrev')}</Typography>
            <Typography className={classes.rightColItem}>
              {translateProp(quote.claimsPrev)}
            </Typography>
          </div>
        </div>
        <BtnEdit text={t('btnChange')} handleClick={() => send(Events.CHANGE)} />
      </section>

      <Hidden smDown>
        <div className={classes.colRight}></div>
      </Hidden>
    </CardContent>
  )
}

export default ContentTravel

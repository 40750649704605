import React, { FC, MutableRefObject } from 'react'
import { QuoteType } from '../../../types/generated/graphql'

interface AvtaleGiroFromProps {
  componentRef: MutableRefObject<HTMLFormElement | null>
  actionUrl: string
  companyName: string
  companyAccountNumber: string
  kid: string
  data: QuoteType
  returnUrl: string
  notificationDisabled: string // 'false' or 'true'
}

const AvtalegiroForm: FC<AvtaleGiroFromProps> = ({
  actionUrl,
  componentRef,
  companyName,
  companyAccountNumber,
  kid,
  returnUrl,
  notificationDisabled,
}) => {
  return (
    <form action={actionUrl} method='post' id='sendData' ref={componentRef}>
      <input type='hidden' name='companyName' id='companyName' value={companyName} />
      <input
        type='hidden'
        name='companyAccountNo'
        id='companyAccountNo'
        value={companyAccountNumber}
      />
      <input type='hidden' name='kid' id='kid' value={kid} />
      <input type='hidden' name='amountLimit' id='amountLimit' value={5000} />
      <input type='hidden' name='returnUrl' id='returnUrl' value={returnUrl} />
      <input
        type='hidden'
        name='notificationDisabled'
        id='notificationDisabled'
        value={notificationDisabled}
      />
    </form>
  )
}

export default AvtalegiroForm

import {
  FLOW_NAME_QUERY_KEY,
  getFlowName,
  getProductName,
  PRODUCT_NAME_QUERY_KEY,
} from '@dg-util/productHandler'
import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import { removePersistedState } from '../FSM/util'
import { httpClientGet as clearCookie } from '../service/request.service'
import { BUILD_CONSTANTS as BC } from '../constants'

export const safeSessionStorageClear = () => {
  const flowName = getFlowName()
  const productName = getProductName()
  sessionStorage.clear()
  sessionStorage.setItem(FLOW_NAME_QUERY_KEY, flowName)
  sessionStorage.setItem(PRODUCT_NAME_QUERY_KEY, productName)
}

export const fullStorageCleanup = async (client: ApolloClient<NormalizedCacheObject>) => {
  await clearCookie(`${BC.REST_URL}/auth-init`, { credentials: 'include' })
  removePersistedState()
  await client.stop()
  await client.resetStore()
  safeSessionStorageClear()
}

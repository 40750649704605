import { gql } from 'graphql-tag'

export const ALL_PERSON_VEHICLES = gql`
  query OffersVehicles {
    offersVehicles {
      licensePlate
      registrationYear
      technical {
        brands
        modelDesignation
        typeDesignation
      }
    }
  }
`

import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { UPDATE_QUOTE_DRAFT_LOCAL } from '../quote/mutation'
import { QuoteType } from 'src/types/generated/graphql'

export const updateQuoteSetterLocal = async (
  client: ApolloClient<NormalizedCacheObject>,
  quote: QuoteType,
  rName = ['updateQuoteDraftLocal']
): Promise<QuoteType> => {
  const response = await client.mutate({
    mutation: UPDATE_QUOTE_DRAFT_LOCAL,
    variables: {
      quote,
    },
  })

  return response.data[rName[0]]
}

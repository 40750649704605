import { isNil } from 'lodash'
import React, { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useApolloClient } from '@apollo/react-hooks'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Link, IconButton } from '@material-ui/core'
import { ChevronLeft } from '@material-ui/icons'

import history from '../../routes/history'
import { SIGN_OUT } from '../../graphql/auth.query'
import { AUTH } from '../../constants'
import { TRANSLATIONS as T } from '../../constants/translations-constants'
import { useTranslate } from './hooks'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customIconPosition: {
      [theme.breakpoints.down('sm')]: {
        marginLeft: '-8px',
      },
    },
  })
)

export interface IBackButtonSharedProps {
  goTo?: string
  buttonText?: string
  customClass?: string
}

const BackButtonShared: FC<IBackButtonSharedProps> = ({ goTo, buttonText, customClass }) => {
  const { t } = useTranslate() //TODO: Change the translation
  const classes = useStyles()
  const client = useApolloClient()
  const btnText = buttonText ? buttonText : t(`${T.COMMON}/back`, {}, 'string')

  const handleRouting = async () => {
    await client.query({ query: SIGN_OUT })
    history.push(AUTH)
  }

  return isNil(goTo) ? (
    <IconButton onClick={handleRouting} aria-label={btnText} size='small' className={customClass}>
      <ChevronLeft fontSize='inherit' className={classes.customIconPosition} />
      <Link variant='body2' color='primary'>
        {btnText}
      </Link>
    </IconButton>
  ) : (
    <IconButton aria-label={btnText} size='small' className={customClass}>
      <ChevronLeft fontSize='inherit' className={classes.customIconPosition} />
      <Link component={RouterLink} variant='body2' color='primary' to={goTo}>
        {btnText}
      </Link>
    </IconButton>
  )
}

export default BackButtonShared

import { gql } from 'graphql-tag'

export const FRAGMENT_TRAVEL_NO_ITEMS = gql`
  fragment TravelNoItems on TravelNo {
    country
    productName

    id

    prevInsuranceCompanyId

    priceAnnual
    priceMonthly
    priceTotal
    priceMonthlyWithTax
    priceAnnualWithTax

    coverage
    coverArea
    claimsPrev
    insuranceMove

    canVerify
    canComparePrice
    displayPrevCompanyField
    encrypted {
      iv
      content
    }
  }
`

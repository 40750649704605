import { StateRoute } from '../../../../../types/interface/fsm.interface'
import { QBReview, QBOwnership } from '../../../../../pages/qb'
import { QBCompareInit } from '../../../../../pages/qb/Insurely'
import { FSMCommonStatePaths } from '../../../../../FSM/shared/routes'
import QBComparePage from '../../../../../pages/qb/Insurely/Compare/QBComparePage'
import QBCompareListPage from '../../../../../pages/qb/Insurely/CompareList/QBCompareListPage'

const FSMVehicleStatePathsSE: Array<StateRoute> = [
  ...FSMCommonStatePaths,
  {
    is: { ownership: 'review' },
    page: QBReview,
  },
  {
    is: { ownership: 'owner' },
    page: QBOwnership,
  },
  {
    is: { insurely: 'init' },
    page: QBCompareInit,
  },
  {
    is: { insurely: 'compare' },
    page: QBComparePage,
  },
  {
    is: { insurely: 'comparisonList' },
    page: QBCompareListPage,
  },
]

export { FSMVehicleStatePathsSE }

import React, { FC } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Hidden } from '@material-ui/core'

import { TRANSLATIONS as T } from '../../../../constants'
import { FormFieldV2 } from '../../../../types/interface/form.interface'
import { Typography, useTranslate } from '@dg-shared'
import DetailedInfoButtonShared from '@dg-shared/DetailedInfoButtonShared'

const useStyles = makeStyles(() =>
  createStyles({
    colRight: {
      marginBottom: '0',
    },
  })
)

interface CoverageLabelProps {
  field: FormFieldV2
  invalid: boolean
  toggler: (state: boolean) => boolean
  value: string
}

const CoverageCustomLabel: FC<CoverageLabelProps> = ({ invalid, toggler }) => {
  const { t } = useTranslate(T.OFFER_CHANGE)
  const { colRight } = useStyles()

  const text = (
    <Typography className={colRight} color={invalid ? 'textError' : 'textSecondary'}>
      {t('form.coverageType.label')}
    </Typography>
  )

  return (
    <>
      <Hidden mdUp>
        <DetailedInfoButtonShared showDetails={() => toggler(true)}>
          {text}
        </DetailedInfoButtonShared>
      </Hidden>
      <Hidden smDown>{text}</Hidden>
    </>
  )
}

export default CoverageCustomLabel

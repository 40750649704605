import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'

import { LMCommonProps } from '../../../types/MLCommonProps'
import { StylesProps } from '../../../types/interface/styles.interface'
import BackButtonShared from '../BackButtonShared'
import { logoMin } from 'BrandedImages'

import backgroundImage from '../../../static/images/error-mobile-image.png'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100vh',
      alignContent: 'start',
    },
    header: (props: StylesProps) => ({
      justifyContent: 'space-around',
      display: 'flex',
      flexDirection: 'column',
      height: '285px',
      padding: '30px 20px',
      background: `center url(${props.backgroundImage}) no-repeat`,
      borderRadius: '0 0 30% 0',
    }),
    content: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vh - 285px)',
      padding: theme.spacing(2),
    },
    backBtn: {
      alignSelf: 'start',
      marginBottom: theme.spacing(4),
    },
  })
)

const LMBgV2: FC<LMCommonProps> = ({
  titleHeader,
  breadcrumbsBtnText,
  breadcrumbsBtnPath,
  children,
}) => {
  const classes = useStyles({ backgroundImage })

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.header}>
        <div>
          <img src={logoMin} alt={titleHeader.toString()} />
        </div>

        {!!titleHeader && (
          <Typography variant='h1' color='primary'>
            {titleHeader}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12} className={classes.content}>
        <BackButtonShared
          customClass={classes.backBtn}
          goTo={breadcrumbsBtnPath}
          buttonText={breadcrumbsBtnText.toString()}
        />
        {children}
      </Grid>
    </Grid>
  )
}

export default LMBgV2

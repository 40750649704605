import React, { FC } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { CTA_CONTEXT } from '../../constants/cta-constants'
import { TRANSLATIONS as T } from '../../constants/translations-constants'
import { NoDataCardProps } from '../../types/interface/claims.interface'

import { Typography, useTranslate, CTA, FSMBtnNext } from './'
import { Events } from '../../FSM/shared/constants'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(5),
    },
    message: {
      textAlign: 'left',
      marginBottom: '0',
    },
    content: {
      paddingTop: theme.spacing(2),
    },
    btnAction: {
      alignSelf: 'center',
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(2),
    },
  })
)

const NoDataCard: FC<NoDataCardProps> = ({ send, type }) => {
  const { t } = useTranslate(T.COMMON)
  const classes = useStyles()

  return (
    <Grid className={classes.wrapper} data-testid={'no-data'}>
      <section style={{ margin: 'auto' }}>
        <Typography className={`${classes.message} ${classes.content}`}>
          {t(`noData.main.0`)}
        </Typography>
        <br />
        <Typography className={classes.message}>{t(`noData.main.1`)}</Typography>
      </section>
      <Grid container direction={'column'}>
        <FSMBtnNext
          customClassName={classes.btnAction}
          handleClick={() => send(Events.NO_DATA)}
          buttonText={t(CTA_CONTEXT[type].btnText)}
        />
        <CTA context={type} />
      </Grid>
    </Grid>
  )
}

export default NoDataCard

import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { GET_QUOTE_SELECTED } from '../quote/query'
import { QuoteType } from '../../types/generated/graphql'

export const selectedQuoteGetterLocal = async (
  client: ApolloClient<NormalizedCacheObject>,
  rName: Array<string> = ['quotes', 'selected']
): Promise<QuoteType> => {
  const response = await client.query({ query: GET_QUOTE_SELECTED })

  return response.data[rName[0]][rName[1]]
}

import React, { FC } from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import { get } from 'lodash'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Card } from '@material-ui/core'

import { TRANSLATIONS as T } from '../../../constants'
import { TravelNo } from '../../../types/generated/graphql'
import { PartnerName, ProductName } from '../../../types/interface/quote.interface'
import { QuoteVehicleType } from '../../../types/Vehicles'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import useLogic from './useLogic'
import {
  CustomCardHeader,
  LDContainerV2FSM,
  LMBasicV3FSM,
  ProductWrapper,
  Typography,
  useTranslate,
} from '@dg-shared'
import Loading from '../Loading/Page'
import CTA from './CTA'
import ContentVehicle from './Content/Vehicle'
import ContentTravel from './Content/Travel'
import PageWrapper from '@dg-shared/PageWrapper'

const useOfferReviewPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    subTitle: {
      marginBottom: theme.spacing(3),
    },
    shortDescription: {
      marginTop: theme.spacing(2),
    },
    disclaimer: {
      marginBottom: theme.spacing(1),
      '& a': {
        textDecoration: 'underline',
        color: 'inherit',
      },
    },
    additionalPadding: {
      marginBottom: theme.spacing(2),
    },
  })
)

const ReviewPage: FC<CommonPageProps> = ({ service }) => {
  const { t } = useTranslate(T.OFFER_REVIEW)
  const classes = useOfferReviewPageStyles()
  const client = useApolloClient()
  const { loading, productType, quote, getBonusText, btnBackText, errorInsurely } = useLogic(
    client,
    service
  )

  if (loading) {
    return <Loading />
  }

  const content = quote && (
    <>
      <Card data-testid='review-content-card' elevation={3}>
        <CustomCardHeader
          priceMonthly={quote.priceMonthly}
          priceDetails={get(quote, 'taxAnnual') || quote.priceAnnual}
        />
        <ProductWrapper products={[ProductName.VEHICLE]} partners={Object.values(PartnerName)}>
          <ContentVehicle
            send={service.send}
            quote={quote as QuoteVehicleType}
            getBonusText={getBonusText}
          />
        </ProductWrapper>
        <ProductWrapper products={[ProductName.TRAVEL]} partners={Object.values(PartnerName)}>
          <ContentTravel send={service.send} quote={quote as TravelNo} />
        </ProductWrapper>
      </Card>
      <ProductWrapper products={[ProductName.TRAVEL]} partners={Object.values(PartnerName)}>
        <section className={classes.shortDescription}>
          <Typography modifiers={['bold']} className={classes.disclaimer}>
            {t('card.disclaimer.0')}
          </Typography>
          <Typography className={classes.additionalPadding}>{t('card.disclaimer.1')}</Typography>
          <Typography className={classes.additionalPadding}>{t('card.disclaimer.2')}</Typography>
          <Typography className={classes.disclaimer}>
            {/* TODO: Move to constants after we merge partner/se into dev */}
            <a
              href='https://instabank.no/forsikring/instabank-reiseforsikring'
              target='_blank'
              rel='noreferrer'
            >
              {t('card.disclaimer.3')}
            </a>
          </Typography>
        </section>
      </ProductWrapper>
      <CTA
        productType={productType}
        send={service.send}
        isVerify={quote.canVerify}
        errorInsurely={(!!errorInsurely && t(`error.${errorInsurely}`)) || null}
        //TODO: Change the type of the quote --> after we will have any possibility to check
        // for different product types. Different properties are needed to do this check
        showCompareBtn={false}
      />
    </>
  )

  return (
    <PageWrapper
      mobile={LMBasicV3FSM}
      desktop={LDContainerV2FSM}
      breadcrumbsBtnText={t(btnBackText)}
      breadcrumbsStepPercentage={50}
    >
      <Typography type='h1' className={classes.title}>
        {t('title')}
      </Typography>
      <Typography className={classes.subTitle}>{t('subtitle')}</Typography>
      {content}
    </PageWrapper>
  )
}
export default ReviewPage

import { CircularProgress } from '@material-ui/core'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import CheckIcon from '@material-ui/icons/Check'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined'
import BeachAccessOutlinedIcon from '@material-ui/icons/BeachAccessOutlined'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'

import {
  DefaultIconComponentsMap,
  CircleKIconComponentsMap,
  DefaultIconPropsMap,
  CircleKIconPropsMap,
  PartnersIcons,
  IconType,
} from '../types/interface/icon.interface'
import CircleKSVGIcon from '../components/shared/CircleKSVGIcon'

const defaultProps = {
  size: 24,
}

const defaultIconComponents: DefaultIconComponentsMap = {
  [IconType.LOADING]: CircularProgress,
  [IconType.ERROR]: CloseRoundedIcon,
  [IconType.CHECK]: CheckIcon,
  [IconType.ARROW_DOWN]: KeyboardArrowDownIcon,
  [IconType.CHEVRON_LEFT]: ChevronLeftIcon,
  [IconType.CALENDAR]: InsertInvitationIcon,
  [IconType.CREATE]: CreateOutlinedIcon,
  [IconType.DOT]: FiberManualRecordIcon,
  [IconType.BEACH_ACCESS]: BeachAccessOutlinedIcon,
  [IconType.FILE_COPY]: FileCopyOutlinedIcon,
  [IconType.ADD]: AddIcon,
  [IconType.REMOVE]: DeleteOutlineIcon,
}

const defaultIconProps: DefaultIconPropsMap = {
  [IconType.LOADING]: {
    thickness: 2.5,
    size: 24,
  },
  [IconType.ERROR]: defaultProps,
  [IconType.CHECK]: defaultProps,
  [IconType.ARROW_DOWN]: defaultProps,
  [IconType.CHEVRON_LEFT]: defaultProps,
  [IconType.CALENDAR]: defaultProps,
  [IconType.CREATE]: defaultProps,
  [IconType.DOT]: defaultProps,
  [IconType.BEACH_ACCESS]: defaultProps,
  [IconType.FILE_COPY]: defaultProps,
}

const circleKIconComponents: CircleKIconComponentsMap = {
  [IconType.LOADING]: CircleKSVGIcon,
  [IconType.ERROR]: CircleKSVGIcon,
  [IconType.CHECK]: CircleKSVGIcon,
  [IconType.ARROW_DOWN]: CircleKSVGIcon,
  [IconType.CHEVRON_LEFT]: ChevronLeftIcon,
  [IconType.CALENDAR]: CircleKSVGIcon,
  [IconType.CREATE]: CircleKSVGIcon,
  [IconType.DOT]: CircleKSVGIcon,
  [IconType.BEACH_ACCESS]: BeachAccessOutlinedIcon,
  [IconType.FILE_COPY]: FileCopyOutlinedIcon,
}

const circleKIconProps: CircleKIconPropsMap = {
  [IconType.LOADING]: {
    type: IconType.LOADING,
  },
  [IconType.ERROR]: {
    type: IconType.ERROR,
    color: '#DA291C',
  },
  [IconType.CHECK]: {
    type: IconType.CHECK,
  },
  [IconType.ARROW_DOWN]: {
    type: IconType.ARROW_DOWN,
  },
  [IconType.CHEVRON_LEFT]: {
    type: IconType.CHEVRON_LEFT,
  },
  [IconType.CALENDAR]: {
    type: IconType.CALENDAR,
  },
  [IconType.CREATE]: {
    type: IconType.CREATE,
  },
  [IconType.DOT]: {
    type: IconType.DOT,
  },
  [IconType.BEACH_ACCESS]: {
    type: IconType.BEACH_ACCESS,
  },
  [IconType.FILE_COPY]: {
    type: IconType.FILE_COPY,
  },
}

const partnersIcons: PartnersIcons = {
  instabank: {
    components: defaultIconComponents,
    props: defaultIconProps,
  },
  circlek: {
    components: circleKIconComponents,
    props: circleKIconProps,
  },
}

export { partnersIcons }

import { gql } from 'graphql-tag'

export const FRAGMENT_QUOTE_ERROR_VALIDATION = gql`
  fragment ServiceErrorValidationItems on ServiceErrorValidation {
    error
    validation
    msg
    code
  }
`

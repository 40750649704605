import { isNil } from 'lodash'
import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { ERROR_CODE_QUOTE_GENERAL } from '../../constants/error-constants'
import { ErrorCode } from '../../types/interface/error.interface'
import { GET_QUOTE_ACCEPTED_LOCAL } from '../quote/query'
import { QuoteAccepted } from '../../types/generated/graphql'

export const getQuoteAcceptedLocal = async (
  client: ApolloClient<NormalizedCacheObject>,
  rName: Array<string> = ['quotes', 'accepted']
): Promise<[QuoteAccepted | null, ErrorCode | null]> => {
  const response = await client.query({
    query: GET_QUOTE_ACCEPTED_LOCAL,
  })

  if (isNil(response)) return [null, ERROR_CODE_QUOTE_GENERAL]
  if (!isNil(response.errors)) return [null, ERROR_CODE_QUOTE_GENERAL]

  return [response.data[rName[0]][rName[1]], null]
}

import { gql } from 'graphql-tag'

export const SIGN_OUT = gql`
  query SignOut {
    signOut {
      ... on SessionDropped {
        sessionDropped
        error
      }
      ... on ErrorResponse {
        msg
        status
      }
    }
  }
`

import { FlowName, PRODUCT_NAME_TO_ID } from '../config'
import { getQueryParamByName } from './getQueryParam'
import { ProductName } from '../types/interface/quote.interface'

// Augmenting the Window Interface for saving global vars
declare global {
  interface Window {
    requestSignOut: boolean
  }
}

export const FLOW_NAME_QUERY_KEY = 'FLOW'
export const PRODUCT_NAME_QUERY_KEY = 'PRODUCT'

export const getPID = (): number => {
  return PRODUCT_NAME_TO_ID[getProductName()]
}

export const getProductName = (): ProductName => {
  return queryParamHandler(PRODUCT_NAME_QUERY_KEY, ProductName.VEHICLE)
}

export const getFlowName = (): FlowName => {
  return queryParamHandler(FLOW_NAME_QUERY_KEY, FlowName.QUOTE_AND_BUY)
}

type QueryParamType = FlowName | ProductName

const queryParamHandler = <T extends QueryParamType>(query: string, defaultValue: T): T => {
  // QueryParam value passed in the browser URL query param
  const queryValue = getQueryParamByName(query) as T | undefined

  // Get cached QueryParam value
  const cachedValue = sessionStorage.getItem(query) as T | undefined

  // Check it is a QueryParam value change
  if (cachedValue && queryValue && cachedValue != queryValue) {
    // QueryParam value change. New QueryParam value came from the url. SignOut should be requested
    // Save this flag in global scope temporarily because we have no state management at this point of code evaluation
    window.requestSignOut = true
  }

  // Set cached QueryParam value if it is passed in query param
  if (queryValue) {
    sessionStorage.setItem(query, queryValue)
  }

  // If there is no queryValue and no cachedValue, set defaultValue in cache
  if (!cachedValue && !queryValue) {
    sessionStorage.setItem(query, String(defaultValue))
  }

  return queryValue || cachedValue || defaultValue
}

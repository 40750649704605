import React, { FC, useEffect } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

import { TRANSLATIONS as T } from '../../../../constants/translations-constants'
import {
  withHidden,
  LMBasicV3FSM,
  useTranslate,
  Typography,
  FSMBtnNext,
  CoverageDetailedInfo,
  LDCardTwoColV2FSM,
} from '@dg-shared'
import { FlowServiceType } from '../../../../types/interface/fsm.interface'
import { useApolloClient } from '@apollo/react-hooks'
import AdvantagesTextList from './AdvantagesTextList'
import { formatWithThousandSpace } from '../../../../service/util.service'
import PriceTwinCards from './PriceTwinCards'
import { Events } from '../../../../FSM/shared/constants'
import { get, has } from 'lodash'
import useLogic from '../useLogic'

const MobileScreen = withHidden(LMBasicV3FSM, true)
const DesktopScreen = withHidden(LDCardTwoColV2FSM, false)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nextButton: {
      margin: '0 auto',
      [theme.breakpoints.up('md')]: {
        maxWidth: '345px',
        marginTop: theme.spacing(5),
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(3),
      },
    },
  })
)

export type PagePropsType = {
  service: FlowServiceType
}

const QBComparePage: FC<PagePropsType> = (props) => {
  const { service } = props
  const { t } = useTranslate(T.OFFER_COMPARE_PRICE)
  const client = useApolloClient()
  const classes = useStyles()
  const STEP_PERCENTAGE = 60
  const { quote, offerDifference, selectedInsurelyQuote, coverageData, setQuoteCompetitorPrice } =
    useLogic(client, service)

  useEffect(() => {
    if (!selectedInsurelyQuote) {
      service.send(Events.INSURELY_NO_OFFERS_FOUND)
    }
    // TODO: FIx with using correct deps in [] and useCallback. Left here to not break anything
    // eslint-disable-next-line
  }, [selectedInsurelyQuote])

  useEffect(() => {
    if (quote && selectedInsurelyQuote) {
      // Set Quote Competitor Price
      setQuoteCompetitorPrice(
        quote.id,
        selectedInsurelyQuote.insurance.premiumAmountYearRounded
      ).catch((error) => {
        throw new Error(error)
      })
    }
  }, [quote, selectedInsurelyQuote, setQuoteCompetitorPrice])

  const colLeftBody =
    quote && selectedInsurelyQuote ? (
      <>
        <AdvantagesTextList annualAmount={formatWithThousandSpace(offerDifference)} />
        <PriceTwinCards send={service.send} quote={quote} offerToCompare={selectedInsurelyQuote} />
        <Typography>{t('additionalInfo')}</Typography>
      </>
    ) : null

  const coverageDetails = has(coverageData, 'type') && (
    <CoverageDetailedInfo
      coverageType={quote.coverageType}
      translationCondition={get(quote, 'superCover', false) ? 'isSuper' : 'isNotSuper'}
      showDialogMobile={false}
    />
  )
  const content = colLeftBody

  const btnNext = colLeftBody ? (
    <FSMBtnNext
      handleClick={() => service.send(Events.NEXT)}
      buttonText={t('submitBtn')}
      customClassName={classes.nextButton}
    />
  ) : null

  const subtitle = <Typography color='textMain'>{t('subtitle')}</Typography>

  return (
    <>
      <MobileScreen
        send={service.send}
        breadcrumbs
        breadcrumbsBtnText={t(`${T.COMMON}/back`)}
        breadcrumbsStepPercentage={STEP_PERCENTAGE}
      >
        <Typography type='h1'>{t(`title`, { coverageName: coverageData?.name })}</Typography>
        {subtitle}
        {content}
        {coverageDetails}
        {btnNext}
      </MobileScreen>
      <DesktopScreen
        send={service.send}
        title={t(`title`, { coverageName: coverageData?.name })}
        subTitle={subtitle}
        breadcrumbs
        breadcrumbsBtnText={t(`${T.COMMON}/back`)}
        breadcrumbsStepPercentage={STEP_PERCENTAGE}
        colLeft={colLeftBody}
        colRight={coverageDetails}
        nextBtn={btnNext}
      />
    </>
  )
}

export default QBComparePage

import React, { FC } from 'react'

import { Typography, FNOLPageWrapper } from '@dg-shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'

const PageReview: FC<CommonPageProps> = ({}) => {
  return (
    <FNOLPageWrapper breadcrumbsBtnText={'back'}>
      <Typography type='h1'>DOC REVIEW</Typography>
    </FNOLPageWrapper>
  )
}

export default PageReview

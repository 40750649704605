import { useEffect, useState } from 'react'
import { ApolloClient } from 'apollo-client'
import { useSelector } from '@xstate/react'
import { get, has, isNil } from 'lodash'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { DATASOURCE_ID as DS_ID } from '../../../constants/datasource-constants'
import { Events } from '../../../FSM/shared/constants'
import { DatasourceValue } from '../../../types/interface/datasource.interface'
import { FlowServiceType } from '../../../types/interface/fsm.interface'
import { MachineConfigType } from '../../../FSM/shared/config'
import { QuoteType } from '../../../types/generated/graphql'
import {
  quoteInitGetter,
  quoteInitGetterLocal,
  quoteInitSetter,
  quoteInitSetterLocal,
} from '../../../graphql/quote'
import {
  insuranceCompaniesListGetter,
  productPropsGetter,
  datasourceGetter,
} from '../../../graphql/datasource'

import { signOutGetter } from '../../../graphql/auth/signOut.getter'
import { getBackButtonText, getPID } from '@dg-util'
import { PartnerName } from '../../../types/interface/quote.interface'

interface HookReview {
  loading: boolean
  productType: number
  quote: QuoteType | null
  getBonusText(value: string): string | null
  btnBackText: string
  errorInsurely: string | null
}

const getErrorKey = (transition: string, errors: Array<string>): string | null => {
  if (errors.indexOf(transition) !== -1) return errors[errors.indexOf(transition)]

  return null
}

const useLogic = (
  client: ApolloClient<NormalizedCacheObject>,
  service: FlowServiceType
): HookReview => {
  const [loading, setLoading] = useState<boolean>(true)
  const [quote, setQuote] = useState<QuoteType | null>(null)
  const [bonusList, setBonusList] = useState<Array<DatasourceValue>>([])
  const transitionType = useSelector(service, (state) => state.event.type)
  const btnBackText = useSelector(service, ({ value }) =>
    getBackButtonText(service.machine.config as MachineConfigType, value)
  )

  const fetchServerData = async () => {
    const quote = await quoteInitGetter(client)
    await productPropsGetter(client)

    if ([PartnerName.digisure, PartnerName.instabank].includes(PARTNER_NAME as PartnerName)) {
      await insuranceCompaniesListGetter(client)
    }

    await quoteInitSetter(client, quote)
    setQuote(quote)
  }

  const fetchLocalData = async () => {
    const quote = await quoteInitGetterLocal(client)

    if (!isNil(quote)) {
      await quoteInitSetterLocal(client, quote)

      setQuote(quote)
      return quote
    }
  }

  const fetchBonusList = async () => {
    const data = await datasourceGetter(client, DS_ID.BONUS)

    return setBonusList(data)
  }

  const getBonusText = (bonusValue: string): string =>
    get(
      bonusList.find((item: DatasourceValue) => item.value === bonusValue),
      'text',
      null
    )

  const fetchData = async () => {
    !(await fetchLocalData()) && (await fetchServerData())
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
    window.scrollTo(0, 0)

    const signOut = async () => {
      await signOutGetter(client)
    }

    return () => {
      const action = service.state.transitions[0]?.actions[0]

      if (get(action, 'type') === 'signOut') {
        signOut()
      }
    }
    // TODO: FIx with using correct deps in [] and useCallback. Left here to not break anything
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    has(quote, 'bonus') && fetchBonusList()
    // TODO: FIx with using correct deps in [] and useCallback. Left here to not break anything
    // eslint-disable-next-line
  }, [quote])

  return {
    loading,
    quote,
    getBonusText,
    productType: getPID(),
    btnBackText: `${T.COMMON}/${btnBackText}`,
    errorInsurely: getErrorKey(transitionType, [
      Events.INSURELY_FAILURE_BANK_ID,
      Events.INSURELY_NO_OFFERS_FOUND,
    ]),
  }
}

export default useLogic

import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { GET_PRODUCT_PROPS } from './query'
import { Datasource } from '../../types/generated/graphql'

export const productPropsGetter = async (
  client: ApolloClient<NormalizedCacheObject>,
  rName = 'getProductProps'
): Promise<Datasource> => {
  const response = await client.query({ query: GET_PRODUCT_PROPS })

  return response.data[rName]
}

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { StylesProps } from '../types/interface/styles.interface'

export const useDesktopLayoutCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '780px',
    },
    cardLeftColumn: (props?: StylesProps) => ({
      display: 'flex',
      width: '390px',
      background: `
      linear-gradient(${
        !!props && props.leftColGradientReverse ? 'to top' : 'to bottom'
      }, rgba(255, 255, 255, 1) 25%, rgba(255, 255, 255, 0)),
      url(${props ? props.backgroundImage : ''}) no-repeat left top scroll
      `,
      backgroundSize: 'cover',
      justifyContent: 'center',
    }),
    gridContainerMain: (props?: StylesProps) => ({
      alignContent: 'start',
      height: '100vh',
      '@media (min-width: 376px)': {
        background: `
          linear-gradient(to top, rgba(255, 255, 255, 1) 5%, rgba(255, 255, 255, 0)),
          url(${props ? props.fullBackgroundImage : ''}) no-repeat center center fixed
        `,
        backgroundSize: 'cover',
        justifyContent: 'center',
        overflowY: 'auto',
      },
    }),
    cardRightColumn: {
      display: 'flex',
      flexDirection: 'column',
      width: '390px',
      padding: '30px',
    },
    typographyPageTitle: {
      fontSize: '1.3rem',
      fontWeight: 700,
      [theme.breakpoints.up('sm')]: {
        textAlign: 'left',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
      },
    },
    typographyActionLinkAbbreviation: {
      fontSize: '0.9rem',
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(8),
      },
    },
  })
)

import React, { FC } from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { has } from 'lodash'

import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { AUTH_ERROR_TRANSLATION_KEYS } from '../../../constants'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { ProductName } from '../../../types/interface/quote.interface'
import { IS_LOCAL } from '../../../config'
import { FSMMatch, FSMSwitch } from '../../../FSM'
import { MATCH_COMPOUND } from '../../../FSM/shared/constants'
import { getFlowName, getProductName } from '@dg-util'
import { LDCardTwoCol, LMBgHeader, Typography, useTranslate, withHidden } from '@dg-shared'
import useLogic from './useLogic'
import AuthDecision from './AuthDecision'
import AuthRegular from './AuthRegular'
import Loading from '../Loading/Page'
import { logoMax, logoMin } from 'BrandedImages'
import BrandConfig from 'BrandConfig'
import StandardsButton from '@dg-shared/StandardsButton'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      lineHeight: '1.25rem',
    },
    //TODO: Remove after implementing proper PRODUCT_ID config
    btnToggle: {
      position: 'absolute',
      top: '20px',
      left: '20px',
      color: theme.palette.secondary.contrastText,
    },
    textFooter: {
      textAlign: 'center',
      padding: 0,
      marginTop: theme.spacing(1),
      marginBottom: 0,
    },
    error: {
      marginBottom: theme.spacing(1),
    },
    disclaimer: {
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
      '& a': {
        textDecoration: 'underline',
        color: 'inherit',
      },
    },
  })
)

const MobileScreen = withHidden(LMBgHeader, true)
const DesktopScreen = withHidden(LDCardTwoCol, false)

window.grecaptcha = (window as Window).grecaptcha || {}

const AuthStrategy: FC<CommonPageProps> = ({ service }) => {
  const { t } = useTranslate(T.AUTH)
  const classes = useStyles()
  const client = useApolloClient()
  const { loading, errorType, errorVipps, vippsLink, authHandler } = useLogic(client, service)

  const toggleProduct = () => {
    const productName = getProductName()

    const switchProduct = () => {
      const newProductName =
        productName === ProductName.VEHICLE ? ProductName.TRAVEL : ProductName.VEHICLE
      window.location.search = `?flow=${getFlowName()}&product=${newProductName}`
    }

    if (IS_LOCAL) {
      return (
        <Button className={classes.btnToggle} onClick={switchProduct}>
          {`[${productName}] toggle`}
        </Button>
      )
    }
  }

  const errorMessage = has(AUTH_ERROR_TRANSLATION_KEYS, errorType) && (
    <Typography color='textError' size={'small'} modifiers={['bold']} className={classes.error}>
      {t(`${T.ERROR}:${AUTH_ERROR_TRANSLATION_KEYS[errorType]}`)}
    </Typography>
  )

  const disclaimer = (
    <FSMSwitch>
      <FSMMatch is={MATCH_COMPOUND.AUTH_CHOICE}>
        <Typography color='textDisclaimer' size='small' className={classes.disclaimer}>
          {`${t('disclaimer.0')} `}
          <a href={BrandConfig.PRIVACY_POLICY_LINK} target='_blank' rel='noreferrer'>
            {t('disclaimer.1')}
          </a>
        </Typography>
        {errorMessage}
      </FSMMatch>
      <FSMMatch is={MATCH_COMPOUND.AUTH_REGULAR}>
        {errorMessage}
        <Typography size='small' color='textMain' className={classes.disclaimer}>
          {t('description')}
        </Typography>
      </FSMMatch>
    </FSMSwitch>
  )

  const routes = (
    <FSMSwitch>
      <FSMMatch is={MATCH_COMPOUND.AUTH_CHOICE}>
        <AuthDecision
          textBtnAuthRegular={t(`decision.textBtn`)}
          linkVipps={vippsLink}
          errorVipps={errorVipps}
        />
      </FSMMatch>
      <FSMMatch is={MATCH_COMPOUND.AUTH_REGULAR}>
        <AuthRegular handleAuth={authHandler} />
      </FSMMatch>
    </FSMSwitch>
  )

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <MobileScreen
        headerTitle={t('title')}
        headerSubTitle={t('subtitle')}
        textLogoAlt={t('title')}
        imageLogo={logoMin}
        brandPageBackground='mainMobile'
      >
        {disclaimer}
        {routes}
      </MobileScreen>

      <DesktopScreen
        imageLogo={logoMax}
        textLogoAlt={t('title')}
        brandPageBackground='main'
        brandPageBackgroundCard='mainCard'
      >
        {toggleProduct()}
        <StandardsButton />
        <section>
          <Typography type='h1' className={classes.title}>
            {t('title')}
          </Typography>
          <Typography type='h3'>{t('subtitle')}</Typography>
          {disclaimer}
        </section>
        {routes}
      </DesktopScreen>
    </>
  )
}

export default AuthStrategy

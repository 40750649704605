import React, { FC } from 'react'
import { get, mapValues } from 'lodash'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Hidden, Dialog, DialogContent } from '@material-ui/core'

import { IconType } from '../../types/interface/icon.interface'
import { TRANSLATIONS as T, VEHICLE_COVERAGE_TYPE_TRANSLATION_KEYS } from '../../constants'

import CoverageInfoList, { LocalizedListItemType } from './CoverageInfoList'
import { filterTranslationByCondition } from '../../service/util.service'
import { Typography, BrandIconShared, useTranslate } from '@dg-shared'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      color: theme.palette.primary.main,
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(1),
      },
    },
    title: {
      marginBottom: theme.spacing(1),
    },
    coverText: {
      marginBottom: theme.spacing(1),
    },
    closeBtn: {
      padding: 0,
      height: '2rem',
      color: theme.palette.primary.main,
    },
    dialogContentStyle: {
      padding: 16,
    },
    closeIcon: {
      color: theme.brand.colors.secondary,
      position: 'absolute',
      top: 10,
      right: 10,
      cursor: 'pointer',
      fontWeight: 100,
    },
  })
)

interface CoverageDetailedInfoProps {
  coverageType: string
  onClose?: () => void
  showDialogMobile?: boolean
  translationCondition?: string
}

const CoverageDetailedInfo: FC<CoverageDetailedInfoProps> = ({
  coverageType,
  translationCondition,
  showDialogMobile = true,
  onClose,
}) => {
  const classes = useStyles()
  const { t } = useTranslate()

  const coverageTranslationKeys = get(
    VEHICLE_COVERAGE_TYPE_TRANSLATION_KEYS,
    [PARTNER_NAME, coverageType],
    null
  )

  const {
    name,
    description,
    list,
    additionalInfo = null,
  } = mapValues(coverageTranslationKeys, (el) => t(`${T.COMMON}:${el}`))

  const infoList = (
    <CoverageInfoList
      list={filterTranslationByCondition(
        list as Array<LocalizedListItemType>,
        translationCondition
      )}
    />
  )

  return (
    <>
      <Hidden smDown={showDialogMobile}>
        <section className={classes.container}>
          <section className={classes.header}>
            {name && (
              <Typography type='h2' className={classes.title}>
                {name}
              </Typography>
            )}
          </section>
          <Typography className={classes.coverText} color='textMain'>
            {description}
          </Typography>
          {infoList}
          <Typography>{additionalInfo}</Typography>
        </section>
      </Hidden>

      <Hidden mdUp smDown={!showDialogMobile}>
        <Dialog onClose={() => onClose()} open={true} maxWidth='xs'>
          <DialogContent className={classes.dialogContentStyle}>
            <BrandIconShared
              type={IconType.ERROR}
              customClass={classes.closeIcon}
              onClick={() => {
                onClose()
              }}
              action
            />
            <Typography type='h2'>{name}</Typography>
            <Typography>{description}</Typography>
            {infoList}
            <Typography>{additionalInfo}</Typography>
          </DialogContent>
        </Dialog>
      </Hidden>
    </>
  )
}

export default CoverageDetailedInfo

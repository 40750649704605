import 'date-fns'
import React, { FC } from 'react'
import { FieldRenderProps } from 'react-final-form'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { FormControl, FormHelperText } from '@material-ui/core'
import { TimePickerProps } from '@material-ui/pickers/TimePicker/TimePicker'

const useRFFCustomTimePickerStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageInput: {
      display: 'flex',
    },
    formControl: {
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        marginBottom: theme.spacing(3),
      },
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        marginBottom: theme.spacing(3),
      },
    },
    timePickerFieldLabel: {
      color: theme.palette.primary.dark,
    },
  })
)

const RFFCustomTimePicker: FC<FieldRenderProps<TimePickerProps>> = ({ input, meta, ...props }) => {
  const classes = useRFFCustomTimePickerStyles()

  const isError: boolean = (meta.error || meta.submitError) && !meta.pristine

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <FormControl error={isError} className={classes.formControl}>
        <TimePicker
          disableToolbar
          variant='inline'
          inputVariant={props.fieldInputVariant || 'standard'}
          format='HH:mm'
          ampm={false}
          value={input.value || null}
          onChange={input.onChange}
          className={props.customClass || null}
          autoOk={true}
          placeholder={props.fieldPlaceholder}
          label={props.fieldLabel}
          InputLabelProps={{
            shrink: true,
            className: classes.timePickerFieldLabel,
          }}
        />
        <FormHelperText>{meta.error}</FormHelperText>
      </FormControl>
    </MuiPickersUtilsProvider>
  )
}

export default RFFCustomTimePicker

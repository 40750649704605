const FSMStorageKey = 'app-state'

enum FSMCommonTransitions {
  AUTH = 'auth',
  AUTH_CHOICE = 'choice',
  AUTH_REGULAR = 'regular',
  REVIEW = 'review',
  CHANGE = 'change',
  VERIFY = 'verify',
  PURCHASED = 'purchased',
  PURCHASED_DONE = 'done',
  PURCHASED_FAILURE = 'failure',
  ERROR = 'error',
  STANDARDS = 'standards',
  HISTORY = 'history',

  // FNOL
  AUTH_BANK_ID = 'bankId',
  AUTH_BANK_ID_START = 'start',
  AUTH_BANK_ID_SWITCHER = 'switcher',
  FNOL_MY_PAGE = 'myPage',
  FNOL_DOCUMENTS = 'documents',
  FNOL_DOCUMENTS_LIST = 'documentsList',
  FNOL_DOCUMENT_REVIEW = 'documentReview',
  FNOL_POLICIES_LIST = 'policiesList',
  FNOL_CLAIMS = 'claims',
  FNOL_CLAIMS_LIST = 'claimsList',
  FNOL_WINDSHIELD_FLOW = 'windshield',
  FNOL_WINDSHIELD_START = 'windshieldStart',
  FNOL_OTHER_FLOW = 'other',
  FNOL_OTHER_START = 'otherStart',
  FNOL_OTHER_DAMAGE_LOCATION_AND_TIME = 'otherDamageLocationAndTime',
  FNOL_OTHER_ADDITIONAL_INFORMATION = 'otherAdditionalInformation',
  FNOL_OTHER_TIMELINE = 'otherTimeline',
  FNOL_OTHER_LEGAL_DATE = 'otherLegalDate',
  FNOL_OTHER_LEGAL_AMOUNT = 'otherLegalAmount',
  FNOL_OTHER_FIRE_CAUSE = 'otherFireCause',
  FNOL_OTHER_VANDALISM = 'otherVandalism',
  FNOL_OTHER_MACHINE_DAMAGE = 'otherMachineDamage',
  FNOL_OTHER_ERROR_FILLING = 'otherErrorFilling',
  FNOL_OTHER_LOST_KEY = 'otherLostKey',
  FNOL_OTHER_LEGAL_AID = 'otherLegalAid',
  FNOL_OTHER_FIRE = 'otherFire',
  FNOL_COLLISION_FLOW = 'collision',
  FNOL_COLLISION_WHAT_HAPPENED = 'collisionWhatHappened',
  FNOL_COLLISION_HOW_MANY = 'collisionHowMany',
  FNOL_COLLISION_ANIMAL = 'collisionAnimal',
  FNOL_COLLISION_OBJECT = 'collisionObject',
  FNOL_COLLISION_RESPONSIBLE = 'collisionResponsible',
  FNOL_COLLISION_INJURED = 'collisionInjured',
  FNOL_COLLISION_CASE_OF_ACCIDENT = 'collisionCaseOfAccident',
  FNOL_COLLISION_REGISTRATION_NUM = 'collisionRegistrationNumber',
  FNOL_COLLISION_WHEN_WHERE = 'collisionWhenWhere',
  FNOL_COLLISION_ACCIDENT_FORM = 'collisionAccidentForm',
  FNOL_COLLISION_DAMAGE_REPORT = 'collisionDamageReport',
  FNOL_COLLISION_MORE_DETAILS = 'collisionMoreDetails',
  FNOL_THEFT_FLOW = 'theft',
  FNOL_THEFT_MORE_INFO = 'theftMoreInfo',
  FNOL_THEFT_STOLEN_IN_CAR = 'theftStolenInCar',
  FNOL_THEFT_STOLEN_FROM_CAR = 'theftStolenFromCar',
  FNOL_THEFT_STOLEN_AGE_VALUE = 'theftStolenAgeValue',
  FNOL_THEFT_STOLEN_WHEN_WHERE = 'theftStolenWhenWhere',
  FNOL_THEFT_POLICE_CASE = 'theftPoliceCase',
  FNOL_ADDITIONAL_INFORMATION = 'additionalInfo',
  FNOL_TIME_LINE = 'timeLine',
  FNOL_ROADSIDE_ASSISTANCE_FLOW = 'roadsideAssistance',
  FNOL_ROADSIDE_ASSISTANCE_START = 'roadsideAssistanceStart',
}

enum Events {
  NEXT = 'NEXT',
  BACK = 'BACK',
  EMPTY = 'EMPTY',
  ERROR = 'ERROR',
  FIND_CUSTOM = 'FIND_CUSTOM',
  DONE = 'DONE',
  PAYMENT_FAILURE = 'PAYMENT_FAILURE',
  PAYMENT_SUCCESS = 'PAYMENT_SUCCESS',
  PAYMENT_CANCEL = 'PAYMENT_CANCEL',
  CUSTOM_AUTH_START = 'CUSTOM_AUTH_START',
  CUSTOM_AUTH_SWITCHER = 'CUSTOM_AUTH_SWITCHER',
  CUSTOM_AUTH_SUCCESS = 'CUSTOM_AUTH_SUCCESS',
  CUSTOM_AUTH_FAILURE = 'CUSTOM_AUTH_FAILURE',
  CHANGE = 'CHANGE',
  WRONG_PRODUCT_ID = 'WRONG_PRODUCT_ID',
  START_COMPARE = 'START_COMPARE',
  COMPARE = 'COMPARE',
  COMPARE_LIST = 'COMPARE_LIST',
  AUTH_ERROR = 'AUTH_ERROR',
  INSURELY_FAILURE_BANK_ID = 'failedBankId',
  INSURELY_NO_OFFERS_FOUND = 'failedCalculation',
  STANDARDS = 'STANDARDS',
  SESSION_EXPIRED = 'SESSION_EXPIRED',

  // FNOL
  NO_DATA = 'NO_DATA',
  FNOL_DOCUMENTS_LIST = 'FNOL_DOCUMENTS_LIST',
  FNOL_CLAIMS = 'FNOL_CLAIMS',
  FNOL_WINDSHIELD = 'FNOL_WINDSHIELD',
  FNOL_COLLISION = 'FNOL_COLLISION',
  FNOL_COLLISION_HOW_MANY = 'FNOL_COLLISION_HOW_MANY',
  FNOL_COLLISION_ANIMAL = 'FNOL_COLLISION_ANIMAL',
  FNOL_COLLISION_OBJECT = 'FNOL_COLLISION_OBJECT',
  FNOL_COLLISION_RESPONSIBLE = 'FNOL_COLLISION_RESPONSIBLE',
  FNOL_COLLISION_INJURED = 'FNOL_COLLISION_INJURED',
  FNOL_COLLISION_CASE_OF_ACCIDENT = 'FNOL_COLLISION_CASE_OF_ACCIDENT',
  FNOL_COLLISION_REGISTRATION_NUM = 'FNOL_COLLISION_REGISTRATION_NUM',
  FNOL_COLLISION_WHEN_WHERE = 'FNOL_COLLISION_WHEN_WHERE',
  FNOL_COLLISION_MORE_DETAILS = 'FNOL_COLLISION_MORE_DETAILS',
  FNOL_THEFT = 'FNOL_THEFT',
  FNOL_ROADSIDE_ASSISTANCE = 'FNOL_ROADSIDE_ASSISTANCE',
  FNOL_OTHER = 'FNOL_OTHER',
  FNOL_OTHER_DAMAGE_LOCATION_AND_TIME = 'FNOL_OTHER_DAMAGE_LOCATION_AND_TIME',
  FNOL_OTHER_ADDITIONAL_INFORMATION = 'FNOL_OTHER_ADDITIONAL_INFORMATION',
  FNOL_OTHER_TIMELINE = 'FNOL_OTHER_TIMELINE',
  FNOL_OTHER_LEGAL_DATE = 'FNOL_OTHER_LEGAL_DATE',
  FNOL_OTHER_LEGAL_AMOUNT = 'FNOL_OTHER_LEGAL_AMOUNT',
  FNOL_OTHER_FIRE_CAUSE = 'FNOL_OTHER_FIRE_CAUSE',
  FNOL_THEFT_STOLEN_IN_CAR = 'FNOL_THEFT_STOLEN_IN_CAR',
  FNOL_THEFT_STOLEN_FROM_CAR = 'FNOL_THEFT_STOLEN_FROM_CAR',
}

export enum FNOLClaimTypes {
  FNOL_WINDSHIELD = 'FNOL_WINDSHIELD',
  FNOL_COLLISION = 'FNOL_COLLISION',
  FNOL_THEFT = 'FNOL_THEFT',
  FNOL_ROADSIDE_ASSISTANCE = 'FNOL_ROADSIDE_ASSISTANCE',
  FNOL_OTHER = 'FNOL_OTHER',
}

const MATCH_COMPOUND = {
  AUTH_CHOICE: { auth: 'choice' },
  AUTH_REGULAR: { auth: 'regular' },
  PURCHASED_MILEAGE: { purchased: 'mileage' },
  PURCHASED_DONE: { purchased: 'done' },
  PURCHASED_FAIL: { purchased: 'failure' },
}

export { FSMCommonTransitions, Events, MATCH_COMPOUND, FSMStorageKey }

import React, { FC } from 'react'
import { Trans } from 'react-i18next'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { getStepPercentage } from '@dg-util'
import useLogic from './useLogic'
import { LColThreeBasic, useTranslate } from '@dg-shared'
import OfferSearchLink from './OfferSearchLink'
import OfferItem from './OfferItem'
import Loading from '../Loading/Page'
import { Vehicle } from '../../../types/generated/graphql'

const useOffersStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      justifyContent: 'center',
      '&:not(last-child)': {
        marginBottom: theme.spacing(3),
      },
      [theme.breakpoints.up('md')]: {
        width: '100%',
        display: 'flex',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        '&:not(last-child)': {
          marginBottom: theme.spacing(4),
        },
      },
    },
  })
)

const Offers: FC<CommonPageProps> = ({ service, currentState }) => {
  const { t } = useTranslate(T.OFFERS)
  const classes = useOffersStyles()
  const stepPercentage = getStepPercentage(currentState)
  const { loading, offerList, handleClick } = useLogic(service.send)

  if (loading) {
    return <Loading />
  }

  const content = (
    <>
      {offerList &&
        offerList.map((car: Vehicle, idx: number) => (
          <Grid key={idx} item xs={12} md={4} className={classes.card}>
            <OfferItem
              vehicle={car}
              translations={{
                regNum: t(`${T.COMMON}:registrationNum`),
              }}
              handleClick={() => handleClick(car.licensePlate)}
            />
          </Grid>
        ))}
      <Grid item xs={12} md={4} className={classes.card}>
        <OfferSearchLink
          translations={{
            CTAQuestion: t('CTA.question'),
            CTALink: t('CTA.link'),
          }}
          handleClick={() => handleClick()}
        />
      </Grid>
    </>
  )

  return (
    <LColThreeBasic
      send={service.send}
      titleHeader={t('title')}
      breadcrumbs
      breadcrumbsBtnText={t(`${T.COMMON}/logout`)}
      breadcrumbsStepPercentage={stepPercentage}
      alignContent={offerList.length < 3}
      transProps={
        <Trans i18nKey='subtitle' count={offerList.length}>
          Vi fant <strong>{{ count: offerList.length }}</strong> biler registrert i ditt navn.
        </Trans>
      }
    >
      {content}
    </LColThreeBasic>
  )
}

export default Offers

import BrandConfig from 'BrandConfig'
import CONFIG, { IS_PRODUCTION } from '../config'

const isSecure = window.location.protocol === 'https:'

const initProtocolConfig = (isSec: boolean) =>
  isSec
    ? {
        protocol: 'https',
        port: 443,
      }
    : {
        protocol: 'http',
        port: 5000,
      }
const createConnectionConfig = () => {
  const protocolConfig = initProtocolConfig(isSecure)

  return {
    GRAPHQL_URL: `${protocolConfig.protocol}://${process.env.API_DOMAIN}:${protocolConfig.port}/api/partner/graphql`,
    REST_URL: `${protocolConfig.protocol}://${process.env.API_DOMAIN}:${protocolConfig.port}/api`,
    AVTALEGIRO_URL: IS_PRODUCTION
      ? `${CONFIG.SERVICE.avatalegiro.instabank.PRODUCTION.URL}`
      : `${CONFIG.SERVICE.avatalegiro.instabank.TEST.URL}`,
    ACCOUNT_NUMBER: CONFIG.SERVICE.avatalegiro.instabank.NUMBER,
    PARTNER_NAME: BrandConfig.PARTNER_NAME,
  }
}

/* eslint-disable-next-line no-unused-vars */
const DEV_CONSTANTS = createConnectionConfig()

console.log('conf', DEV_CONSTANTS)

export const BUILD_CONSTANTS = Object.assign({}, DEV_CONSTANTS)

import { Events } from '../../../../../../FSM/shared/constants'
import { FSMTransitionsFNOLVehicleSE } from '../constants'

const {
  FLOW_MACHINE_ID,
  AUTH_BANK_ID,
  FNOL_MY_PAGE,
  FNOL_DOCUMENTS,
  FNOL_DOCUMENTS_LIST,
  FNOL_DOCUMENT_REVIEW,
} = FSMTransitionsFNOLVehicleSE

export default {
  [FNOL_DOCUMENTS]: {
    initial: FNOL_DOCUMENTS_LIST,
    states: {
      [FNOL_DOCUMENTS_LIST]: {
        on: {
          [Events.BACK]: { target: `#${FLOW_MACHINE_ID}.${FNOL_MY_PAGE}` },
          [Events.NEXT]: { target: FNOL_DOCUMENT_REVIEW },
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_DOCUMENT_REVIEW]: {
        on: {
          [Events.BACK]: { target: FNOL_DOCUMENTS_LIST },
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
    },
  },
}

import React, { ReactNode } from 'react'

import { getProductName } from '@dg-util'
import { PartnerName, ProductName } from '../../types/interface/quote.interface'

type PidWrapperPropsType = {
  children: ReactNode
  products: Array<ProductName>
  partners: Array<PartnerName>
}

const ProductWrapper: React.FC<PidWrapperPropsType> = ({ children, products, partners }) => {
  const productName = getProductName()
  const correctProduct = products.includes(productName)
  const correctPartner = partners.includes(PARTNER_NAME as PartnerName)

  return correctPartner && correctProduct ? <>{children}</> : null
}

export default ProductWrapper

import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { LinearProgress } from '@material-ui/core'

import { Events } from '../../FSM/shared/constants'
import { ServiceSend } from '../../types/interface/fsm.interface'
import Typography from './typography/Typography'
import FSMBtnBack from './FSMBtnBack'
import { TranslatedTextType } from '@dg-shared/hooks/useTranslate'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      height: '50px',
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
      },
    },
    gridItemLeft: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        marginLeft: `-${theme.spacing(1)}px`,
      },
    },
    gridItemRight: {
      width: theme.spacing(15),
    },
    gridItemPositionProgressbarWrapper: {
      paddingTop: '0.5rem',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '0.7rem',
      },
    },
    progressValue: {
      lineHeight: 1,
      marginBottom: theme.spacing(0.5),
    },
    barColorPrimary: {
      backgroundColor: theme.palette.info.main,
      borderRadius: theme.spacing(11),
      '& > *': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  })
)

export interface FSMBreadcrumbsProps {
  send?: ServiceSend
  buttonText?: TranslatedTextType
  stepsPercentage?: number | null
}

const FSMBreadcrumbs: FC<FSMBreadcrumbsProps> = ({ send, buttonText, stepsPercentage = null }) => {
  const classes = useStyles()
  const progressBlock = stepsPercentage && (
    <div className={classes.gridItemRight}>
      <div className={classes.gridItemPositionProgressbarWrapper}>
        <Typography className={classes.progressValue}>{`${stepsPercentage}%`}</Typography>
      </div>
      <LinearProgress
        variant='determinate'
        value={stepsPercentage}
        className={classes.barColorPrimary}
      />
    </div>
  )

  return (
    <div className={classes.container}>
      <div className={classes.gridItemLeft}>
        {buttonText ? (
          <FSMBtnBack buttonText={buttonText} handleBack={() => send(Events.BACK)} />
        ) : (
          <FSMBtnBack handleBack={() => send(Events.BACK)} />
        )}
      </div>
      {progressBlock}
    </div>
  )
}

export default FSMBreadcrumbs

import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { SET_OWNERSHIP } from './mutation'

export const setOwnershipMutator = async (
  client: ApolloClient<NormalizedCacheObject>,
  id: number,
  newOwnerSSN: string | null,
  newOwnerName: string | null,
  rName = 'setOwnership'
): Promise<string> => {
  const response = await client.mutate({
    mutation: SET_OWNERSHIP,
    variables: {
      newOwnerSSN,
      newOwnerName,
      id,
    },
    errorPolicy: 'all',
  })

  return response.data[rName]
}

import { get } from 'lodash'
import React, { FC, useState } from 'react'
import { CardContent, CardMedia, Hidden } from '@material-ui/core'

import {
  VEHICLE_COVERAGE_TYPE_MAP,
  VEHICLE_COVERAGE_TYPE_TRANSLATION_KEYS,
} from '../../../../constants'
import { TRANSLATIONS as T } from '../../../../constants/translations-constants'
import {
  CoverageTypeKeyType,
  PartnerName,
  ProductName,
} from '../../../../types/interface/quote.interface'
import { Events } from '../../../../FSM/shared/constants'
import { VehicleNo, VehicleSe } from '../../../../types/generated/graphql'
import { formatWithThousandSpace } from '../../../../service/util.service'
import { CoverageDetailedInfo, ProductWrapper, Typography, useTranslate } from '@dg-shared'
import BtnEdit from '../BtnEdit'
import useContentStyles from './useContentStyles'
import { exampleCarImg } from 'BrandedImages'
import InfoIcon from '@dg-shared/InfoIcon/InfoIcon'
import DetailedInfoButtonShared from '@dg-shared/DetailedInfoButtonShared'
import { ServiceSend } from '../../../../types/interface/fsm.interface'

interface ContentVehicle {
  send: ServiceSend
  quote: VehicleNo & VehicleSe
  getBonusText(value: string): string
}

const getCoverageText = (coverageName: string) => {
  return get(VEHICLE_COVERAGE_TYPE_TRANSLATION_KEYS, [PARTNER_NAME, coverageName], null)
}

const ContentVehicle: FC<ContentVehicle> = ({ send, quote, getBonusText }) => {
  const classes = useContentStyles({ isImage: true })
  const { t } = useTranslate(T.OFFER_REVIEW)
  const [coverageInfoOpened, setCoverageInfoState] = useState<boolean>(false)

  const coverageName = get(VEHICLE_COVERAGE_TYPE_MAP, [PARTNER_NAME, quote.coverageType], null)

  const coverage = (
    <>
      <Hidden mdUp>
        <DetailedInfoButtonShared showDetails={() => setCoverageInfoState(true)} color='main'>
          <Typography className={classes.rightColItem}>{coverageName}</Typography>
        </DetailedInfoButtonShared>
      </Hidden>
      <Hidden smDown>
        <Typography className={classes.rightColItem}>{coverageName}</Typography>
      </Hidden>
    </>
  )

  const coverageInfo = (
    <CoverageDetailedInfo
      coverageType={quote.coverageType}
      onClose={() => setCoverageInfoState(false)}
      translationCondition={quote.superCover ? 'isSuper' : 'isNotSuper'}
    />
  )

  return (
    <CardContent className={classes.twoCol}>
      <section className={classes.colLeft} data-testid={'quote-info-block'}>
        {/* //NOTE: Need block container for Safari when use forms */}
        <section>
          <CardMedia
            component='img'
            alt='Example Car'
            image={exampleCarImg}
            className={classes.itemImage}
          />
        </section>
        <Typography type='h2' className={classes.cardTitle} testId={'vehicle-info'}>
          {[quote.carYear, quote.carBrand, quote.carModel].filter((item) => item).join(' ')}
        </Typography>
        <Typography type='h3' className={classes.cardSubTitle} testId={'regNum-info'}>
          {t(`${T.COMMON}:registrationNum`, { regNum: quote.productId })}
        </Typography>
        <>
          <div className={classes.column} data-testid={'coverage-data'}>
            <div className={classes.row}>
              <Typography>{t(`${T.COMMON}:coverage`)}</Typography>
              {coverage}
              {coverageInfoOpened && coverageInfo}
            </div>
            <ProductWrapper partners={[PartnerName.circlek]} products={Object.values(ProductName)}>
              {[CoverageTypeKeyType.halv, CoverageTypeKeyType.hel].includes(
                quote.coverageType as CoverageTypeKeyType
              ) && (
                <div className={classes.row}>
                  <Typography>{t(`${T.COMMON}:coverageDetails.super.title`)}</Typography>
                  <Typography className={classes.rightColItem} color='textError'>
                    <InfoIcon
                      coverageType={getCoverageText(CoverageTypeKeyType.super)}
                      color='main'
                      label={t(quote.superCover ? `${T.COMMON}/yes` : `${T.COMMON}/no`)}
                    />
                  </Typography>
                </div>
              )}
            </ProductWrapper>
            {!!quote.deductible && (
              <div className={classes.row}>
                <Typography>{t(`${T.COMMON}:deductible.title`)}</Typography>
                <Typography className={classes.rightColItem}>
                  {t(`${T.COMMON}:deductible.value`, {
                    deductible: formatWithThousandSpace(quote.deductible),
                  })}
                </Typography>
              </div>
            )}
            <ProductWrapper
              partners={[PartnerName.instabank]}
              products={Object.values(ProductName)}
            >
              <div className={classes.row}>
                <Typography>{t(`${T.COMMON}:bonus`)}</Typography>
                <Typography className={classes.rightColItem}>
                  {getBonusText(quote.bonus)}
                </Typography>
              </div>
            </ProductWrapper>
            <div className={classes.row}>
              <Typography>{t(`${T.COMMON}:yearlyDrivingDistance.title`)}</Typography>
              <Typography className={classes.rightColItem}>
                {t(`${T.COMMON}:yearlyDrivingDistance.value`, {
                  annualMileage: formatWithThousandSpace(quote.mileageAnnual),
                })}
              </Typography>
            </div>
            <div className={classes.row}>
              <Typography>{t(`${T.COMMON}:prevDriversAge`)}</Typography>
              <Typography className={classes.rightColItem}>
                {quote.ageLimit ? t(`${T.COMMON}/yes`) : t(`${T.COMMON}/no`)}
              </Typography>
            </div>
          </div>
          <BtnEdit
            handleClick={() => send(Events.CHANGE)}
            text={t(`${T.COMMON}:button.changeOffer`)}
          />
        </>
      </section>

      <Hidden smDown>
        <div className={classes.colRight}>{coverageInfo}</div>
      </Hidden>
    </CardContent>
  )
}

export default ContentVehicle

import {
  CoverageTypesMapping,
  CoverageTypeKeyType,
  PartnerName,
} from '../types/interface/quote.interface'
import { ENV, EnvType } from '../config'

export const DEFAULT_PRODUCT_ID = 100

type CoverageMapping = {
  [key: string]: {
    [key: string]: string
  }
}

export const VEHICLE_COVERAGE_TYPE_MAP: CoverageMapping = {
  circlek: {
    [CoverageTypeKeyType.trafik]: 'Trafik',
    [CoverageTypeKeyType.halv]: 'Halvförsäkring',
    [CoverageTypeKeyType.hel]: 'Helförsäkring',
    [CoverageTypeKeyType.super]: 'Avställd',
  },
  instabank: {
    '1': 'Ansvar',
    '2': 'Delkasko',
    '3': 'Kasko',
    '4': 'Super',
  },
}

export const VEHICLE_COVERAGE_TYPE_TRANSLATION_KEYS: CoverageTypesMapping = {
  circlek: {
    [CoverageTypeKeyType.trafik]: {
      name: 'coverageDetails.trafik.name',
      description: 'coverageDetails.trafik.description',
      list: 'coverageDetails.trafik.list',
      additionalInfo: 'coverageDetails.trafik.additionalInfo',
    },
    [CoverageTypeKeyType.halv]: {
      name: 'coverageDetails.halvforsakring.name',
      description: 'coverageDetails.halvforsakring.description',
      list: 'coverageDetails.halvforsakring.list',
      additionalInfo: 'coverageDetails.halvforsakring.additionalInfo',
    },
    [CoverageTypeKeyType.hel]: {
      name: 'coverageDetails.helforsakring.name',
      description: 'coverageDetails.helforsakring.description',
      list: 'coverageDetails.helforsakring.list',
    },
    [CoverageTypeKeyType.super]: {
      name: 'coverageDetails.super.name',
      description: 'coverageDetails.super.description',
      list: 'coverageDetails.super.list',
    },
  },
  instabank: {
    '1': {
      name: 'coverageDetails.ansvar.name',
      description: 'coverageDetails.ansvar.description',
      list: 'coverageDetails.ansvar.list',
    },
    '2': {
      name: 'coverageDetails.delkasko.name',
      description: 'coverageDetails.delkasko.description',
      list: 'coverageDetails.delkasko.list',
    },
    '3': {
      name: 'coverageDetails.kasko.name',
      description: 'coverageDetails.kasko.description',
      list: 'coverageDetails.kasko.list',
    },
    '4': {
      name: 'coverageDetails.super.name',
      description: 'coverageDetails.super.description',
      list: 'coverageDetails.super.list',
    },
  },
}

export const DEV_INSTABANK_LINK_CANCEL_AUTH = 'https://instabank.no/forsikring/bil'
export const FALK_LINK = 'https://rsa2.falck.no/'

const defaultNoInsurelyTestCompany = 'no-demo'
const defaultSeInsurelyTestCompany = 'se-demo'

const INSURELY_TEST_COMPANY_ID_MAP: Record<PartnerName, Record<EnvType, string | null>> = {
  [PartnerName.digisure]: {
    [EnvType.ENV_LOCAL]: defaultNoInsurelyTestCompany,
    [EnvType.ENV_DEV]: defaultNoInsurelyTestCompany,
    [EnvType.ENV_ACC]: defaultNoInsurelyTestCompany,
    [EnvType.ENV_PROD]: defaultNoInsurelyTestCompany,
  },
  [PartnerName.instabank]: {
    [EnvType.ENV_LOCAL]: defaultNoInsurelyTestCompany,
    [EnvType.ENV_DEV]: defaultNoInsurelyTestCompany,
    [EnvType.ENV_ACC]: defaultNoInsurelyTestCompany,
    [EnvType.ENV_PROD]: null,
  },
  [PartnerName.circlek]: {
    [EnvType.ENV_LOCAL]: defaultSeInsurelyTestCompany,
    [EnvType.ENV_DEV]: defaultSeInsurelyTestCompany,
    [EnvType.ENV_ACC]: defaultSeInsurelyTestCompany,
    [EnvType.ENV_PROD]: null,
  },
}

export const DEFAULT_INSURELY_TEST_COMPANY =
  INSURELY_TEST_COMPANY_ID_MAP[PARTNER_NAME as PartnerName][ENV]

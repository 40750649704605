import { useEffect, useState, ReactNode } from 'react'
import { get } from 'lodash'
import { FieldInputProps } from 'react-final-form'
import { useApolloClient } from '@apollo/react-hooks'

import { FieldOptions } from '../../../types/interface/form.interface'
import { productPropsGetter } from '../../../graphql/datasource'
import { GraphOptionMap } from '../../../types/generated/graphql'

//FIXME: Define types
const useSelectOptions = (
  input: FieldInputProps<string | number | Date, HTMLInputElement>,
  props: { [p: string]: unknown; options: Array<FieldOptions>; children?: ReactNode }
) => {
  const client = useApolloClient()
  const [options, setOptions] = useState<Array<GraphOptionMap>>(
    props.options as Array<GraphOptionMap>
  )

  const fetchDatasource = async () => {
    const data = await productPropsGetter(client)
    const fieldOption = get(data, input.name) as Array<GraphOptionMap>

    if (typeof fieldOption !== 'number') {
      setOptions(fieldOption)
    }
  }

  useEffect(() => {
    if (props.fetchOptions) {
      ;(async () => {
        await fetchDatasource()
      })()
    }
    // TODO: FIx with using correct deps in [] and useCallback. Left here to not break anything
    // eslint-disable-next-line
  }, [])

  return {
    options,
  }
}

export default useSelectOptions

import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import { LMCommonProps } from '../../../types/MLCommonProps'
import { BreadcrumbsV2, Typography } from '@dg-shared'
import { StylesProps } from '../../../types/interface/styles.interface'
// Changed according to the specific design [link on task]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: (props: StylesProps) => ({
      display: 'flex',
      flexDirection: 'column',
      height: `${props.height}px`,
    }),
    page: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
    },
    content: {
      flexGrow: 1,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    title: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(2),
    },
    subTitle: {
      marginBottom: theme.spacing(3),
    },
  })
)

const LMBasicV3: FC<LMCommonProps> = ({
  flow,
  titleHeader,
  H2SubTitle,
  paragraphSubTitle,
  breadcrumbsBtnText,
  breadcrumbsBtnPath,
  stepPercentage,
  children,
  customClass, //FIXME: Remove this prop when all pages will have the same positioning of elements
}) => {
  const classes = useStyles({ height: window.innerHeight })

  return (
    <Grid container className={`${classes.container} ${customClass}`}>
      <Grid item xs={12} className={classes.page}>
        {(!!breadcrumbsBtnPath || !!breadcrumbsBtnText) && (
          <BreadcrumbsV2
            flow={flow}
            goTo={breadcrumbsBtnPath}
            buttonText={breadcrumbsBtnText.toString()}
            stepsPercentage={stepPercentage}
          />
        )}
        {!!titleHeader && (
          <Typography type='h1' className={classes.title}>
            {titleHeader}
          </Typography>
        )}
        {!!H2SubTitle && (
          <Typography type='h2' className={classes.subTitle}>
            {H2SubTitle}
          </Typography>
        )}
        {!!paragraphSubTitle && (
          <Typography className={classes.subTitle}>{paragraphSubTitle}</Typography>
        )}
        <section className={classes.content}>{children}</section>
      </Grid>
    </Grid>
  )
}

export default LMBasicV3

import React, { FC } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import { TRANSLATIONS as T, VEHICLE_COVERAGE_TYPE_TRANSLATION_KEYS } from '../../../../constants'
import { FormFieldV2 } from '../../../../types/interface/form.interface'
import { Typography, useTranslate } from '@dg-shared'
import InfoIcon from '@dg-shared/InfoIcon/InfoIcon'
import { CoverageTypeKeyType, PartnerName } from '../../../../types/interface/quote.interface'

const useStyles = makeStyles(() =>
  createStyles({
    row: {
      display: 'flex',
    },
  })
)

interface SuperCoverLabelProps {
  field: FormFieldV2
  invalid: boolean
  toggler: (state: boolean) => boolean
}

const SuperCoverCustomLabel: FC<SuperCoverLabelProps> = () => {
  const { t } = useTranslate(T.OFFER_CHANGE)
  const { row } = useStyles()

  return (
    <section className={row}>
      <Typography color={'textSecondary'}>{t('form.superCover.label')}</Typography>
      <InfoIcon
        coverageType={
          VEHICLE_COVERAGE_TYPE_TRANSLATION_KEYS[PartnerName.circlek][CoverageTypeKeyType.super]
        }
      />
    </section>
  )
}

export default SuperCoverCustomLabel

import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import { get } from 'lodash'

import { AUTH_USER } from '../auth/mutation'
import { Session, UserAuthInput } from '../../types/generated/graphql'

export const authMutator = async (
  client: ApolloClient<NormalizedCacheObject>,
  userInput: UserAuthInput,
  reCaptchaToken: string | null,
  rName = ['data', 'authUser']
): Promise<Session> => {
  const response = await client.mutate({
    mutation: AUTH_USER,
    variables: {
      data: userInput,
      reCaptchaToken,
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })

  return get(response, [...rName])
}

import { parse, startOfDay } from 'date-fns'
import {
  OFFSET_90_DAYS,
  OFFSET_30_DAYS,
  OFFSET_365_DAYS,
  FORMAT_YEAR_MONTH_DAY_HYPHEN,
} from '../constants'

import { VehicleNo, QuoteType } from '../types/generated/graphql'
import { getProductName } from '@dg-util'

interface ProductMap {
  [key: string]: number
}
interface DateCalculationMap {
  [key: string]: ProductMap
}

interface ProductCalculation {
  [key: string]: () => ProductDateMap
}
interface PartnerProductDateCalculations {
  [key: string]: ProductCalculation
}

interface ProductDateMap {
  min: Date
  max: Date
  selected: Date
}

const convertIntoDateWithOffset = (offset: number): Date => new Date(Date.now() + offset)

const defineOffsetNumber = (realCompany = 0): number => {
  return (
    {
      instabank: {
        travel: realCompany ? OFFSET_30_DAYS : 0,
        vehicle: 0,
      },
      circlek: {
        vehicle: 0,
      },
    } as DateCalculationMap
  )[PARTNER_NAME][getProductName()]
}

const getPolicyStartDateVehicleNo = (quote: VehicleNo) =>
  parse(quote.policyStartDate, FORMAT_YEAR_MONTH_DAY_HYPHEN, new Date())

const getStartDate = (realCompany?: number): Date =>
  convertIntoDateWithOffset(defineOffsetNumber(realCompany))

const getDateVehicleNo = (quote: VehicleNo) => (): ProductDateMap => {
  const startDate = getStartDate()

  const quoteOwner = quote.canVerify
  const policyStartDate = getPolicyStartDateVehicleNo(quote as VehicleNo)
  const selectedDate = policyStartDate.getTime() < startDate.getTime() ? startDate : policyStartDate

  return {
    min: startOfDay(quoteOwner ? selectedDate : startDate),
    max: convertIntoDateWithOffset(OFFSET_90_DAYS),
    selected: quoteOwner ? selectedDate : startDate,
  }
}

const getDateTravelNo = (realCompany?: number) => (): ProductDateMap => {
  const startDate = getStartDate(realCompany)

  return {
    min: startOfDay(startDate),
    max: convertIntoDateWithOffset(OFFSET_90_DAYS),
    selected: startDate,
  }
}

const getDateVehicleSe = (): ProductDateMap => {
  const startDate = getStartDate()

  return {
    min: startOfDay(startDate),
    max: convertIntoDateWithOffset(OFFSET_365_DAYS),
    selected: null,
  }
}

const defineOfferDate = (quote: QuoteType, realCompany?: number): ProductDateMap =>
  ((
    {
      circlek: {
        vehicle: getDateVehicleSe,
      },
      instabank: {
        vehicle: getDateVehicleNo(quote as VehicleNo),
        travel: getDateTravelNo(realCompany),
      },
    } as PartnerProductDateCalculations
  )[PARTNER_NAME][getProductName()]())

export default defineOfferDate

import { ReactNode } from 'react'

import { QuoteType, QuoteUpdateInput } from '../generated/graphql'

export interface QuoteAccepted {
  id: number
  policyNumber: number
  startDate: string
}

export interface QuoteUpdateValues {
  quoteInput: QuoteUpdateInput
  id: number
}

export interface OfferItemProps {
  itemData: QuoteType
  colLeft?: ReactNode
  colRight?: ReactNode
  isImage?: boolean
  showDivider?: boolean
}

export type CoverageType = {
  name: string
  description: string
  list?: string
  additionalInfo?: string
}

export enum CoverageTypeKeyType {
  trafik = 'Trafik',
  halv = 'Halv',
  hel = 'Hel',
  super = '4',
}

export interface CoverageTypesMapping {
  [key: string]: {
    [key: string]: CoverageType
  }
}

export enum ProductType {
  VEHICLE = 100,
  TRAVEL = 102,
}

export enum PartnerName {
  instabank = 'instabank',
  circlek = 'circlek',
  digisure = 'digisure',
}

export enum ProductName {
  VEHICLE = 'vehicle',
  TRAVEL = 'travel',
}

import { MachineConfig, StateNode } from 'xstate'
import { has, merge } from 'lodash'
import { Events, FSMCommonTransitions as TV } from './constants'
import { IS_PRODUCTION } from '../../config'

export type ContextType = { [key: string]: unknown }
export type StateSchemaType = {
  states: { [key: string]: StateNode }
}
export type EventType = { type: Events; isVerify: boolean }
export type MachineConfigType = MachineConfig<ContextType, StateSchemaType, EventType>

// FSM states that available only on DEV, LOCAL, ACC environments
const FSMStandards: MachineConfigType = !IS_PRODUCTION
  ? {
      states: {
        [TV.STANDARDS]: {
          on: {
            [Events.BACK]: TV.AUTH,
          },
        },
      },
      on: {
        [Events.STANDARDS]: {
          target: TV.STANDARDS,
          actions: 'setBackPath',
        },
      },
    }
  : {}

const FSMConfigCommon: MachineConfigType = {
  states: {
    [TV.ERROR]: {
      on: {
        [Events.BACK]: [
          {
            target: TV.AUTH,
            cond: (ctx) => has(ctx.backPath, TV.AUTH),
          },
          {
            target: TV.CHANGE,
            cond: (ctx) => ctx.backPath === TV.CHANGE,
          },
          {
            target: TV.VERIFY,
            cond: (ctx) => ctx.backPath === TV.VERIFY,
          },
          {
            target: TV.PURCHASED,
            cond: (ctx) => ctx.backPath === TV.PURCHASED,
          },
        ],
      },
    },
  },
  on: {
    [Events.ERROR]: {
      target: TV.ERROR,
      actions: 'setBackPath',
    },
  },
}

export default merge(FSMStandards, FSMConfigCommon)

import React, { FC } from 'react'
import { Route, Redirect, RouteComponentProps } from 'react-router-dom'
import { isEmpty, isNil } from 'lodash'

import CONFIG from '../config'
import { COOKIE_TFF } from '../constants/auth-constants'
import { FlowType } from '../constants'
import { SessionData, SessionDataNoBID } from '../types/interface/session.interface'
import { FSMRouterConsumer } from '../FSM'
import { getSessionStorageAuthData } from '../service/util.service'
import getCookieByName from '../util/getCookieByName'
import BaseLayout from '../BaseLayout'

export interface RouteComponent {
  path: string
  component: React.ComponentType<RouteComponentProps> | React.ComponentType
}

export interface FSMProtectedRouteProps extends RouteComponent {
  routes?: RouteComponent[]
  protectSrc?: number
}

const FSMProtectedRoute: FC<FSMProtectedRouteProps> = ({ component, protectSrc, ...rest }) => {
  //FIXME: Define type for the component
  const Component = component

  const isTFF = getCookieByName(COOKIE_TFF).includes('true')
  const parsedUserInfo: SessionData = getSessionStorageAuthData(CONFIG.USER_INFO_KEY)
  const parsedUserInfoBID: SessionData = getSessionStorageAuthData(CONFIG.USER_INFO_KEY_BID)

  const isAuthenticated: boolean = !!parsedUserInfo && !!(parsedUserInfo as SessionDataNoBID).token
  const isAuthenticatedBID: boolean = !isEmpty(parsedUserInfoBID) && !isNil(parsedUserInfoBID.token)

  const sourceToken: boolean = !isNil(protectSrc) && protectSrc === FlowType.qb
  const sourceBId: boolean = !isNil(protectSrc) && protectSrc === FlowType.FNOL

  const isQuoteAndBuyAccess: boolean = sourceToken && (isAuthenticated || isTFF)
  const isProfileAccess: boolean = sourceBId && isAuthenticatedBID

  return (
    <FSMRouterConsumer>
      {(context) => {
        return (
          <Route
            exact
            {...rest}
            render={(props: RouteComponentProps) => {
              if (isQuoteAndBuyAccess || isProfileAccess) {
                return (
                  <BaseLayout>
                    <Component {...props} {...{ send: context.service.send }} />
                  </BaseLayout>
                )
              }

              //FIXME: Redirected us back to /auth every time: getRedirectPath(protectSrc)
              //FIXME: Better to return null
              return <Redirect to='/' />
            }}
          />
        )
      }}
    </FSMRouterConsumer>
  )
}

export default FSMProtectedRoute

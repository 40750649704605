import React, { FC } from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import { endOfDay, startOfDecade } from 'date-fns'

import { FNOL_FORM_ID, TRANSLATIONS as T } from '../../../../../constants'
import { useTranslate, Typography, FNOLPageWrapper } from '@dg-shared'
import FormBuilder from '@dg-shared/forms/FormBuilder'
import { CommonPageProps } from '../../../../../types/interface/fsm.interface'
import useLogic, { DateFormValues } from './useLogic'
import useCommonLegalAidStyles from '../commonStyles'

const Page: FC<CommonPageProps> = ({ service }) => {
  const classes = useCommonLegalAidStyles()
  const client = useApolloClient()
  const { t } = useTranslate(`${T.FNOL_OTHER_LEGAL_AID}.date`)
  const { selectedDataFields, handleSubmit, updateForm } = useLogic(client, service.send)

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t(`${T.COMMON}/back`)} breadcrumbsStepPercentage={50}>
      <Typography type='h1'>{t('title')}</Typography>
      <Typography>{t('subtitle')}</Typography>
      <section className={classes.formContainer}>
        <FormBuilder
          id={FNOL_FORM_ID.DATE}
          submitHandler={handleSubmit}
          submitClass={classes.submitBtn}
          classes={classes.form}
          filledFieldsData={selectedDataFields}
          isFormControllable
          setFormState={({ values }) => updateForm(values as DateFormValues)}
          validationArgs={{ minDate: startOfDecade(new Date()), maxDate: endOfDay(new Date()) }}
        />
      </section>
    </FNOLPageWrapper>
  )
}

export default Page

import {
  CLAIMS,
  FNOL_OTHER,
  FNOL_DAMAGE_LIST,
  FNOL_ACCIDENT_FORM,
  FNOL_LEGAL_AID_DATE,
  FNOL_LEGAL_AID_COMPENSATION,
  FNOL_ACCIDENT_COMMENTS,
  FNOL_CAUSE_OF_EMERGENCY,
  FNOL_COLLISION,
  FNOL_WINDSHIELD,
  FNOL_ROADSIDE_ASSISTANCE,
} from '../route-constants'
import { Type as ClaimType, EventOtherReasons, FireReasons } from '../claim-constants'

interface ConditionOption {
  negationCoverageType?: Array<number>
}

export interface Option {
  key: string
  text: string
  value: string
  answerKey?: number
  conditions?: ConditionOption
}

export interface QuestionRouteMap {
  [key: string]: string
}

export interface ClaimFlowItem {
  pageTransKey: string
  breadcrumbsBtnPath: string
  stepPercentage: number
  fields: Array<Option>
}

export interface ClaimFormAdditionalOption {
  autoFocus?: boolean
  multiline?: boolean
  fieldMaxDate?: number
  fieldInputVariant?: string
  fieldType?: string
  fieldLabel: string
  fieldPlaceholder: string
}

export interface ClaimFormItemOption {
  inputAdornmentText?: string
  format?: string
  name: string
  component: string
  additionalFieldProps: ClaimFormAdditionalOption
}

export interface ClaimFormOptions {
  formValidator?: string
  optional?: boolean
  buttonSubmitText?: string
  classes?: string
  dataTestid?: string
  dataButtonTestId?: string
}

export interface ClaimFormItem {
  formOptions?: ClaimFormOptions
  pageTransKey: string
  breadcrumbsBtnPath: string
  stepPercentage: number
  nextBtnPath: string
  fields: Array<ClaimFormItemOption>
  deadEnd?: boolean
}

// TODO: All names Uppercase
export const QUESTIONS = {
  WHAT_HAS_HAPPENED: 'QuestionChooseCorrectClaim', // fnol/other
  HAPPENED_OTHER_REASONS: 'QuestionWhatHasHappenedOtherReasons', // damage-list
  REASON_FOR_FIRE: 'QuestionReasonForFire', // damage-brann
  WHEN_DID_IT_HAPPENED: 'QuestionWhenDidItHappened', // damage-accident-form
  WHERE_DID_IT_HAPPENED: 'QuestionWhereDidItHappened', // damage-accident-form
  WHEN_DID_DISPUTE_OCCURED: 'QuestionWhenDidDisputeOccurred', // damage-legal-aid-date
  WHAT_IS_THE_DISPUTED_AMMOUNT: 'QuestionWhatIsTheDisputedAmount', // damage-legal-aid-compensation
  ANY_OTHER_INFORMATION_WE_SHOULD_KNOW_OF: 'QuestionAnyOtherInformationWeShouldKnowOf', // damage-legal-aid-comments
}

export const FLOW_OTHER_VALIDATION_SCHEMA = {
  [FNOL_OTHER.toString()]: [QUESTIONS.WHAT_HAS_HAPPENED],
  [FNOL_DAMAGE_LIST]: [QUESTIONS.WHAT_HAS_HAPPENED, QUESTIONS.HAPPENED_OTHER_REASONS],
  [FNOL_CAUSE_OF_EMERGENCY]: [
    QUESTIONS.WHAT_HAS_HAPPENED,
    QUESTIONS.HAPPENED_OTHER_REASONS,
    QUESTIONS.REASON_FOR_FIRE, // damage-brann
  ],
  [FNOL_ACCIDENT_FORM]: [
    QUESTIONS.WHAT_HAS_HAPPENED,
    QUESTIONS.HAPPENED_OTHER_REASONS,
    QUESTIONS.REASON_FOR_FIRE, // damage-brann
    QUESTIONS.WHEN_DID_IT_HAPPENED, // damage-accident-form
    QUESTIONS.WHERE_DID_IT_HAPPENED, // damage-accident-form
  ],
  [FNOL_LEGAL_AID_DATE]: [
    QUESTIONS.WHAT_HAS_HAPPENED,
    QUESTIONS.HAPPENED_OTHER_REASONS,
    QUESTIONS.WHEN_DID_DISPUTE_OCCURED, // damage-legal-aid-date
  ],
  [FNOL_LEGAL_AID_COMPENSATION]: [
    QUESTIONS.WHAT_HAS_HAPPENED,
    QUESTIONS.HAPPENED_OTHER_REASONS,
    QUESTIONS.WHEN_DID_DISPUTE_OCCURED, // damage-legal-aid-date
    QUESTIONS.WHAT_IS_THE_DISPUTED_AMMOUNT, // damage-legal-aid-compensation
  ],
  [FNOL_ACCIDENT_COMMENTS]: [
    QUESTIONS.WHAT_HAS_HAPPENED, // /fnol/other
    QUESTIONS.HAPPENED_OTHER_REASONS, // damage-list
    QUESTIONS.REASON_FOR_FIRE, // damage-brann
    QUESTIONS.WHEN_DID_IT_HAPPENED, // damage-accident-form
    QUESTIONS.WHERE_DID_IT_HAPPENED, // damage-accident-form
    QUESTIONS.WHAT_IS_THE_DISPUTED_AMMOUNT, // damage-legal-aid-compensation
    QUESTIONS.ANY_OTHER_INFORMATION_WE_SHOULD_KNOW_OF, // damage-legal-aid-comments
  ],
}

export const CLAIM_FLOW_TYPES: ClaimFlowItem = {
  pageTransKey: 'selectType',
  breadcrumbsBtnPath: CLAIMS,
  stepPercentage: 20,
  fields: [
    {
      key: QUESTIONS.WHAT_HAS_HAPPENED,
      text: 'windshield',
      value: FNOL_WINDSHIELD,
      answerKey: ClaimType.windshieldDamage,
      conditions: {
        negationCoverageType: [1],
      },
    },
    {
      key: QUESTIONS.WHAT_HAS_HAPPENED,
      text: 'collision',
      value: FNOL_COLLISION,
      answerKey: ClaimType.collision,
    },
    {
      key: QUESTIONS.WHAT_HAS_HAPPENED,
      text: 'roadsideAssistance',
      value: FNOL_ROADSIDE_ASSISTANCE,
      answerKey: ClaimType.roadAssistance,
      conditions: {
        negationCoverageType: [1],
      },
    },
    {
      key: QUESTIONS.WHAT_HAS_HAPPENED,
      text: 'other',
      value: FNOL_DAMAGE_LIST,
      answerKey: ClaimType.other,
    },
  ],
}

export const DAMAGE_LIST_ANSWERS: ClaimFlowItem = {
  pageTransKey: 'other.damageList',
  breadcrumbsBtnPath: FNOL_OTHER,
  stepPercentage: 30,
  fields: [
    {
      key: QUESTIONS.HAPPENED_OTHER_REASONS,
      text: 'vandalism',
      value: FNOL_ACCIDENT_FORM,
      answerKey: EventOtherReasons.vandalism,
    },
    {
      key: QUESTIONS.HAPPENED_OTHER_REASONS,
      text: 'machineDamage',
      value: FNOL_ACCIDENT_FORM,
      answerKey: EventOtherReasons.damageMechanical,
    },
    {
      key: QUESTIONS.HAPPENED_OTHER_REASONS,
      text: 'errorFilling',
      value: FNOL_ACCIDENT_FORM,
      answerKey: EventOtherReasons.misfueling,
    },
    {
      key: QUESTIONS.HAPPENED_OTHER_REASONS,
      text: 'lostKey',
      value: FNOL_ACCIDENT_FORM,
      answerKey: EventOtherReasons.keyLost,
    },
    {
      key: QUESTIONS.HAPPENED_OTHER_REASONS,
      text: 'legalAid',
      value: FNOL_LEGAL_AID_DATE,
      answerKey: EventOtherReasons.legalAid,
    },
    // TODO:  Bran flow is commented. Don't have answerKey(integer) for this option.
    // Uncomment when Insicon provide the correct answerKey.
    // { // Related to Yaroslav https://insicon.zendesk.com/hc/en-us/requests/8941
    //   key: QUESTIONS.HAPPENED_OTHER_REASONS,
    //   text: 'other.page.damageBtnFire',
    //   value: FNOL_CAUSE_OF_EMERGENCY //FIXME: We do not have value here
    //   answerKey: ???
    // }
  ],
}

export const DAMAGE_CAUSE_OF_EMERGENCY: ClaimFlowItem = {
  pageTransKey: '', //TODO: Fix in scope of next subtask
  // pageTitle: 'other.brann.title.header',
  // pageText: 'other.brann.title.annotation',
  breadcrumbsBtnPath: FNOL_DAMAGE_LIST,
  stepPercentage: 50,
  fields: [
    {
      key: QUESTIONS.REASON_FOR_FIRE,
      text: 'other.brann.cause.leakage',
      value: FNOL_ACCIDENT_FORM,
      answerKey: FireReasons.leakOilOrGas,
    },
    {
      key: QUESTIONS.REASON_FOR_FIRE,
      text: 'other.brann.cause.electric',
      value: FNOL_ACCIDENT_FORM,
      answerKey: FireReasons.faultElectrical,
    },
    {
      key: QUESTIONS.REASON_FOR_FIRE,
      text: 'other.brann.cause.lit',
      value: FNOL_ACCIDENT_FORM,
      answerKey: FireReasons.lit,
    },
    {
      key: QUESTIONS.REASON_FOR_FIRE,
      text: 'other.brann.cause.openFire',
      value: FNOL_ACCIDENT_FORM,
      answerKey: FireReasons.fireOpen,
    },
    {
      key: QUESTIONS.REASON_FOR_FIRE,
      text: 'other.brann.cause.other',
      value: FNOL_ACCIDENT_FORM,
      answerKey: FireReasons.other,
    },
  ],
}

import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import { DatasourceValue } from '../../types/interface/datasource.interface'
import { GET_INSURANCE_COMPANIES_LIST } from './query'

export const insuranceCompaniesListGetter = async (
  client: ApolloClient<NormalizedCacheObject>,
  rName: Array<string> = ['getInsuranceCompaniesList', 'insuranceCompanies']
): Promise<Array<DatasourceValue>> => {
  const response = await client.query({
    query: GET_INSURANCE_COMPANIES_LIST,
    errorPolicy: 'all',
  })

  return response.data[rName[0]][rName[1]]
}

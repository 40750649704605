import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    coverageInfo: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    coverageInfoButton: {
      padding: 0,
      paddingLeft: '5px',
      color: theme.palette.primary.main,
    },
  })
)

interface CoverageDetailedBtnProps {
  classes?: Record<string, string>
  toggleInfo: () => void
  ariaText: string
}

const CoverageDetailedBtn: FC<CoverageDetailedBtnProps> = ({
  toggleInfo,
  children,
  classes = {},
  ariaText,
}) => {
  const classesComponent = useStyles()
  return (
    <div className={[classesComponent.coverageInfo, classes.root].join(' ')}>
      {children}
      <IconButton
        aria-label={ariaText}
        className={classesComponent.coverageInfoButton}
        onClick={() => {
          toggleInfo()
        }}
      >
        <Icon fontSize='small'>info_outlined</Icon>
      </IconButton>
    </div>
  )
}

export default CoverageDetailedBtn

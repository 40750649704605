import React, { FC, useState } from 'react'
import { Card, CardContent } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { TRANSLATIONS as T } from '../../../constants'
import { FORM_ID } from '../../../constants/forms-constants'
import { UserData } from '../../../types/interface/form.interface'
import {
  ExternalSubmitBtn,
  ProductWrapper,
  ReCaptchaPrivacyTerms,
  Typography,
  useTranslate,
} from '@dg-shared'
import DetailedInfoShared from '@dg-shared/DetailedInfoShared'
import FormBuilder from '@dg-shared/forms/FormBuilder'
import BrandConfig from 'BrandConfig'
import { PartnerName, ProductName } from '../../../types/interface/quote.interface'

export const useAuthRegularStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    errorMessage: {
      marginBottom: theme.spacing(3),
    },
    submitBtn: {
      margin: `0 auto`,
    },
    infoCard: {
      overflow: 'visible',
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    additionalInfo: {
      marginTop: theme.spacing(1.5),
      textAlign: 'center',
      '& a': {
        color: 'inherit',
      },
    },
  })
)

interface AuthRegularProps {
  handleAuth(data: UserData): void
}

const AuthRegular: FC<AuthRegularProps> = ({ handleAuth }) => {
  const { t } = useTranslate(T.AUTH)
  const classes = useAuthRegularStyles()
  const [infoOpened, setInfoOpened] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(true)
  const detailsText = {
    title: t('form.ssn.details.title'),
    content: t('form.ssn.details.content'),
    link: t('form.ssn.details.link'),
  }

  return (
    <>
      <section data-testid='auth-regular-wrapper-first' className={classes.wrapper}>
        {infoOpened && (
          <Card className={classes.infoCard}>
            <CardContent>
              <DetailedInfoShared hideDetails={() => setInfoOpened(false)} text={detailsText} />
            </CardContent>
          </Card>
        )}
        <FormBuilder
          id={FORM_ID.AUTH}
          submitHandler={handleAuth}
          handleBtnDisabled={setBtnDisabled}
          customLabelFunc={setInfoOpened}
          customSubmit
        />
      </section>
      <section data-testid='auth-regular-wrapper-second'>
        <ExternalSubmitBtn
          text={t(`form.submitText`)}
          targetId={FORM_ID.AUTH}
          disabled={btnDisabled}
          customClassName={classes.submitBtn}
        />
        <ReCaptchaPrivacyTerms links={BrandConfig.GOOGLE_RECAPTCHA_LINKS} />
        <ProductWrapper products={[ProductName.VEHICLE]} partners={[PartnerName.circlek]}>
          <Typography size='small' color='textDisclaimer' className={classes.additionalInfo}>
            {t('additionalInfo.text') + ' '}
            <a href={BrandConfig.MAIN_LINK.vehicle} target='_blank' rel='noreferrer'>
              {t('additionalInfo.link')}
            </a>
          </Typography>
        </ProductWrapper>
      </section>
    </>
  )
}

export default AuthRegular

import { InsurelyResultType } from '../types/insurelyTypes'
import { QuoteVehicleType } from '../types/Vehicles'

// Filter results with the corresponding registrationNo and insuranceType
// Include the insurances for vehicles without registrationNo in case if there are no
// match with the same registrationNo like in quote
export const filterVehicleInsurelyResults = (
  results: InsurelyResultType[],
  quote: QuoteVehicleType
) => {
  // Filter only vehicle insurances
  const vehicleInsurances = results.filter(
    (result) => result.insurance.insuranceType === 'vehicleInsurance'
  )

  // Check if we have insurance offers with the same registrationNo that we have in quote
  const insurancesWithTheSameRegistrationNo = vehicleInsurances.filter(
    (result) => result.insurance.registrationNo === quote.productId
  )

  // return them immediately if found at least one
  if (insurancesWithTheSameRegistrationNo.length > 0) {
    // Return only the first found match
    return insurancesWithTheSameRegistrationNo.slice(0, 1)
  } else {
    // Return all offers without registration number if no insurancesWithTheSameRegistrationNo match found
    return vehicleInsurances.filter((result) => !result.insurance.registrationNo)
  }
}

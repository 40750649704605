import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Grid, Card, Hidden } from '@material-ui/core'

import { LDCardTwoColV2FSMProps } from '../../../types/interface/layouts.interface'
import { BrandedHeaderShared, Typography, FSMBreadcrumbs } from '@dg-shared'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        minWidth: '750px',
      },
    },
    card: {
      width: '100%',
      height: 'auto',
      display: 'flex',
      marginBottom: theme.spacing(5),
      [theme.breakpoints.up('md')]: {
        flexDirection: 'column',
        maxWidth: '968px',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
    cols: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
      // we use interpolation here, cause Material returns a value without 'px' for Block or Inline paddings/margins
      paddingBlock: `${theme.spacing(5)}px`,
      paddingInline: `${theme.spacing(3)}px`,
    },
    colLeft: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(2),
      },
    },
    colRight: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(2),
      },
    },
  })
)

const LDCardTwoColV2FSM: FC<LDCardTwoColV2FSMProps> = ({
  send,
  colLeft,
  colRight,
  title,
  subTitle,
  nextBtn,
  breadcrumbsBtnText,
  breadcrumbsStepPercentage,
  breadcrumbs = false,
  hideHeader = false,
}) => {
  const classes = useStyles()

  const annotation =
    typeof subTitle === 'string' ? (
      <Typography type='h3' color='textSecondary'>
        {subTitle}
      </Typography>
    ) : (
      subTitle
    )

  return (
    <Grid container direction='row' component='article' className={classes.container}>
      {!hideHeader && (
        <Hidden mdUp={false} smDown={true}>
          <Grid item xs={12}>
            <BrandedHeaderShared />
          </Grid>
        </Hidden>
      )}

      <Grid item xs={12}>
        {breadcrumbs && (
          <FSMBreadcrumbs
            send={send}
            buttonText={breadcrumbsBtnText}
            stepsPercentage={breadcrumbsStepPercentage}
          />
        )}
      </Grid>

      <Grid item xs={12} className={classes.card}>
        {title && <Typography type='h1'>{title}</Typography>}
        {annotation}
        <Card className={classes.cols}>
          <section className={classes.colLeft}>{colLeft}</section>
          <section className={classes.colRight}>{colRight}</section>
        </Card>
        {nextBtn && nextBtn}
      </Grid>
    </Grid>
  )
}

export default LDCardTwoColV2FSM

import React, { FC } from 'react'

import { TRANSLATIONS as T } from '../../../../constants/translations-constants'
import { Typography, useTranslate } from '@dg-shared'
import useContentStyles from './useContentStyles'
import { TravelNo } from '../../../../types/generated/graphql'

interface ContentProps {
  offer: TravelNo
}

const Content: FC<ContentProps> = ({
  offer: { priceMonthly, coverage, coverArea, taxMonthly },
}) => {
  const { t } = useTranslate(`${T.OFFER_VERIFY}.card`)
  const classes = useContentStyles()

  return (
    <>
      <Typography type='h3'>{t(`${T.COMMON}:card.title`)}</Typography>
      <Typography className={classes.textDetails}>
        {t(`${T.COMMON}:productProps.${coverage}`)}
      </Typography>
      <Typography className={classes.textDetails}>
        {t(`${T.COMMON}:productProps.${coverArea}`)}
      </Typography>

      <section className={`${classes.row} ${classes.spaceTop}`}>
        <Typography type='h3'>{t(`${T.COMMON}:card.total`)}</Typography>
        <section className={classes.priceRow}>
          {/* //FIXME: This should be done on BFF */}
          <Typography type='h3' className={classes.priceText}>
            {`${priceMonthly + (taxMonthly || 0)}`}
          </Typography>
          <Typography className={classes.currency}>{t('priceMonth')}</Typography>
        </section>
      </section>
    </>
  )
}

export default Content

import React, { FC, useState } from 'react'
import { Card, CardContent, CardMedia, Divider, Hidden } from '@material-ui/core'
import { get, has } from 'lodash'

import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { FORM_ID } from '../../../constants'
import {
  OfferItemProps,
  ProductType,
  PartnerName,
  ProductName,
} from '../../../types/interface/quote.interface'
import { QuoteUpdateInput } from '../../../types/generated/graphql'
import { FormState } from '../../../types/interface/form.interface'
import {
  CoverageDetailedInfo,
  Typography,
  useTranslate,
  CustomCardHeader,
  ProductWrapper,
} from '@dg-shared'
import { exampleCarImg } from 'BrandedImages'
import useContentStyles from '../Review/Content/useContentStyles'
import { getPID } from '@dg-util'
import FormBuilder from '@dg-shared/forms/FormBuilder'

interface ChangeItemProps extends OfferItemProps {
  updateFormState(data: FormState<QuoteUpdateInput>): void
  updateOfferHandler(values: QuoteUpdateInput): void
  initialFormValues: Record<string, unknown>
  submitDisabled: boolean
}

const ChangeItem: FC<ChangeItemProps> = ({
  updateFormState,
  updateOfferHandler,
  itemData,
  initialFormValues,
  submitDisabled,
}) => {
  const { t } = useTranslate(T.OFFER_CHANGE)
  const productID = getPID()
  const isTravel = productID === ProductType.TRAVEL
  const classes = useContentStyles({ isImage: isTravel, brandPageBackgroundCard: 'travelReview' })
  const [coverageInfoOpened, setCoverageInfoState] = useState<boolean>(false)

  const handleOfferUpdate = (values: QuoteUpdateInput): void => {
    updateOfferHandler(values)
  }

  const coverageDetailedInfo = itemData.__typename !== 'TravelNo' &&
    has(itemData, 'coverageType') && (
      <CoverageDetailedInfo
        coverageType={itemData.coverageType}
        onClose={() => setCoverageInfoState(false)}
        translationCondition={get(itemData, 'superCover', false) ? 'isSuper' : 'isNotSuper'}
      />
    )

  return (
    <Card elevation={3}>
      <CustomCardHeader
        priceMonthly={itemData.priceMonthly}
        priceDetails={get(itemData, 'taxAnnual') || itemData.priceAnnual}
      />
      <CardContent className={classes.twoCol}>
        <section className={classes.colLeft}>
          <ProductWrapper products={[ProductName.TRAVEL]} partners={[PartnerName.instabank]}>
            <Typography type='h2' className={classes.cardSubTitle}>
              {t('card.title')}
            </Typography>
          </ProductWrapper>

          {itemData.__typename !== 'TravelNo' && (
            <>
              <section>
                <CardMedia
                  component='img'
                  alt='Example Car'
                  image={exampleCarImg}
                  className={classes.itemImage}
                />
              </section>
              <Typography type='h2' className={classes.cardTitle}>
                {`${itemData.carYear} ${itemData.carBrand} ${itemData.carModel}`}
              </Typography>
              <Typography type='h3' className={classes.cardSubTitle}>
                {t(`${T.COMMON}:registrationNum`, { regNum: itemData.productId })}
              </Typography>
              <Hidden mdUp>
                <Divider />
                {coverageInfoOpened && coverageDetailedInfo}
              </Hidden>
            </>
          )}

          <FormBuilder
            id={FORM_ID.CHANGE}
            isFormControllable
            submitHandler={handleOfferUpdate}
            setFormState={updateFormState}
            customLabelFunc={setCoverageInfoState}
            optional={!submitDisabled}
            initialValues={initialFormValues}
            submitText={t('form.submitText')}
            submitClass={classes.buttonSubmit}
          />
        </section>

        <Hidden smDown>
          <div className={classes.colRight}>{coverageDetailedInfo}</div>
        </Hidden>
      </CardContent>
    </Card>
  )
}

export default ChangeItem

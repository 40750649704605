import { gql } from 'graphql-tag'

export const FRAGMENT_VEHICLE_NO_ITEMS = gql`
  fragment VehicleNoItems on VehicleNo {
    country
    productName

    id

    policyStartDate
    prevInsuranceCompanyId

    priceAnnual
    priceMonthly
    priceTotal
    priceMonthlyWithTax
    priceAnnualWithTax
    taxAnnual
    taxMonthly

    carBrand
    carModel
    productId
    carYear
    mileageAnnual

    coverageType
    deductible
    bonus
    ageLimit

    canVerify
    canComparePrice
    displayPrevCompanyField
    encrypted {
      iv
      content
    }
  }
`

import GothamBook from './Gotham-Book.woff'
import GothamBookItalic from './Gotham-BookItalic.woff'
import GothamBlack from './Gotham-Black.woff'
import GothamLight from './Gotham-Light.woff'

const gothamBook = {
  fontFamily: 'Gotham',
  fontStyle: 'regular',
  fontDisplay: 'swap',
  fontWeight: '400',
  src: `url(${GothamBook}) format('woff')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

const gothamItalic = {
  fontFamily: 'Gotham',
  fontStyle: 'italic',
  fontWeight: '400',
  src: `url(${GothamBookItalic}) format('woff')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

const gothamBlack = {
  fontFamily: 'Gotham Black',
  fontStyle: 'normal',
  fontWeight: '700',
  src: `url(${GothamBlack}) format('woff')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

const gothamLight = {
  fontFamily: 'Gotham Light',
  fontStyle: 'normal',
  fontWeight: '400',
  src: `url(${GothamLight}) format('woff')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

export default [gothamBook, gothamBlack, gothamItalic, gothamLight]

import { gql } from 'graphql-tag'
import { FRAGMENT_QUOTE_ERROR_VALIDATION } from '../fragments/QuoteErrorValidation.fragment'
import { FRAGMENT_QUOTE_ERROR_GENERAL } from '../fragments/QuoteErrorGeneral.fragment'

export const SAVE_INITIATED_CLAIM = gql`
  mutation InitClaim($data: InitClaimInput!) {
    initClaim(data: $data) {
      ... on FNOL {
        id
      }
      ... on ServiceError {
        ...ServiceErrorItems
      }
      ... on ServiceErrorValidation {
        ...ServiceErrorValidationItems
      }
    }
  }
  ${FRAGMENT_QUOTE_ERROR_VALIDATION}
  ${FRAGMENT_QUOTE_ERROR_GENERAL}
`

export const ADD_CLAIM_LOCAL = gql`
  mutation AddClaimLocal($body: Object!) {
    addClaimLocal(body: $body) @client
  }
`

export const REMOVE_CLAIM_LOCAL = gql`
  mutation RemoveClaimLocal($body: Object!) {
    removeClaimLocal(body: $body) @client
  }
`

export const SET_CLAIM_SELECTED = gql`
  mutation SetClaimSelectedLocal(
    $licensePlateNumber: String!
    $policyNumber: String!
    $coverageType: Number!
  ) {
    setClaimSelectedLocal(
      licensePlateNumber: $licensePlateNumber
      policyNumber: $policyNumber
      coverageType: $coverageType
    ) @client
  }
`

export const UPLOAD_FILES = gql`
  mutation UploadFiles($data: FilesUploadInput!) {
    uploadFiles(data: $data)
  }
`

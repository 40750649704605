import { Resolvers } from 'apollo-client'
import { get, isEmpty } from 'lodash'

import { GET_CLAIM_INITIATED, GET_CLAIM_SELECTED } from '../claim/query'
import {
  GET_DRAFTS,
  GET_QUOTE_SELECTED,
  GET_QUOTE_ACCEPTED_LOCAL,
  GET_QUOTE_PRODUCT_ID_SELECTED,
} from '../../graphql/quote/query'
import { ProductType, QuoteAccepted } from '../../types/interface/quote.interface'
import { QuoteType } from '../../types/generated/graphql'
import { filterEqual } from '../../service/util.service'
import { getPID } from '../../util/productHandler'
import { InMemoryCache } from 'apollo-cache-inmemory'

const compareSelectedId = (
  cache: InMemoryCache,
  isProductVehicle: boolean,
  quote: QuoteType
): boolean => {
  //FIXME: Clean up this code
  const query = isProductVehicle ? GET_QUOTE_PRODUCT_ID_SELECTED : GET_QUOTE_SELECTED
  const idPath = isProductVehicle ? ['quotes', 'productId'] : ['quotes', 'selected', 'id']
  const idToCompare = 'productId' in quote ? quote.productId : quote.id

  const data = cache.readQuery({ query })
  const selectedId = get(data, idPath, null)

  return selectedId === idToCompare
}

const resolvers: Resolvers = {
  Mutation: {
    //TODO: Check this resolver with errors
    addQuoteLocal: (_, { quote }, { cache }) => {
      try {
        const { quotes } = cache.readQuery({ query: GET_DRAFTS })
        const filteredDuplicatedQuote = filterEqual(quotes.drafts, quote)
        cache.writeQuery({
          query: GET_DRAFTS,
          data: { quotes: { __typename: 'Quotes', drafts: [...filteredDuplicatedQuote, quote] } },
        })

        return quote
      } catch (e) {
        console.error('Error: add quote', e)

        return null
      }
    },
    addClaimLocal: (_, { body }, { cache }) => {
      try {
        const { claims } = cache.readQuery({ query: GET_CLAIM_INITIATED })
        const flow = Object.assign({}, claims.initatedClaim.flow, body)

        cache.writeQuery({
          query: GET_CLAIM_INITIATED,
          data: {
            claims: {
              __typename: 'Claims',
              initatedClaim: {
                __typename: 'Claim',
                flow,
              },
            },
          },
        })
        return null
      } catch (e) {
        console.error('Error: add Claim', e)
        return null
      }
    },
    removeClaimLocal: (_, { body }, { cache }) => {
      try {
        if (!body) {
          return
        }

        const {
          claims: { initatedClaim },
        } = cache.readQuery({ query: GET_CLAIM_INITIATED })

        const flow = Object.keys(initatedClaim.flow).reduce((obj, key) => {
          let updatedState = { ...obj }
          if (body.flowRequiredOptions.includes(key)) {
            updatedState = {
              ...obj,
              [key]: initatedClaim.flow[key],
            }
          }
          return updatedState
        }, {})

        cache.writeQuery({
          query: GET_CLAIM_INITIATED,
          data: {
            claims: {
              __typename: 'Claims',
              initatedClaim: {
                __typename: 'Claim',
                flow,
              },
            },
          },
        })

        return null
      } catch (e) {
        console.error('Error: init claim', e)
        return null
      }
    },
    setClaimSelectedLocal: (_, { licensePlateNumber, policyNumber, coverageType }, { cache }) => {
      try {
        cache.writeQuery({
          query: GET_CLAIM_SELECTED,
          data: {
            claims: {
              __typename: 'Claims',
              selectedClaim: {
                __typename: 'SelectedClaim',
                licensePlateNumber,
                policyNumber,
                coverageType,
              },
            },
          },
        })
        return null
      } catch (e) {
        console.error('Error: add claim selected', e)
        return null
      }
    },
    //TODO: Check this resolver with errors
    setQuoteSelectedLocal: (_, { quote }, { cache }) => {
      try {
        cache.writeQuery({
          query: GET_QUOTE_SELECTED,
          data: { quotes: { __typename: 'Quotes', selected: { __typename: 'Quote', ...quote } } },
        })

        return quote
      } catch (e) {
        //FIXME: Remove this
        console.error('Error: set selected quote', e)
        return null
      }
    },
    setProductIdSelectedLocal: (_, { productId }, { cache }) => {
      try {
        cache.writeQuery({
          query: GET_QUOTE_PRODUCT_ID_SELECTED,
          data: { quotes: { __typename: 'Quotes', productId } },
        })

        return productId
      } catch (e) {
        //FIXME: Remove this
        console.error('Error: set product id for the quote creation', e)
        return null
      }
    },
    updateQuoteDraftLocal: (_, { quote }, { cache }) => {
      try {
        const { quotes } = cache.readQuery({ query: GET_DRAFTS })
        const filteredDrafts = quotes.drafts.filter((q: QuoteType) => q.id !== quote.id)
        const updatedDrafts = [...filteredDrafts, quote]

        cache.writeQuery({
          query: GET_DRAFTS,
          data: {
            quotes: {
              __typename: 'Quotes',
              drafts: updatedDrafts,
            },
          },
        })

        return quote
      } catch (e) {
        console.error('Error: update selected quote', e)
        return null
      }
    },
    addQuoteAcceptedLocal: (_, { quoteAccepted }: { quoteAccepted: QuoteAccepted }, { cache }) => {
      try {
        cache.writeQuery({
          query: GET_QUOTE_ACCEPTED_LOCAL,
          data: {
            quotes: {
              __typename: 'Quotes',
              accepted: { __typename: 'QuoteAccepted', ...quoteAccepted },
            },
          },
        })

        return quoteAccepted
      } catch (e) {
        console.error('Error: add quote accepted', e)

        return null
      }
    },
    addCompareDiffLocal: (_, { diff }: { diff: number }, { cache }) => {
      try {
        cache.writeData({
          data: {
            quotes: {
              __typename: 'Quotes',
              comparisonItems: { __typename: 'CollectionItems', compareDiff: diff },
            },
          },
        })

        return null
      } catch (e) {
        console.error('Error: add comparison Quotes Local', e)

        return null
      }
    },
  },
  Query: {
    getQuoteDraftLocal: (_, __, { cache }) => {
      try {
        const isProductVehicle = getPID() === ProductType.VEHICLE
        const { quotes } = cache.readQuery({ query: GET_DRAFTS })

        if (isEmpty(quotes.drafts)) {
          return null
        }

        const defineProduct = quotes.drafts.find((quote: QuoteType) =>
          compareSelectedId(cache, isProductVehicle, quote)
        )

        return defineProduct ? defineProduct : null
      } catch (e) {
        console.error('Error: get calculated once quote', e)
        return null
      }
    },
  },
  Quote: {},
}

export default resolvers

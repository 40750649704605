import React from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import {
  BrandIconShared,
  CoverageInfoList,
  TranslatedTextType,
  Typography,
  useTranslate,
} from '@dg-shared'
import { TRANSLATIONS as T } from '../../../constants'
import { IconType } from '../../../types/interface/icon.interface'
import { CoverageType } from '../../../types/interface/quote.interface'
import { ThemeBrandColorsType } from 'BrandTheme/types'

const useStyles = makeStyles((theme) =>
  createStyles({
    iconStyle: {
      verticalAlign: 'sub',
      marginLeft: 5,
      cursor: 'pointer',
    },
    closeIcon: {
      color: theme.brand.colors.secondary,
      position: 'absolute',
      top: 10,
      right: 10,
      cursor: 'pointer',
      fontWeight: 100,
    },
    labelStyle: {
      fontWeight: 700,
    },
    DialogContentStyle: {
      padding: 16,
    },
  })
)

type InfoIconPropsType = {
  color?: keyof ThemeBrandColorsType
  label?: TranslatedTextType
  icon?: string
  coverageType: CoverageType
}

/**
 * Renders an informational label with an icon that shows an info popup by clicking on it
 * Informational popup text and label name is based on a translation name prefix
 * example: name = 'SuperCover', label is t('SuperCoverLabel'),
 * popupInfoText is t('SuperCoverInfoText') + t('SuperCoverInfoListText')
 *
 * @param coverageType {string} - coverage type name
 * @param icon {string} - Material UI Icon font icon name
 * @param color {string} - optional ThemeBrandColorsType color name
 * @param label {string} - optional label name override
 * @constructor
 */
const InfoIcon: React.FC<InfoIconPropsType> = ({
  coverageType,
  icon = 'info_outlined',
  color = 'textSecondary',
  label,
}) => {
  const { t } = useTranslate(T.COMMON)
  const { iconStyle, closeIcon, labelStyle, DialogContentStyle } = useStyles()
  const [open, setOpen] = React.useState(false)

  const labelText = label || t(`${coverageType}`)

  const [titleText, infoText, infoListText] = Object.values(coverageType).map((item) => t(item))

  const showPopup = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(!open)
    event.preventDefault()
  }

  const close = () => setOpen(false)

  const infoTextDetails = Array.isArray(infoListText) ? (
    <CoverageInfoList list={infoListText} />
  ) : (
    infoListText
  )

  return (
    <Typography component='span' className={labelStyle} color={color}>
      {labelText}
      <Dialog onClose={close} open={open} maxWidth='xs'>
        <DialogContent className={DialogContentStyle}>
          <BrandIconShared type={IconType.ERROR} customClass={closeIcon} onClick={close} action />
          <Typography type='h2'>{titleText}</Typography>
          <Typography>{infoText}</Typography>
          {infoTextDetails}
        </DialogContent>
      </Dialog>
      <Icon fontSize='small' onClick={showPopup} className={iconStyle}>
        {icon}
      </Icon>
    </Typography>
  )
}

export default InfoIcon

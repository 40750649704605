import { useState, useEffect } from 'react'
import { ApolloClient } from 'apollo-client'
import { isEmpty } from 'lodash'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { PolicyItem, HookDocuments, backBtn } from '../../../types/interface/claims.interface'
import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { policyListGetter } from '../../../graphql/policy/policyList.getter'
import { policyFileGetter } from '../../../graphql/policy/policyFile.getter'
import convertBase64ToBlob from '../../../util/convertBase64ToBlob'

const useLogic = (client: ApolloClient<NormalizedCacheObject>): HookDocuments => {
  const [loading, setLoading] = useState<boolean>(true)
  const [documents, setDocuments] = useState<Array<PolicyItem>>([])

  const getPolicies = async () => {
    const data = await policyListGetter(client)

    setDocuments(data)
    setLoading(false)
  }

  const convertToPdf = (fileObj: Record<string, string>): void => {
    const blob = convertBase64ToBlob(fileObj.content, 'application/pdf')
    const link = document.createElement('a')

    link.href = URL.createObjectURL(blob)
    link.title = fileObj.name
    link.target = '_blank'

    link.click()
  }

  const handleClick = async (id: number): Promise<void> => {
    const data = await policyFileGetter(client, id)

    convertToPdf(data)
    setLoading(false)
  }

  useEffect(() => {
    ;(async () => {
      await getPolicies()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const backBtn: backBtn = {
    text: `${T.COMMON}/${!isEmpty(documents) ? `back` : `logout`}`,
    path: !isEmpty(documents) ? 'TODO: DELETED CONSTANT CLEANUP: PROFILE_MENU' : '',
  }

  return {
    loading,
    backBtn,
    documents,
    handleClick,
  }
}

export default useLogic

import React, { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useApolloClient } from '@apollo/react-hooks'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Link, IconButton } from '@material-ui/core'

import history from '../../routes/history'
import { FlowType, AUTH, SIGN_IN_BANK_ID } from '../../constants'
import { SIGN_OUT } from '../../graphql/auth.query'

import { TRANSLATIONS as T } from '../../constants/translations-constants'
import { IconType } from '../../types/interface/icon.interface'
import { useTranslate } from './hooks'
import { BrandIconShared } from '@dg-shared'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customIconPosition: {
      fontSize: 'inherit',
      color: theme.palette.primary.dark,
      [theme.breakpoints.down('sm')]: {
        marginLeft: '-8px',
      },
    },
    link: {
      color: theme.palette.primary.dark,
      fontFamily: 'Gotham Black',
    },
  })
)

// We need this flow types and new property here to distinguish
// between two different scopes in one application (Q&B and FNOL).
// This goTo property basically says that if it present - use
// inner application routing. In case it's undefined - all cases
// are logout. However, because we have two scopes (Q&B and FNOL)
// in the context of reusable BtnBackV2 component we need to know
// to which page to redirect. So, in case we will specify the page
// on the layout level, we will have broken logic with signOut
// request.

export interface BtnBackV2Props {
  goTo?: string
  buttonText?: string
  customClass?: string
  flow: FlowType
}

const BtnBackV2: FC<BtnBackV2Props> = ({ flow, goTo, buttonText, customClass }) => {
  const { t } = useTranslate()
  const classes = useStyles()
  const client = useApolloClient()
  const btnText = buttonText || t(`${T.COMMON}/back`)
  const getLogoutPath = (flowType: FlowType): string => {
    switch (flowType) {
      case FlowType.qb:
        return AUTH
      case FlowType.FNOL:
        return SIGN_IN_BANK_ID
      default:
        return AUTH
    }
  }

  //TODO: This logic should not be here. BtnBack should be a dumb component.
  const handleRouting = () => {
    if (!goTo) {
      client.query({ query: SIGN_OUT, fetchPolicy: 'no-cache' }).finally(() => {
        history.push(getLogoutPath(flow))
      })
    }
  }

  const LinkSingle = (
    <Link variant='body1' className={classes.link}>
      {btnText}
    </Link>
  )

  const LinkRouter = (
    <Link component={RouterLink} variant='body1' className={classes.link} to={goTo || ''}>
      {btnText}
    </Link>
  )

  const handleClick = !goTo && handleRouting
  const link = goTo ? LinkRouter : LinkSingle

  return (
    <IconButton
      onClick={handleClick}
      aria-label={btnText.toString()}
      size={'small'}
      className={customClass}
    >
      <BrandIconShared type={IconType.CHEVRON_LEFT} customClass={classes.customIconPosition} />
      {link}
    </IconButton>
  )
}

export default BtnBackV2

import React, { FC } from 'react'
import { isArray } from 'lodash'
import { useApolloClient } from '@apollo/react-hooks'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import { LOGO_TEXT } from '../../../constants'
import { Events, MATCH_COMPOUND } from '../../../FSM/shared/constants'
import { FSMSwitch, FSMMatch } from '../../../FSM'

import { Typography, LDCardTwoCol, LMBgHeader, withHidden } from '@dg-shared'
import Loading from '../Loading/Page'
import useLogic from './useLogic'
import Fail from './Content/Fail'
import Mileage from './Content/Mileage'
import Done from './Content/Done'
import { logoMax, logoMin } from 'BrandedImages'
import { CommonPageProps } from '../../../types/interface/fsm.interface'

const useStyles = makeStyles((theme) =>
  createStyles({
    description: {
      marginBottom: 0,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  })
)

const MobileScreen = withHidden(LMBgHeader, true)
const DesktopScreen = withHidden(LDCardTwoCol, false)

const PurchasedPage: FC<CommonPageProps> = ({ ...props }) => {
  const client = useApolloClient()
  const classes = useStyles()
  const {
    service: { send },
    currentState,
  } = props

  const { loading, signOut, mileageSubmit, getText } = useLogic(client, send, currentState)
  const { title, subtitle, description } = getText()

  const dropSession = async () => signOut()

  const descriptionText =
    isArray(description) &&
    description.map((el, idx) => (
      <Typography
        size='small'
        color='textMain'
        key={`description-${idx}`}
        className={classes.description}
      >
        {el}
      </Typography>
    ))

  const body = (
    <FSMSwitch>
      <FSMMatch is={MATCH_COMPOUND.PURCHASED_MILEAGE}>
        <Mileage handleSubmit={mileageSubmit} handleSkip={() => props.service.send(Events.DONE)} />
      </FSMMatch>
      <FSMMatch is={MATCH_COMPOUND.PURCHASED_DONE}>
        <Done dropSession={dropSession} />
      </FSMMatch>
      <FSMMatch is={MATCH_COMPOUND.PURCHASED_FAIL}>
        <Fail
          handleBack={async () => {
            await dropSession()
            props.service.send(Events.BACK)
          }}
        />
      </FSMMatch>
    </FSMSwitch>
  )

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <MobileScreen
        headerTitle={title}
        headerSubTitle={subtitle}
        textLogoAlt={LOGO_TEXT}
        brandPageBackground='secondaryMobile'
        imageLogo={logoMin}
      >
        {descriptionText}
        {body}
      </MobileScreen>
      <DesktopScreen
        textLogoAlt={title}
        imageLogo={logoMax}
        brandPageBackground='secondary'
        brandPageBackgroundCard='secondaryCard'
        isReverse
      >
        <div>
          <Typography type='h1'>{title}</Typography>
          <Typography type='h3'>{subtitle}</Typography>
          {descriptionText}
        </div>
        {body}
      </DesktopScreen>
    </>
  )
}

export default PurchasedPage

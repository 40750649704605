import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core'

import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { IconType } from '../../../types/interface/icon.interface'
import { Typography, BrandIconShared, useTranslate } from '@dg-shared'

const useDetailsStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      alignItems: 'flex-start',
    },
    listItemText: {
      fontSize: '16px',
      lineHeight: '22px',
      color: theme.brand.colors.textMain,
    },
    listIcon: {
      marginTop: '4px',
      minWidth: '7px',
      color: theme.brand.colors.main,
    },
    listIconSVG: {
      transform: 'scale(0.25)',
    },
  })
)

const Details: FC = () => {
  const classes = useDetailsStyles()
  const { t } = useTranslate(`${T.OFFER_VERIFY}.details`)

  return (
    <>
      <Typography modifiers={['bold']}>{t('title')}</Typography>
      <List>
        {t(`text`, {}, 'obj')?.map((el) => (
          <ListItem className={classes.listItem} key={el}>
            <ListItemIcon className={classes.listIcon}>
              <BrandIconShared type={IconType.DOT} customClass={classes.listIconSVG} />
            </ListItemIcon>
            <ListItemText
              primary={el}
              primaryTypographyProps={{ className: classes.listItemText }}
            />
          </ListItem>
        ))}
      </List>
    </>
  )
}

export default Details

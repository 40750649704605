import React, { FC, useEffect } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

import { TRANSLATIONS as T } from '../../../../constants/translations-constants'
import { LMBasicV3FSM, useTranslate, LDContainerV2FSM, Typography } from '@dg-shared'
import { FlowServiceType } from '../../../../types/interface/fsm.interface'
import { useApolloClient } from '@apollo/react-hooks'
import { Events } from '../../../../FSM/shared/constants'
import useLogic from '../useLogic'
import { Card } from '@material-ui/core'
import CompareList from './CompareList'
import PageWrapper from '@dg-shared/PageWrapper'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(5),
      },
    },
    title: {
      marginBottom: theme.spacing(2.5),
    },
  })
)

export type PagePropsType = {
  service: FlowServiceType
}

const QBCompareListPage: FC<PagePropsType> = (props) => {
  const { service } = props
  const { t } = useTranslate(T.OFFER_COMPARE_POLICY)
  const client = useApolloClient()
  const classes = useStyles()
  const STEP_PERCENTAGE = 60
  const { insurelyResults, selectedInsurelyQuote, quote } = useLogic(client, service)

  useEffect(() => {
    if (!insurelyResults) {
      service.send(Events.INSURELY_NO_OFFERS_FOUND)
    }
    // TODO: FIx with using correct deps in [] and useCallback. Left here to not break anything
    // eslint-disable-next-line
  }, [insurelyResults])

  useEffect(() => {
    if (selectedInsurelyQuote && quote) {
      service.send(Events.NEXT)
    }
  }, [selectedInsurelyQuote, quote, service])

  const content = (
    <Card elevation={3} className={classes.container}>
      <CompareList />
    </Card>
  )

  return (
    <PageWrapper
      mobile={LMBasicV3FSM}
      desktop={LDContainerV2FSM}
      breadcrumbsBtnText={t(`${T.COMMON}/back`)}
      breadcrumbsStepPercentage={STEP_PERCENTAGE}
    >
      <Typography className={classes.title} type={'h1'}>
        {t('title')}
      </Typography>
      {content}
    </PageWrapper>
  )
}

export default QBCompareListPage

import React, { FC } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { Events } from '../../../FSM/shared/constants'
import {
  FSMBtnNext,
  ProductWrapper,
  ReCaptchaPrivacyTerms,
  TranslatedTextType,
  Typography,
  VippsBtn,
} from '@dg-shared'
import BrandConfig from 'BrandConfig'
import { FlowServiceType } from '../../../types/interface/fsm.interface'
import { PartnerName, ProductName } from '../../../types/interface/quote.interface'
import { useTranslate } from '@dg-shared/hooks'
import { TRANSLATIONS as T } from '../../../constants'

export const useAuthDecisionStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttons: {
      display: 'flex',
      flexDirection: 'column',
    },
    nextBtn: {
      width: '230px',
      height: '48px',
      margin: '0 auto',
    },
    disclaimer: {
      marginTop: 5,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
      '& a': {
        textDecoration: 'underline',
        color: 'inherit',
      },
    },
  })
)

interface AuthDecisionProps {
  textBtnAuthRegular: TranslatedTextType
  linkVipps: string
  errorVipps: boolean
  service?: FlowServiceType
}

const AuthDecision: FC<AuthDecisionProps> = ({
  textBtnAuthRegular,
  linkVipps,
  errorVipps,
  ...props
}) => {
  const classes = useAuthDecisionStyles()
  const { t } = useTranslate(T.AUTH)

  return (
    <>
      <VippsBtn url={linkVipps} disabled={errorVipps} />
      <section data-testid='next-buttons' className={classes.buttons}>
        <FSMBtnNext
          testId='button-next'
          handleClick={() => props.service.send(Events.NEXT)}
          buttonText={textBtnAuthRegular}
          customClassName={classes.nextBtn}
          outlined
        />
        <ProductWrapper products={[ProductName.VEHICLE]} partners={[PartnerName.circlek]}>
          <ReCaptchaPrivacyTerms links={BrandConfig.GOOGLE_RECAPTCHA_LINKS} />
        </ProductWrapper>
        <ProductWrapper
          products={[ProductName.VEHICLE, ProductName.TRAVEL]}
          partners={[PartnerName.instabank]}
        >
          <Typography
            component='caption'
            size='xSmall'
            color='textDisclaimer'
            modifiers={['preFormatted']}
            className={classes.disclaimer}
          >
            {t('description')}
          </Typography>
        </ProductWrapper>
      </section>
    </>
  )
}

export default AuthDecision

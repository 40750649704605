import { get, includes, isObject } from 'lodash'

import { FSMStateType } from '../types/interface/fsm.interface'
import { getProductName } from '@dg-util'

type PageStepPercentage = {
  [pageName: string]: {
    TRAVEL: number
    VEHICLE: number
  }
}

export default (page: FSMStateType): number => {
  const productType = getProductName()
  let pageName = isObject(page) ? page[Object.keys(page)[0] as keyof FSMStateType] : page
  //FIXME: Extra case route naming of state machine routing  (find, findEmpty, findCustom). Returned for Find component.
  pageName = includes(pageName, 'find') ? 'find' : pageName

  const QB_STEP_PERCENTAGE: PageStepPercentage = {
    LIST: {
      TRAVEL: 50,
      VEHICLE: 50,
    },
    FIND: {
      TRAVEL: 50,
      VEHICLE: 50,
    },
    REVIEW: {
      TRAVEL: 50,
      VEHICLE: 50,
    },
    CHANGE: {
      TRAVEL: 50,
      VEHICLE: 50,
    },
    OWNER: {
      TRAVEL: 50,
      VEHICLE: 50,
    },
    VERIFY: {
      TRAVEL: 80,
      VEHICLE: 80,
    },
  }

  return get(QB_STEP_PERCENTAGE, [
    pageName.toUpperCase() as keyof PageStepPercentage,
    productType.toUpperCase(),
  ])
}

type InitialState = {
  [key: string]: string | Record<string, unknown>
}

export const INITIAL_STATE: InitialState = {
  // Issue with nested queries in local state management:
  // https://github.com/apollographql/apollo-link-state/issues/226
  quotes: {
    __typename: 'Quotes',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    drafts: [],
    selected: {
      __typename: 'QuoteType',
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    productId: null,
    accepted: {
      __typename: 'QuoteAccepted',
    },
    comparisonItems: {
      __typename: 'CollectionItems',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      items: [],
      compareDiff: 0,
      selected: {
        __typename: 'CollectionItem',
      },
    },
  },
  claims: {
    __typename: 'Claims',
    initatedClaim: {
      __typename: 'Claim',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      flow: [],
    },
    selectedClaim: {
      __typename: 'SelectedClaim',
      licensePlateNumber: '',
      policyNumber: '',
      coverageType: '',
    },
  },
}

import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory'
import { logger /* createObjData */ } from '../logger/logger'

import CONFIG, { APP_VERSION, ENV } from '../config'
import {
  ERROR_QUOTE_CONSTRAINTS,
  ERROR_CODE_QUOTE_CONSTRAINTS,
  ERROR_CODE_REG_NUM,
  ERROR_CODE_SESSION_EXPIRED,
  ERROR_CODE_UNKNOWN_CHARACTERISTICS,
  ERROR_GENERAL,
  ERROR_TYPE as ET,
  BUILD_CONSTANTS,
  ERROR_CODE_QUOTE_VALIDATION,
} from '../constants'
import { Events } from '../FSM/shared/constants'
import { SessionDataNoBID, SessionDataBID } from '../types/interface/session.interface'
import { FlowServiceType } from '../types/interface/fsm.interface'
import resolvers from '../graphql/resolvers'
import { signOutGetter } from '../graphql/auth/signOut.getter'
import { getSessionStorageAuthData } from './util.service'
import { fullStorageCleanup, getPID, getProductName } from '../util'
import errorLoggerService from './errorLogger.service'

declare module 'graphql' {
  interface GraphQLError {
    code: number
  }
}

const initClient = (
  cache: InMemoryCache,
  flowService: FlowServiceType
): ApolloClient<NormalizedCacheObject> => {
  const link = createHttpLink({
    uri: BUILD_CONSTANTS.GRAPHQL_URL,
    credentials: ENV !== 'local' ? 'same-origin' : 'include',
  })
  const authLink = setContext((_, { headers }) => {
    const parsedUserInfo: SessionDataNoBID = getSessionStorageAuthData(CONFIG.USER_INFO_KEY)
    const parsedUserInfoBid: SessionDataBID = getSessionStorageAuthData(CONFIG.USER_INFO_KEY_BID)
    const token: string = parsedUserInfo ? parsedUserInfo.token : ''
    const bidToken: string = parsedUserInfoBid ? parsedUserInfoBid.token : ''
    const headerToken = token ? { Token: token } : {}
    const headerTokenBid = bidToken ? { BidToken: bidToken } : {}
    const basicHeaders = {
      ProductType: getPID(),
      ProductName: getProductName(),
      CID: PARTNER_NAME,
    }
    const heads = Object.assign({}, { ...headers }, basicHeaders, headerToken, headerTokenBid)

    return {
      headers: heads,
    }
  })

  // GraphQL request errors handling by error.code
  // Navigation to appropriate error page
  const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
    const { send, state } = flowService

    if (networkError) {
      logger.fatal(networkError)
      errorLoggerService.captureMessage('NetworkError', {
        state: { ...state.event, state: state.value },
        operation: { name: operation.operationName, variables: operation.variables },
      })
      send({ type: Events.ERROR, errorType: ERROR_GENERAL, state: state.value })
      return
    }

    if (graphQLErrors) {
      graphQLErrors.map(async (error) => {
        errorLoggerService.captureException(error, {
          state: { ...state.event, state: state.value },
          operation: { name: operation.operationName, variables: operation.variables },
        })

        switch (error.code) {
          case ERROR_CODE_SESSION_EXPIRED:
            send(Events.SESSION_EXPIRED)
            break
          case ERROR_CODE_UNKNOWN_CHARACTERISTICS:
            send(Events.WRONG_PRODUCT_ID)
            break
          case ERROR_CODE_REG_NUM:
            await signOutGetter(client)
            send(Events.WRONG_PRODUCT_ID)
            break
          case ERROR_CODE_QUOTE_CONSTRAINTS:
          case ERROR_CODE_QUOTE_VALIDATION:
            // Cleanup site data + session storage on purchase constraint error
            await fullStorageCleanup(client)

            send({
              type: Events.ERROR,
              errorType: ERROR_QUOTE_CONSTRAINTS,
              state: state.value,
            })
            break
          default:
            if (operation.operationName === 'AuthUser') {
              send(Events.AUTH_ERROR)
              break
            }
            //TODO: Map Error Types by code, need to refactor ET object after all errors would be handled there.
            const errorType = Object.keys(ET).find((el) => ET[el].code === error.code)

            if (!errorType) {
              send({ type: Events.ERROR, errorType: ERROR_GENERAL, state: state.value })
              break
            }
            send({ type: Events.ERROR, errorType, state: state.value })
            break
        }
      })
      return
    }
  })

  const links = ApolloLink.from([authLink, errorLink, link])

  const client = new ApolloClient({
    cache,
    link: links,
    resolvers,
    version: APP_VERSION,
  })

  return client
}

export default initClient

import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Card, CardMedia, CardContent, CardActionArea } from '@material-ui/core'

import { OfferItemProps } from '../../../types/interface/offer.interface'
import { Typography } from '@dg-shared'
import { exampleCarImg } from 'BrandedImages'

const useOffersStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: '100%',
      border: '2px solid transparent',
      [theme.breakpoints.up('md')]: {
        maxWidth: '340px',
      },
      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
    },
    button: {
      display: 'flex',
      height: '100%',
      padding: theme.spacing(2),
      justifyContent: 'start',
      flexDirection: 'column',
    },
    content: {
      width: '100%',
      padding: '0',
    },
    imageContainer: {
      marginBottom: theme.spacing(2),
    },
    image: {
      display: 'block',
    },
    row: {
      display: 'flex',
    },
    licensePlate: {
      marginLeft: theme.spacing(1),
    },
  })
)

const OfferItem: FC<OfferItemProps> = ({ vehicle, translations, handleClick }) => {
  const classes = useOffersStyles()
  const IMG_TITLE = 'Example Car'

  return (
    <Card elevation={3} className={classes.card}>
      <CardActionArea onClick={handleClick} className={classes.button}>
        <section className={classes.imageContainer}>
          <CardMedia
            component='img'
            className={classes.image}
            alt={IMG_TITLE}
            image={exampleCarImg}
            title={IMG_TITLE}
          />
        </section>

        <CardContent className={classes.content}>
          <Typography type='h2'>
            {`${vehicle.registrationYear} ${vehicle.technical?.brands} ${vehicle.technical?.modelDesignation}`}
          </Typography>

          <section className={classes.row}>
            <Typography>{translations.regNum}</Typography>
            <Typography modifiers={['bold']} className={classes.licensePlate}>
              {vehicle.licensePlate}
            </Typography>
          </section>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default OfferItem

import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { GET_POLICIES_LIST } from '../document/document.query'
import { PolicyItem } from '../../types/interface/claims.interface'

export const policyListGetter = async (
  client: ApolloClient<NormalizedCacheObject>,
  rName = 'policies'
): Promise<Array<PolicyItem>> => {
  const response = await client.query({
    query: GET_POLICIES_LIST,
    fetchPolicy: 'no-cache',
  })

  return response.data[rName].list
}

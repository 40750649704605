import { gql } from 'graphql-tag'

import { FRAGMENT_QUOTE_ERROR_GENERAL } from './fragments/QuoteErrorGeneral.fragment'

export const AUTH_BY_BANK_ID = gql`
  query AuthByBankId {
    authByBankId {
      ... on AuthLink {
        link
      }
      ... on ServiceError {
        ...ServiceErrorItems
      }
    }
  }
  ${FRAGMENT_QUOTE_ERROR_GENERAL}
`

import { FNOLClaimsList, FNOLPoliciesList } from '../../../../../../pages/FNOL'

export default [
  {
    is: { claims: 'policiesList' },
    page: FNOLPoliciesList,
  },
  {
    is: { claims: 'claimsList' },
    page: FNOLClaimsList,
  },
]

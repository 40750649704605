import { withStyles } from '@material-ui/core/styles'
import { LinearProgress } from '@material-ui/core'

const ColorLinearProgress = withStyles((theme) => ({
  root: {
    width: '100%',
    height: 10,
    borderRadius: 20,
    backgroundColor: theme.palette.info.main,
    '@media (min-width: 376px)': {
      width: '100%',
    },
  },
  bar: {
    borderRadius: 20,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress)

export default ColorLinearProgress

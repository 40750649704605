//TODO: Move to UI
// QuestionChooseCorrectClaim
export enum Type {
  windshieldDamage = 1,
  collision = 2,
  theft = 3,
  roadAssistance = 4,
  other = 5,
}

// NOT USED for now
// QuestionWhatHappened
export enum EventCollision {
  oneOrMoreCars = 1,
  animal = 2,
  smallObject = 3,
  building = 4,
  predecessorOrCyclist = 5,
  other = 5,
}

// QuestionWhatHasHappened
export enum EventOther {
  stolenCar = 1,
  stolenCarInterior = 2,
  stolenCarExterior = 3,
  other = 4,
}

// QuestionWhatHasHappenedOtherReasons
export enum EventOtherReasons {
  vandalism = 1,
  damageMechanical = 2,
  misfueling = 3,
  keyLost = 4,
  legalAid = 5,
  //TODO: We don't have Brann here... find the ticket
}

// QuestionReasonForFire
export enum FireReasons {
  leakOilOrGas = 14,
  faultElectrical = 15,
  lit = 16,
  fireOpen = 17,
  other = 3,
}

import React, { FC } from 'react'
import { useApolloClient } from '@apollo/react-hooks'

import { TRANSLATIONS as T, FNOL_FORM_ID as FORM_ID } from '../../../../../constants'
import { CommonPageProps } from '../../../../../types/interface/fsm.interface'
import { FNOLPageWrapper, Typography, useTranslate } from '@dg-shared'
import FormBuilder from '@dg-shared/forms/FormBuilder'
import useLogic from './useLogic'
import useCommonLegalAidStyles from '../commonStyles'

const Page: FC<CommonPageProps> = ({ service }) => {
  const { t } = useTranslate(`${T.FNOL_OTHER_LEGAL_AID}.amount`)
  const classes = useCommonLegalAidStyles()
  const client = useApolloClient()
  const { selectedDataFields, handleSubmit, updateForm } = useLogic(client, service.send)

  const content = (
    <section className={classes.formContainer}>
      <FormBuilder
        id={FORM_ID.COMPENSATION}
        submitHandler={handleSubmit}
        classes={classes.form}
        submitClass={classes.submitBtn}
        filledFieldsData={selectedDataFields}
        isFormControllable
        setFormState={({ values }) => updateForm(values)}
      />
    </section>
  )

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t(`${T.COMMON}/back`)} breadcrumbsStepPercentage={70}>
      <Typography type='h1'>{t('title')}</Typography>
      <Typography>{t('subtitle')}</Typography>
      {content}
    </FNOLPageWrapper>
  )
}

export default Page

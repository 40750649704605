import { get } from 'lodash'

import { QuoteType, TravelNo } from '../types/generated/graphql'
import {
  VEHICLE_COVERAGE_TYPE_MAP as VCTM,
  VEHICLE_COVERAGE_TYPE_TRANSLATION_KEYS as VCTTK,
} from '../constants'

export type CoverageType = {
  name: string
  description: string
  list: string
}

export interface CoverageMapping {
  type: CoverageType | null
  name: string | null
}

const getCoverageData = (quote?: Exclude<QuoteType, TravelNo>): CoverageMapping => {
  return {
    name: get(VCTM, [PARTNER_NAME, quote.coverageType], null),
    type: get(VCTTK, [PARTNER_NAME, quote.coverageType], null),
  }
}

export default getCoverageData

import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import { ClaimItem } from '../../types/interface/claims.interface'
import { GET_CLAIM_LIST } from './query'

export const claimListGetter = async (
  client: ApolloClient<NormalizedCacheObject>,
  rName = 'claims'
): Promise<Array<ClaimItem>> => {
  const response = await client.query({
    query: GET_CLAIM_LIST,
    fetchPolicy: 'no-cache',
  })

  return response.data[rName].list
}

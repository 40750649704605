import { FormConfigType } from '../../../../types/interface/form.interface'
import { FNOL_FORM_ID as FORM_ID } from '../../../../constants/forms-constants'

import { formatWithThousandSpace } from '../../../../service/util.service'
import { RFFCustomDatePicker, RFFCustomTextField, RFFCustomTimePicker } from '@dg-shared'
import { normalizeFormat } from '@dg-util'

const FNOLFormConfig: FormConfigType = {
  [FORM_ID.ACCIDENT]: {
    vehicle: {
      id: FORM_ID.ACCIDENT,
      fields: {
        address: {
          name: 'address',
          format: normalizeFormat,
          component: RFFCustomTextField,
          componentProps: {
            autoFocus: true,
          },
        },
        city: {
          name: 'city',
          format: normalizeFormat,
          component: RFFCustomTextField,
        },
        country: {
          name: 'country',
          format: normalizeFormat,
          component: RFFCustomTextField,
        },
        occurDate: {
          name: 'occurDate',
          component: RFFCustomDatePicker,
          componentProps: {
            fieldMaxDate: Date.now(),
          },
        },
        time: {
          name: 'occurDate',
          component: RFFCustomTimePicker,
        },
      },
      initialValues: {},
    },
  },
  [FORM_ID.COMPENSATION]: {
    vehicle: {
      id: FORM_ID.COMPENSATION,
      fields: {
        compensation: {
          name: 'compensation',
          component: RFFCustomTextField,
          parse: formatWithThousandSpace,
          componentProps: {
            inputAdornmentText: 'NOK',
          },
        },
      },
      initialValues: {},
    },
  },
  [FORM_ID.DATE]: {
    vehicle: {
      id: FORM_ID.DATE,
      fields: {
        date: {
          name: 'date',
          component: RFFCustomDatePicker,
          componentProps: {
            fieldMaxDate: Date.now(),
            fieldInputVariant: 'outlined',
          },
        },
      },
      initialValues: {},
    },
  },
  [FORM_ID.ADDITIONAL_INFO]: {
    vehicle: {
      id: FORM_ID.ADDITIONAL_INFO,
      fields: {
        additionalInfo: {
          name: 'additionalInfo',
          component: RFFCustomTextField,
          componentProps: {
            multiline: true,
            autoFocus: true,
          },
        },
      },
      initialValues: {},
    },
  },
}

export default FNOLFormConfig

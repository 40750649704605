import React, { FC } from 'react'
import { Typography as MuiTypography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { ThemeBrandTypographyType, ThemeBrandColorsType } from 'BrandTheme/types'

export type TypographyType = keyof ThemeBrandTypographyType['heading']
export type TypographySizeType = keyof ThemeBrandTypographyType['text']
export type TypographyModifierType = keyof ThemeBrandTypographyType['modifiers']
export type BrandColorType = keyof ThemeBrandColorsType

const brandTypeStyles = (type: TypographyType) =>
  makeStyles((theme: Theme) =>
    createStyles({
      textType: theme.brand.typography.heading[type],
    })
  )

const brandSizeStyles = (size: TypographySizeType) =>
  makeStyles((theme: Theme) =>
    createStyles({
      textSize: theme.brand.typography.text[size],
    })
  )

const brandColorStyles = (color: BrandColorType) =>
  makeStyles((theme: Theme) =>
    createStyles({
      textColor: {
        color: `${theme.brand.colors[color]} !important`,
      },
    })
  )

const brandModifierStyles = (modifiers: TypographyModifierType[]) =>
  makeStyles((theme: Theme) =>
    createStyles({
      modifier: Object.assign(
        {},
        ...modifiers.map((modifier) => theme.brand.typography.modifiers[modifier])
      ),
    })
  )

export type TypographyPropsType = {
  className?: string
  type?: TypographyType
  size?: TypographySizeType
  modifiers?: TypographyModifierType[]
  color?: BrandColorType
  children?: React.ReactNode
  component?: React.ElementType
  style?: React.CSSProperties
  testId?: string
  html?: boolean
  href?: string
}

const Typography: FC<TypographyPropsType> = ({
  className,
  type,
  size,
  modifiers,
  color,
  children,
  component,
  style,
  testId,
  html = false,
  href,
}) => {
  const colorClass = brandColorStyles(color)()?.textColor
  const typeClass = brandTypeStyles(type)()?.textType
  const sizeClass = brandSizeStyles(size)()?.textSize
  const modifierClass = brandModifierStyles(modifiers || [])()?.modifier
  const variant = type || 'body1'
  const extraProps: Record<string, unknown> = {}

  // Support HTML entities
  if (html) {
    extraProps.dangerouslySetInnerHTML = { __html: children }
  }

  // Support link attribute for <a href='...'>
  if (href) {
    extraProps.href = href
  }

  return (
    <MuiTypography
      data-testid={testId}
      className={[typeClass, sizeClass, modifierClass, colorClass, className].join(' ')}
      data-component='typography'
      gutterBottom
      variant={variant}
      component={component}
      style={style}
      {...extraProps}
    >
      {!html ? children : null}
    </MuiTypography>
  )
}

export default Typography

import React, { FC, useEffect, useState } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { format, add } from 'date-fns'

import { CTA_TYPE_TIMELINE } from '../../../constants/cta-constants'
import { FORMAT_DAY_MONTH } from '../../../constants'
import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { FSMTransitionsFNOLVehicleNO } from '../../../brand/instabank/FSM/fnol/vehicle/constants'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events } from '../../../FSM/shared/constants'
import TimelineItem from '../../../components/Claims/TimelineItem'
import { logoMax } from 'BrandedImages'
import {
  Typography,
  useTranslate,
  withHidden,
  LMBasicV3FSM,
  LDCardTwoCol,
  CTA,
  FSMBtnNext,
} from '@dg-shared'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardClass: {
      height: '895px',
    },
    mobileTimeline: {
      margin: '0 15px',
      marginTop: '30px',
    },
    submitBtn: {
      marginBottom: theme.spacing(3),
    },
    timelineSection: {
      position: 'relative',
    },
    timelineList: {
      marginLeft: theme.spacing(3),
      position: 'relative',
      listStyleType: 'none',
    },
    border: {
      borderLeft: `2px dashed ${theme.palette.secondary.dark}`,
      height: '80%',
      position: 'absolute',
      top: '5px',
      left: '23px',
    },
    cardHeader: {
      marginBottom: theme.spacing(4),
      marginTop: `-${theme.spacing(1)}px`,
    },
  })
)

const { FNOL_COLLISION_FLOW } = FSMTransitionsFNOLVehicleNO

const MobileScreen = withHidden(LMBasicV3FSM, true)
const DesktopScreen = withHidden(LDCardTwoCol, false)

const PageTimeline: FC<CommonPageProps> = ({ service, currentState }) => {
  const { t } = useTranslate(T.FNOL_TIMELINE)
  const [claimInitDate, setClaimInitDate] = useState<Date>(new Date())
  const classes = useStyles()
  const flow = Object.keys(currentState)[0]

  useEffect(() => {
    // TODO: Get Date from Apollo State
    setClaimInitDate(new Date())
  }, [])

  const footer = (
    <section>
      <FSMBtnNext
        customClassName={classes.submitBtn}
        buttonText={t(`${T.COMMON}/finished`)}
        handleClick={() => service.send(Events.NEXT)}
      />
      <CTA context={CTA_TYPE_TIMELINE} />
    </section>
  )

  const events = [
    {
      subHeader: format(add(claimInitDate, { days: 0 }), FORMAT_DAY_MONTH),
    },
    {
      subHeader: format(add(claimInitDate, { days: 3 }), FORMAT_DAY_MONTH),
    },
    {
      subHeader: format(add(claimInitDate, { days: 10 }), FORMAT_DAY_MONTH),
    },
    {
      subHeader: format(add(claimInitDate, { days: 15 }), FORMAT_DAY_MONTH),
    },
  ]

  const eventsList = events.map((event: { subHeader: string }, idx) => {
    const subHeaderEstimated = `${event.subHeader} ${t('estimated')}`
    const subHeader = idx > 0 ? subHeaderEstimated : event.subHeader
    const translationFlowKey = flow === FNOL_COLLISION_FLOW ? flow : 'default'

    return (
      <TimelineItem
        key={idx}
        active={idx === 0}
        subHeaderTxt={subHeader}
        contentTxt={t(`step.${translationFlowKey}.text`)[idx]}
        headerTxt={t(`step.${translationFlowKey}.header`)[idx]}
      />
    )
  })

  const timeLine = (
    <section>
      <section className={classes.timelineSection}>
        <div className={classes.border}></div>
        <ul className={classes.timelineList}>{eventsList}</ul>
      </section>
      {footer}
    </section>
  )

  return (
    <>
      <MobileScreen
        breadcrumbs
        breadcrumbsBtnText={t(`${T.COMMON}/back`)}
        send={service.send}
        titleHeader={t('title')}
        paragraphSubTitle={t('subtitle')}
      >
        {timeLine}
      </MobileScreen>
      <DesktopScreen
        imageLogo={logoMax}
        textLogoAlt={t('title')}
        cardHeight={895}
        brandPageBackground='fnolTimeline'
        brandPageBackgroundCard='fnolTimelineCard'
      >
        <Typography type='h1' className={classes.cardHeader}>
          {t('title')}
        </Typography>
        {timeLine}
      </DesktopScreen>
    </>
  )
}

export default PageTimeline

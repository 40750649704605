import { FSMCommonTransitions } from '../../../../../FSM/shared/constants'

const FSMTransitionsVehicleSE = {
  ...FSMCommonTransitions,
  FLOW_MACHINE_ID: 'QBFlowMachineVehicleSE',
  OWNER: 'owner',
  OWNERSHIP: 'ownership',
  HISTORY: 'history',
  PURCHASED_MILEAGE: 'mileage',
  INSURELY: 'insurely',
  INSURELY_COMPARE_INIT: 'init',
  INSURELY_COMPARE: 'compare',
  INSURELY_COMPARARISON_LIST: 'comparisonList',
}

export { FSMTransitionsVehicleSE }

import React, { FC, useState, useEffect } from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import { Grid, Typography } from '@material-ui/core'
import { isEmpty } from 'lodash'

import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { CTA_TYPE_PROFILE } from '../../../constants/cta-constants'
import { Events } from '../../../FSM/shared/constants'
import { ClaimItem } from '../../../types/interface/claims.interface'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { claimListGetter } from '../../../graphql/claim/initList.getter'
import { setClaimSelectedMutator } from '../../../graphql/claim/local-setClaimSelected.mutator'
import Loading from '../../qb/Loading/Page'
import PolicyCard from '../../../components/Claims/PolicyCard'
import { useTranslate, NoDataCard, FNOLPageWrapper } from '@dg-shared'

const PageList: FC<CommonPageProps> = ({ service }) => {
  const { t } = useTranslate(T.CLAIMS)
  const client = useApolloClient()
  const [loading, setLoading] = useState<boolean>(true)
  const [policyList, setPolicyList] = useState<Array<ClaimItem>>([])

  const title = t('title')
  // We don't display subtitle if there's no policy items
  const subtitle = !isEmpty(policyList) && t('subtitle')

  const getPolicies = async () => {
    const dataPoliciesList = await claimListGetter(client)

    setPolicyList(dataPoliciesList)
    setLoading(false)
  }

  useEffect(() => {
    ;(async () => {
      await getPolicies()
    })()
    // TODO: FIx with using correct deps in [] and useCallback. Left here to not break anything
    // eslint-disable-next-line
  }, [])

  const handleClaimCreation = async (
    licensePlateNumber: string,
    policyNumber: string,
    coverageType: number
  ) => {
    await setClaimSelectedMutator(client, licensePlateNumber, policyNumber, coverageType)

    service.send({ type: Events.NEXT, policyId: policyNumber })
  }

  const body = !isEmpty(policyList) ? (
    <Grid container spacing={3} justifyContent={'center'} data-testid={'policy-list'}>
      {policyList.map((policy: ClaimItem, idx: number) => (
        <Grid key={idx} item xs={12} md={4}>
          <PolicyCard
            data={policy}
            handleClick={async () => {
              await handleClaimCreation(policy.licensePlateNumber, policy.id, policy.coverageType)
            }}
          />
        </Grid>
      ))}
    </Grid>
  ) : (
    <NoDataCard send={service.send} type={CTA_TYPE_PROFILE} />
  )

  if (loading) {
    return <Loading />
  }

  return (
    <FNOLPageWrapper
      breadcrumbsBtnText={t(`${T.COMMON}/back`, {}, 'string')}
      breadcrumbsStepPercentage={10}
    >
      <Typography variant={'h1'} align={'center'}>
        {title}
      </Typography>
      {subtitle && <Typography>{subtitle}</Typography>}
      {body}
    </FNOLPageWrapper>
  )
}

export default PageList

import React, { FC, useState, useEffect } from 'react'
import { includes, has } from 'lodash'

import { Events, FSMCommonTransitions as CT } from '../../FSM/shared/constants'
import { FSMRouterProvider } from '../FSMContext'
import { getPersistedState, removePersistedState, persistState, setClientHistory } from '../util'
import { FlowMachineType, FlowServiceType, FSMStateType } from '../../types/interface/fsm.interface'

interface FSMProviderProps {
  service: FlowServiceType
  machine: FlowMachineType
}

const FSMProvider: FC<FSMProviderProps> = ({ children, service, machine }) => {
  const [state, setState] = useState<FSMStateType>()
  const persistedState = getPersistedState(machine)

  useEffect(() => {
    window.onpopstate = () => service.send(Events.BACK)
    // FYI: The line of code above doesn't work for Vipps flow.
    // Therefore, we add one more handler only for ExtraOffers(after Vipps auth) flow
    // which would change xState state value to the previous one in case user clicks browser's BACK button.
    window.addEventListener('unload', () => {
      has(service.state.value, 'extraOffers') && service.send(Events.BACK)
    })

    service
      .onTransition(async (state: FSMStateType) => {
        setClientHistory(state.value)

        // Looks like we will match the start state of each machine just
        // to not save the very first state.
        if (!state.matches({ auth: CT.AUTH_CHOICE })) {
          await persistState(state)
        }

        //FIXME: Need to refactor this function after release
        // Actually it is the prev state only in case of going back from integrations
        // (Avtalegiry site or so). However we need to simplify the logic here.
        if (state.matches({ auth: CT.AUTH_CHOICE }) && !includes(state.event.type, 'init')) {
          removePersistedState()
          const prevState = getPersistedState(machine)
          return setState(prevState)
        }

        //On state we have matches logic which we can use in Switch
        setState(state)
      })
      .start(persistedState)

    return () => {
      service.stop()
    }
    // TODO: FIx with using correct deps in [] and useCallback. Left here to not break anything
    // eslint-disable-next-line
  }, [])

  return (
    <FSMRouterProvider
      value={{
        currentState: state,
        service: service,
      }}
    >
      {children}
    </FSMRouterProvider>
  )
}

export default FSMProvider

import { Events } from '../../../../../../FSM/shared/constants'
import { FSMTransitionsFNOLVehicleSE } from '../constants'

const {
  FLOW_MACHINE_ID,
  AUTH_BANK_ID,
  FNOL_CLAIMS,
  FNOL_CLAIMS_LIST,
  FNOL_ROADSIDE_ASSISTANCE_FLOW,
  FNOL_ROADSIDE_ASSISTANCE_START,
} = FSMTransitionsFNOLVehicleSE

export default {
  [FNOL_ROADSIDE_ASSISTANCE_FLOW]: {
    initial: FNOL_ROADSIDE_ASSISTANCE_START,
    states: {
      [FNOL_ROADSIDE_ASSISTANCE_START]: {
        on: {
          [Events.BACK]: { target: `#${FLOW_MACHINE_ID}.${FNOL_CLAIMS}.${FNOL_CLAIMS_LIST}` },
          [Events.SESSION_EXPIRED]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
    },
  },
}

import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Grid, Card } from '@material-ui/core'

import { StylesProps } from '../../../types/interface/styles.interface'
import { TranslatedTextType, Typography } from '@dg-shared'
import { ThemeBrandPageBackgroundNameType } from 'BrandTheme/types'

export const useLDCardTwoColStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      container: (props?: StylesProps) => ({
        alignContent: 'center',
        height: '100vh',
        justifyContent: 'center',
        overflowY: 'auto',
        [theme.breakpoints.up('md')]: {
          minWidth: '750px',
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
          ...theme.brand.assets.pageBackgrounds[props.brandPageBackground],
        },
      }),
      card: (props?: StylesProps) => ({
        display: 'flex',
        width: '100%', // 780 | 900
        height: `${props.cardHeight}px`, // 616 | 620
        [theme.breakpoints.up('md')]: {
          maxWidth: '920px',
        },
      }),
      cardColLeft: (props?: StylesProps) => ({
        flex: 1,
        display: 'flex',
        ...theme.brand.assets.pageBackgrounds[props.brandPageBackgroundCard],
        width: '100%',
        padding: `0 ${theme.spacing(4)}px`,
        justifyContent: 'center',
      }),
      cardColRight: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        maxWidth: '52%',
        padding: theme.spacing(2),
        overflowY: 'auto',
        [theme.breakpoints.up('sm')]: {
          paddingTop: theme.spacing(5),
          paddingBottom: theme.spacing(5),
          paddingLeft: theme.spacing(8),
          paddingRight: theme.spacing(8),
        },
      },
      logoWrapper: {
        display: 'flex',
        width: '100%',
      },
      logoImage: {
        alignSelf: 'center',
        maxHeight: '69px',
        margin: 'auto',
        maxWidth: '100%',
      },
      textFooter: {
        textAlign: 'center',
        padding: 0,
        marginTop: theme.spacing(1),
        marginBottom: 0,
      },
    }),
  // https://stackoverflow.com/questions/59559632/material-ui-have-different-style-result-in-production-mode
  { index: 1 }
)

export interface LDCardTwoColProps {
  textLogoAlt: TranslatedTextType
  textFooter?: TranslatedTextType
  brandPageBackground: ThemeBrandPageBackgroundNameType
  brandPageBackgroundCard?: ThemeBrandPageBackgroundNameType
  cardHeight?: number
  imageLogo: string
  isReverse?: boolean
}

const LDCardTwoCol: FC<LDCardTwoColProps> = ({
  textLogoAlt,
  textFooter,
  imageLogo,
  brandPageBackground,
  brandPageBackgroundCard,
  isReverse,
  cardHeight = 620,
  children,
}) => {
  const classes = useLDCardTwoColStyles({
    brandPageBackgroundCard,
    brandPageBackground,
    leftColGradientReverse: isReverse,
    cardHeight,
  })

  return (
    <Grid container direction='row' component='article' className={classes.container}>
      <Card className={classes.card}>
        <section data-testid='desktop-card-left' className={classes.cardColLeft}>
          <div className={classes.logoWrapper}>
            <img className={classes.logoImage} src={imageLogo} alt={`${textLogoAlt} Logo`} />
          </div>
        </section>
        <section className={classes.cardColRight}>
          {children}
          {!!textFooter && (
            <Typography size='small' color='textDisclaimer' className={classes.textFooter}>
              {textFooter}
            </Typography>
          )}
        </section>
      </Card>
    </Grid>
  )
}

export default LDCardTwoCol

import { get, has } from 'lodash'

import guards from './guards'
import { FSMTransitionsVehicleSE as TV } from './constants'
import { mergeDeep } from '../../../../../service/util.service'
import { Events } from '../../../../../FSM/shared/constants'
import actions from '../../../../../FSM/shared/actions'
import sharedFSMconfig, { MachineConfigType } from '../../../../../FSM/shared/config'

const FSMConfig: MachineConfigType = {
  id: TV.FLOW_MACHINE_ID,
  initial: TV.AUTH,
  context: {
    authorized: false,
  },
  states: {
    [TV.AUTH]: {
      initial: TV.AUTH_REGULAR,
      states: {
        [TV.AUTH_REGULAR]: {
          on: {
            [Events.NEXT]: `#${TV.FLOW_MACHINE_ID}.${TV.OWNERSHIP}`,
            [Events.AUTH_ERROR]: TV.AUTH_REGULAR,
          },
          exit: 'setUserAuthorized',
        },
      },
    },
    [TV.OWNERSHIP]: {
      initial: TV.REVIEW,
      states: {
        [TV.REVIEW]: {
          on: {
            [Events.BACK]: { target: `#${TV.FLOW_MACHINE_ID}.${TV.AUTH}`, actions: 'signOut' },
            [Events.NEXT]: `#${TV.FLOW_MACHINE_ID}.${TV.VERIFY}`,
            [Events.CHANGE]: `#${TV.FLOW_MACHINE_ID}.${TV.CHANGE}`,
            [Events.WRONG_PRODUCT_ID]: `#${TV.FLOW_MACHINE_ID}.${TV.AUTH}`,
            [Events.START_COMPARE]: `#${TV.FLOW_MACHINE_ID}.${TV.INSURELY}`,
            [Events.SESSION_EXPIRED]: `#${TV.FLOW_MACHINE_ID}.${TV.AUTH}`,
          },
        },
        [TV.HISTORY]: {
          type: 'history',
        },
      },
    },
    [TV.CHANGE]: {
      on: {
        [Events.BACK]: TV.OWNERSHIP,
        [Events.NEXT]: TV.OWNERSHIP,
        [Events.SESSION_EXPIRED]: `#${TV.FLOW_MACHINE_ID}.${TV.AUTH}`,
      },
    },
    [TV.VERIFY]: {
      on: {
        [Events.BACK]: `${TV.OWNERSHIP}.${TV.HISTORY}`,
        [Events.NEXT]: TV.PURCHASED,
        [Events.SESSION_EXPIRED]: `#${TV.FLOW_MACHINE_ID}.${TV.AUTH}`,
      },
    },
    [TV.PURCHASED]: {
      initial: TV.PURCHASED_DONE,
      states: {
        [TV.PURCHASED_DONE]: {
          type: 'final',
          on: {
            [Events.BACK]: `#${TV.FLOW_MACHINE_ID}.${TV.AUTH}`,
          },
        },
        [TV.HISTORY]: {
          type: 'history',
        },
      },
    },
    [TV.INSURELY]: {
      initial: TV.INSURELY_COMPARE_INIT,
      states: {
        [TV.INSURELY_COMPARE_INIT]: {
          on: {
            [Events.BACK]: `#${TV.FLOW_MACHINE_ID}.${TV.OWNERSHIP}`,
            [Events.COMPARE]: TV.INSURELY_COMPARE,
            [Events.COMPARE_LIST]: TV.INSURELY_COMPARARISON_LIST,
            [Events.INSURELY_NO_OFFERS_FOUND]: `#${TV.FLOW_MACHINE_ID}.${TV.OWNERSHIP}`,
            [Events.SESSION_EXPIRED]: `#${TV.FLOW_MACHINE_ID}.${TV.AUTH}`,
          },
        },
        [TV.INSURELY_COMPARARISON_LIST]: {
          on: {
            [Events.NEXT]: TV.INSURELY_COMPARE,
            [Events.BACK]: `#${TV.FLOW_MACHINE_ID}.${TV.OWNERSHIP}.${TV.HISTORY}`,
            [Events.INSURELY_NO_OFFERS_FOUND]: `#${TV.FLOW_MACHINE_ID}.${TV.OWNERSHIP}`,
            [Events.SESSION_EXPIRED]: `#${TV.FLOW_MACHINE_ID}.${TV.AUTH}`,
          },
        },
        [TV.INSURELY_COMPARE]: {
          on: {
            [Events.NEXT]: `#${TV.FLOW_MACHINE_ID}.${TV.VERIFY}`,
            [Events.BACK]: `#${TV.FLOW_MACHINE_ID}.${TV.OWNERSHIP}.${TV.HISTORY}`,
            [Events.INSURELY_NO_OFFERS_FOUND]: `#${TV.FLOW_MACHINE_ID}.${TV.OWNERSHIP}`,
            [Events.SESSION_EXPIRED]: `#${TV.FLOW_MACHINE_ID}.${TV.AUTH}`,
          },
        },
      },
    },
    [TV.ERROR]: {
      on: {
        [Events.BACK]: [
          {
            target: TV.AUTH,
            cond: (ctx) =>
              has(ctx.backPath, TV.OWNERSHIP) && get(ctx.backPath, TV.OWNERSHIP) === TV.REVIEW,
          },
          {
            target: `${TV.OWNERSHIP}.${TV.HISTORY}`,
            cond: (ctx) => has(ctx.backPath, TV.OWNERSHIP),
          },
          {
            target: `${TV.PURCHASED}.${TV.HISTORY}`,
            cond: (ctx) => has(ctx.backPath, TV.PURCHASED),
          },
          {
            target: TV.INSURELY,
            cond: (ctx) => has(ctx.backPath, TV.INSURELY),
          },
        ],
      },
    },
  },
}

const FSMConfigVehicleSE = mergeDeep(FSMConfig, sharedFSMconfig)
const FSMOptionsVehicleSE = { guards, actions }

export { FSMConfigVehicleSE, FSMOptionsVehicleSE }

import React, { FC, useEffect } from 'react'
import { get } from 'lodash'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import BrandConfig from 'BrandConfig'
import { TRANSLATIONS as T } from '../../../../constants'
import { CTA_TYPE_PURCHASED_DONE } from '../../../../constants/cta-constants'
import { FSMBtnNext, CTA, Typography, useTranslate } from '@dg-shared'
import { getProductName } from '@dg-util'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    annotation: {
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(3),
      },
    },
    footer: {
      justifyContent: 'center',
      margin: '0 !important',
    },
  })
)

interface DoneProps {
  dropSession(): void
}

const Done: FC<DoneProps> = ({ dropSession }) => {
  const { t } = useTranslate(T.OFFER_PURCHASED)
  const classes = useStyles()

  const link = get(BrandConfig.MAIN_LINK, getProductName())

  useEffect(() => {
    dropSession()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Typography className={classes.annotation}>{t('done.annotation')}</Typography>
      <CTA context={CTA_TYPE_PURCHASED_DONE} containerClass={classes.footer} />
      <FSMBtnNext
        buttonText={t(`${T.COMMON}/finished`)}
        handleClick={() => (window.location.href = link)}
      />
    </>
  )
}

export default Done

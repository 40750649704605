import { pick } from 'lodash'

import { UserAuthInput, UserAuthInputNo, UserAuthInputSe } from '../../types/generated/graphql'
import { PartnerName } from '../../types/interface/quote.interface'

export default (authFormData: UserAuthInputNo | UserAuthInputSe): UserAuthInput => {
  switch (PARTNER_NAME) {
    case PartnerName.instabank:
      return { paramsNO: { ...(authFormData as UserAuthInputNo) } }
    case PartnerName.circlek:
      return {
        paramsSE: { ...pick(authFormData as UserAuthInputSe, ['ssn']) },
      }
    default:
      throw new Error('Partner name is not defined')
  }
}

import React, { FC } from 'react'

import { TRANSLATIONS as T } from '../../../../constants/translations-constants'
import { VehicleSe } from '../../../../types/generated/graphql'
import { Typography, useTranslate } from '@dg-shared'
import useContentStyles from './useContentStyles'

interface ContentProps {
  offer: VehicleSe
}

const Content: FC<ContentProps> = ({
  offer: { priceMonthly, carBrand, productId, carModel, carYear },
}) => {
  const { t } = useTranslate(`${T.OFFER_VERIFY}.card`)
  const classes = useContentStyles()

  return (
    <>
      <Typography type='h3'>{t(`${T.COMMON}:car`)}</Typography>
      <Typography className={classes.textDetails}>
        {`${carYear} ${carBrand} ${carModel}`}
      </Typography>
      <Typography className={classes.textDetails}>
        {t(`${T.COMMON}:registrationNum`, { regNum: productId })}
      </Typography>
      <section className={`${classes.row} ${classes.spaceTop}`}>
        <Typography type='h3'>{t(`${T.COMMON}/total`)}</Typography>
        <section className={classes.priceRow}>
          <Typography type={'h3'}>{priceMonthly}</Typography>
          <Typography className={classes.currency}>{t('priceMonth')}</Typography>
        </section>
      </section>
    </>
  )
}

export default Content

import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Grid, LinearProgress } from '@material-ui/core'

import { IHeaderSharedProps } from '../../types/interface/IHeaderSharedProps'
import Typography from './typography/Typography'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainerMain: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      height: '50px',
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
      },
    },
    gridItemLeft: {
      display: 'flex',
    },
    gridItemRight: {
      width: theme.spacing(15),
    },
    gridItemPositionProgressbarWrapper: {
      paddingTop: '0.5rem',
      display: 'flex',
      justifyContent: 'center',
    },
    progressValue: {
      lineHeight: 1,
    },
    barColorPrimary: {
      backgroundColor: theme.palette.info.main,
      borderRadius: theme.spacing(11),
      '& > *': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  })
)

const HeaderShared: FC<IHeaderSharedProps> = ({ renderBackButton, stepsPercentage }) => {
  const classes = useStyles()
  const progressBlock = stepsPercentage && (
    <div className={classes.gridItemRight}>
      <div className={classes.gridItemPositionProgressbarWrapper}>
        <Typography className={classes.progressValue}>{`${stepsPercentage}%`}</Typography>
      </div>
      <LinearProgress
        variant='determinate'
        value={stepsPercentage}
        className={classes.barColorPrimary}
      />
    </div>
  )

  return (
    <Grid container className={classes.gridContainerMain}>
      <Grid item xs={4} sm={1} className={classes.gridItemLeft}>
        {renderBackButton()}
      </Grid>
      {progressBlock}
    </Grid>
  )
}

export default HeaderShared

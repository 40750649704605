import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import { StylesProps } from '../../../types/interface/styles.interface'
import { TranslatedTextType, Typography } from '@dg-shared'
import { ThemeBrandPageBackgroundNameType } from 'BrandTheme/types'

export const useLMBgHeaderStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      container: (props: StylesProps) => ({
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        height: `${props.height}px`,
      }),
      page: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
          flex: 1,
          paddingTop: theme.spacing(1),
        },
      },
      content: {
        display: 'flex',
        flexDirection: 'column',
      },
      header: (props: StylesProps) => ({
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 0,
        padding: theme.spacing(2),
        height: '285px', //TODO: Do this with a condition
        ...theme.brand.assets.pageBackgrounds[props.brandPageBackground],
        borderRadius: '0 0 30% 0',
        [theme.breakpoints.down('md')]: {
          height: '200px',
        },
      }),
      logoWrapper: {
        display: 'flex',
        flex: 0,
        marginBottom: theme.spacing(2),
      },
      logoImage: (props: StylesProps) => ({
        alignSelf: 'start',
        height: props.logoImageHeight ? props.logoImageHeight : '28px',
      }),
      headerTitle: {
        marginBottom: theme.spacing(2),
        whiteSpace: 'pre-line',
        lineHeight: '1.3',
      },
      textFooter: {
        textAlign: 'center',
        padding: `${theme.spacing(1)}px 0 0`,
        marginBottom: '0',
      },
    }),
  { index: 1 }
)

export type LMBgHeaderProps = {
  headerTitle?: TranslatedTextType
  headerClass?: string
  headerSubTitle?: TranslatedTextType
  textLogoAlt: TranslatedTextType
  textFooter?: TranslatedTextType
  headerContainerClass?: string
  containerClass?: string
  pageClass?: string
  imageGradient?: boolean
  imageLogo: string
  brandPageBackground: ThemeBrandPageBackgroundNameType
}

const LMBgHeader: FC<LMBgHeaderProps> = ({
  headerTitle,
  headerSubTitle,
  headerClass = '',
  textFooter,
  headerContainerClass = '',
  containerClass = '',
  pageClass = '',
  textLogoAlt,
  imageLogo,
  children,
  imageGradient,
  brandPageBackground,
}) => {
  const classes = useLMBgHeaderStyles({
    brandPageBackground,
    leftColGradientReverse: imageGradient,
    height: window.innerHeight,
  })

  return (
    <Grid container className={`${classes.container} ${containerClass}`}>
      <div data-testid='mobile-header' className={`${classes.header} ${headerContainerClass}`}>
        <div className={classes.logoWrapper}>
          <img className={classes.logoImage} src={imageLogo} alt={`${textLogoAlt} Logo`} />
        </div>
        {!!headerTitle && (
          <Typography type='h1' className={`${classes.headerTitle} ${headerClass}`}>
            {headerTitle}
          </Typography>
        )}
        {!!headerSubTitle && <Typography type='h3'>{headerSubTitle}</Typography>}
      </div>
      <Grid item xs={12} className={`${classes.page} ${pageClass}`}>
        {children}
        {!!textFooter && (
          <Typography size='small' className={classes.textFooter} color='textDisclaimer'>
            {textFooter}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

export default LMBgHeader

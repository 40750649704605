import { isNil } from 'lodash'
import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { ERROR_CODE_QUOTE_GENERAL } from '../../constants/error-constants'
import { ErrorCode } from '../../types/interface/error.interface'
import { QuoteAccepted } from '../../types/interface/quote.interface'
import { SET_QUOTE_ACCEPTED_LOCAL } from '../quote/mutation'

export const acceptMutatorLocal = async (
  client: ApolloClient<NormalizedCacheObject>,
  quoteAccepted: QuoteAccepted
): Promise<[true | null, ErrorCode | null]> => {
  const response = await client.mutate({
    mutation: SET_QUOTE_ACCEPTED_LOCAL,
    variables: { quoteAccepted },
  })

  if (!isNil(response.errors)) return [null, ERROR_CODE_QUOTE_GENERAL]

  return [true, null]
}

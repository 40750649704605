import { useState, useEffect } from 'react'
import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import { initiatedClaimGetterLocal } from '../../../../../graphql/claim/local-selected.getter'
import { FORMAT_YEAR_MONTH_DAY_HYPHEN, QUESTIONS } from '../../../../../constants'
import { format } from 'date-fns'
import { updateMutator } from '../../../../../graphql/claim/local-update.mutator'
import { Events } from '../../../../../FSM/shared/constants'
import { ServiceSend } from '../../../../../types/interface/fsm.interface'

export interface DateFormValues {
  date: Date
}

interface LegalAidHook {
  selectedDataFields: DateFormValues
  handleSubmit(values: DateFormValues): Promise<void>
  updateForm(values: DateFormValues): void
}

const useLogic = (client: ApolloClient<NormalizedCacheObject>, send: ServiceSend): LegalAidHook => {
  const [selectedDataFields, setSelectedDataFields] = useState<DateFormValues | null>(null)

  const getClaimLocal = async (client: ApolloClient<NormalizedCacheObject>) => {
    return await initiatedClaimGetterLocal(client)
  }

  useEffect(() => {
    getClaimLocal(client).then((res) => {
      setSelectedDataFields({ date: new Date(res[QUESTIONS.WHEN_DID_DISPUTE_OCCURED]) })
    })
  }, [client])

  const handleSubmit = async (values: { date: Date }) => {
    const reqBody = {
      [QUESTIONS.WHEN_DID_DISPUTE_OCCURED]: format(
        new Date(values.date),
        FORMAT_YEAR_MONTH_DAY_HYPHEN
      ),
    }

    await updateMutator(client, reqBody)

    send(Events.NEXT)
  }

  return {
    selectedDataFields,
    handleSubmit,
    updateForm: setSelectedDataFields,
  }
}

export default useLogic

import React, { FC, useRef } from 'react'
import { Field } from 'react-final-form'
import { Hidden } from '@material-ui/core'
import { useApolloClient } from '@apollo/react-hooks'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { BUILD_CONSTANTS as BC, FORM_ID, TRANSLATIONS as T } from '../../../constants'
import { PartnerName, ProductName } from '../../../types/interface/quote.interface'
import { QuoteType } from '../../../types/generated/graphql'
import useLogic from './useLogic'
import { getStepPercentage } from '@dg-util'
import {
  AvtalegiroForm,
  ExternalSubmitBtn,
  LDCardTwoColV2FSM,
  LMBasicV3FSM,
  ProductWrapper,
  RFFCustomCheckbox,
  useTranslate,
  withHidden,
} from '@dg-shared'
import FormBuilder from '@dg-shared/forms/FormBuilder'
import Loading from '../Loading/Page'
import VerifyCard from './Card'
import OfferDetails from './Details'
import { CommonPageProps } from '../../../types/interface/fsm.interface'

const MobileScreen = withHidden(LMBasicV3FSM, true)
const DesktopScreen = withHidden(LDCardTwoColV2FSM, false)

const useStyles = makeStyles(() =>
  createStyles({
    checkbox: {
      '& .MuiButtonBase-root': {
        padding: '9px 9px 9px 0',
      },
    },
  })
)

const VerifyPage: FC<CommonPageProps> = ({ service, currentState }) => {
  const { t } = useTranslate(T.OFFER_VERIFY)
  const client = useApolloClient()
  const paymentRef = useRef<HTMLFormElement | null>(null)
  const stepPercentage = getStepPercentage(currentState)
  const { btnDisabled, loading, formData, KID } = useLogic(client, service.send)
  const quoteData = !!formData && formData.quote

  const styles = useStyles()

  const formPayment = quoteData && (
    <AvtalegiroForm
      actionUrl={`${BC.AVTALEGIRO_URL}/ecsa/start`}
      componentRef={paymentRef}
      companyName={BC.PARTNER_NAME}
      companyAccountNumber={BC.ACCOUNT_NUMBER}
      data={quoteData}
      kid={KID as string}
      returnUrl={`${BC.REST_URL}/payment`}
      notificationDisabled='false'
    />
  )

  //TODO: Work on this structure later.
  const offerForm = !!formData && (
    <>
      <FormBuilder
        id={FORM_ID.VERIFY}
        filledFieldsData={formData.formValue}
        handleBtnDisabled={formData.handleBtnDisabled}
        initialValues={{ policyDate: formData.date.selected }}
        setFormState={formData.changeHandler}
        validationArgs={{ ...formData.date }}
        submitHandler={(values) => formData.handleSubmit(paymentRef, values)}
        externalDependency={{ insuranceCompany: formData.quote.displayPrevCompanyField }}
        isFormControllable
        customSubmit
      >
        <Hidden mdUp>
          <VerifyCard offer={formData.quote} />
        </Hidden>

        <OfferDetails />
        <Field
          name='agreementConfirm'
          type='agreementConfirm'
          fieldLabel={t(`form.agreementConfirm.label`)}
        >
          {(props) => <RFFCustomCheckbox {...props} className={styles.checkbox} />}
        </Field>
        <ProductWrapper products={[ProductName.VEHICLE]} partners={[PartnerName.circlek]}>
          <Field
            name='consentSharing'
            type='consentSharing'
            fieldLabel={t(`form.consentSharing.label`)}
          >
            {(props) => <RFFCustomCheckbox {...props} className={styles.checkbox} />}
          </Field>
        </ProductWrapper>
      </FormBuilder>
      {formPayment}
    </>
  )

  const externalSubmitBtn = (
    <ExternalSubmitBtn text={t('submit')} targetId={FORM_ID.VERIFY} disabled={btnDisabled} />
  )

  const cardOffer = !!quoteData && <VerifyCard offer={quoteData as QuoteType} />

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <MobileScreen
        send={service.send}
        breadcrumbs
        breadcrumbsBtnText={t(`${T.COMMON}/back`)}
        titleHeader={t('title')}
        breadcrumbsStepPercentage={stepPercentage}
      >
        {offerForm}
        {externalSubmitBtn}
      </MobileScreen>

      <DesktopScreen
        send={service.send}
        colLeft={offerForm}
        colRight={cardOffer}
        title={t('title')}
        breadcrumbsStepPercentage={stepPercentage}
        nextBtn={externalSubmitBtn}
        breadcrumbsBtnText={t(`${T.COMMON}/back`)}
        breadcrumbs
      />
    </>
  )
}

export default VerifyPage

export type InsurelyInsuranceType = 'vehicleInsurance' | string

export type InsurelyResultType = {
  insurance: {
    insuranceObjectStreetAddress: string
    insuranceObjectPostalCode: string
    insuranceObjectCity: string
    securityDoorGridDiscount: boolean
    connectedAlarmDiscount: boolean
    livingArea: number
    numberOfResidents: number
    insuredMovablesAmount: number
    externalId: string
    insuranceName: string
    insuranceCompany: string
    insuranceType: string
    insuranceSubType: string
    insuranceNumber: string
    premiumAmountYearRounded: number
    discountAmount: number
    discountPercentage: string
    renewalDate: string
    startDate: string
    coming: boolean
    premiumFrequency: number
    paymentMethod: string
    registrationNo: InsurelyInsuranceType
    employerPaid: boolean
    otherInsuranceHolder: boolean
    insuranceHolderName: string
    insuranceHolderStreetAddress: string
    insuranceHolderPostalCode: string
    insuranceHolderCity: string
  }
  deductibles: {
    deductibleName: string
    deductibleId: string
    deductibleAmount: number
  }[]
  addons: []
  parameters: []
  personalInformation: {
    PERSONAL_NUMBER: number
  }
}

export enum InsurelyEventType {
  APP_INITIALIZED = 'APP_INITIALIZED',
  APP_LOADED = 'APP_LOADED',
  APP_HEIGHT = 'APP_HEIGHT',
  APP_CENTER = 'APP_CENTER',
  PAGE_VIEW = 'PAGE_VIEW',
  APP_CLOSE = 'APP_CLOSE',
  COLLECTION_ID = 'COLLECTION_ID',
  RESULTS = 'RESULTS',
  COLLECTION_STATUS = 'COLLECTION_STATUS',
}

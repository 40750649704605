import { isNil } from 'lodash'
import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { ErrorCode } from '../../types/interface/error.interface'
import { SAVE_INITIATED_CLAIM } from './mutation'

export const initMutator = async (
  client: ApolloClient<NormalizedCacheObject>,
  policyNumber: string,
  licensePlateNumber: string,
  questions: Record<string, unknown>,
  rName = 'initClaim'
): Promise<[Record<string, unknown> | null, ErrorCode | null]> => {
  const response = await client.mutate({
    mutation: SAVE_INITIATED_CLAIM,
    variables: {
      data: {
        policyNumber,
        licensePlateNumber,
        questions,
      },
    },
  })

  if (!isNil(response.errors) || (!isNil(response.data) && response.data[rName].error)) {
    return [null, response.data[rName].code]
  }

  return [response.data[rName], null]
}

import { FSMCommonTransitions } from './constants'
import { StateRoute } from '../../types/interface/fsm.interface'
import AuthStrategy from '../../pages/qb/Auth/AuthStrategy'
import { QBChange, QBPurchased, QBVerify } from '../../pages/qb'
import { ErrorPage, StandardsPage } from '@dg-shared'
import { IS_PRODUCTION, IS_ACCEPTANCE } from '../../config'

export const FSMCommonStatePaths: Array<StateRoute> = [
  {
    is: FSMCommonTransitions.AUTH,
    page: AuthStrategy,
  },
  {
    is: FSMCommonTransitions.CHANGE,
    page: QBChange,
  },
  {
    is: FSMCommonTransitions.VERIFY,
    page: QBVerify,
  },
  {
    is: FSMCommonTransitions.PURCHASED,
    page: QBPurchased,
  },
  {
    is: FSMCommonTransitions.ERROR,
    page: ErrorPage,
  },
]

// FSM state paths that available only on DEV and LOCAL environments
if (!IS_PRODUCTION && !IS_ACCEPTANCE) {
  // UI Standards path
  FSMCommonStatePaths.push({
    is: FSMCommonTransitions.STANDARDS,
    page: StandardsPage,
  })
}

import { get } from 'lodash'
import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { AUTH_BY_BANK_ID } from '../bankId'

export const authByBankId = async (
  client: ApolloClient<NormalizedCacheObject>,
  rName = ['authByBankId', 'link']
): Promise<string> => {
  const response = await client.query({
    query: AUTH_BY_BANK_ID,
    fetchPolicy: 'no-cache',
  })

  return get(response, ['data', ...rName])
}

import { useEffect, useState } from 'react'
// eslint-disable-next-line import/no-named-as-default
import ApolloClient from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import { get, isNil, omitBy, pick } from 'lodash'

import { FORM_ID } from '../../../constants'
import { FormState } from '../../../types/interface/form.interface'
import { QuoteType, QuoteUpdateInput } from '../../../types/generated/graphql'
import { QuoteUpdateValues } from '../../../types/interface/quote.interface'
import {
  selectedQuoteGetterLocal,
  updateQuoteSetter,
  updateQuoteSetterLocal,
} from '../../../graphql/quote'
import { getFlowName, getProductName } from '@dg-util'
import FormConfig from 'FormConfig'

export interface HookChange {
  loading: boolean
  quote: QuoteType | null
  formBtnDisabled: boolean
  handleQuoteUpdate(values: QuoteUpdateInput): void
  updateFormState(data: FormState<QuoteUpdateInput>): void
  initialFormValues: Record<string, unknown>
}

const useLogic = (client: ApolloClient<NormalizedCacheObject>): HookChange => {
  const [loading, setLoading] = useState<boolean>(true)
  const [quote, setQuote] = useState<QuoteType | null>(null)
  const [initialFormValues, setInitialFormValues] = useState<Record<string, unknown>>()
  const [formBtnDisabled, setFormBtnDisabled] = useState(true)

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = async () => {
    const quote = await fetchQuote()
    formInitialValues(quote)
    setLoading(false)
  }

  const fetchQuote = async () => {
    const localData = await selectedQuoteGetterLocal(client)

    setQuote(localData)
    return localData
  }

  const updateQuote = async (variables: QuoteUpdateValues) => {
    //FYI: Please, take a look at this thread: https://github.com/apollographql/apollo-link/issues/268
    try {
      const result = await updateQuoteSetter(client, variables)
      await updateQuoteSetterLocal(client, result)
      await fetchData()
    } catch (e) {
      return
    }
  }

  const handleQuoteUpdate = async (values: QuoteUpdateInput) => {
    setFormBtnDisabled(true)
    setLoading(true)

    const variables = {
      id: (quote as QuoteType).id,
      quoteInput: values,
    }

    await updateQuote(variables)
  }

  const updateFormState = (data: FormState<QuoteUpdateInput>) => {
    const { pristine, values } = data

    if (!pristine) {
      setFormBtnDisabled(false)
    }
    if (quote) {
      setQuote(Object.assign({}, quote, values))
    }
  }

  const formInitialValues = (quote: QuoteType) => {
    const config = get(FormConfig, [getFlowName(), FORM_ID.CHANGE, getProductName()])

    return setInitialFormValues(omitBy(pick(quote, Object.keys(config.fields)), isNil))
  }

  return {
    loading,
    quote,
    formBtnDisabled,
    initialFormValues,
    handleQuoteUpdate,
    updateFormState,
  }
}

export default useLogic

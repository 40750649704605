import { useEffect, useState } from 'react'
// eslint-disable-next-line import/no-named-as-default
import ApolloClient from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import format from 'date-fns/format'

import {
  FORMAT_DAY_MONTH_YEAR,
  FORMAT_YEAR_MONTH_DAY_HYPHEN,
  TRANSLATIONS as T,
} from '../../../constants'
import { Events, FSMCommonTransitions as FCT } from '../../../FSM/shared/constants'
import { MileageFormValues } from '../../../types/interface/form.interface'
import { QuoteAccepted } from '../../../types/generated/graphql'
import { signOutGetter } from '../../../graphql/auth/signOut.getter'
import { getQuoteAcceptedLocal } from '../../../graphql/quote'
import { removeAllSpaces } from '../../../service/util.service'
import { fullStorageCleanup, setPageError } from '@dg-util'
import { TranslatedTextType, useTranslate } from '@dg-shared'
import { PartnerName } from '../../../types/interface/quote.interface'
import { FSMStateType, ServiceSend } from '../../../types/interface/fsm.interface'
import { setMileageMutator } from '../../../graphql/quote/mileage.setter'

interface HookDone {
  loading: boolean
  signOut(): void
  mileageSubmit(value: MileageFormValues): void
  getText(): {
    title: TranslatedTextType
    subtitle: TranslatedTextType
    description: TranslatedTextType | null
  }
}

//TODO: Define the type
const useLogic = (
  client: ApolloClient<NormalizedCacheObject>,
  send: ServiceSend,
  currentState: FSMStateType
): HookDone => {
  const { t } = useTranslate(T.OFFER_PURCHASED)
  const [loading, setLoading] = useState<boolean>(true)
  const [quote, setQuote] = useState<QuoteAccepted | null>(null)

  useEffect(() => {
    ;(async () => {
      await fetchData()
    })()
    // TODO: FIx with using correct deps in [] and useCallback. Left here to not break anything
    // eslint-disable-next-line
  }, [])

  const fetchData = async () => {
    await fetchQuote()
    setLoading(false)
  }

  const fetchQuote = async () => {
    const [quote, err] = await getQuoteAcceptedLocal(client)

    if (err) {
      setPageError(send, err, currentState)
      setLoading(false)
    }

    setQuote(quote)
  }

  const signOut = async () => {
    const [, err] = await signOutGetter(client)

    if (err) {
      setPageError(send, err, currentState)
      setLoading(false)
      return
    }

    await fullStorageCleanup(client)
  }

  const mileageSubmit = async ({ mileage, readDate }: MileageFormValues) => {
    setLoading(true)
    const mileageFormatted = parseInt(removeAllSpaces(mileage), 10)
    const dateFormatted = format(new Date(readDate), FORMAT_YEAR_MONTH_DAY_HYPHEN)

    await setMileageMutator(client, mileageFormatted, dateFormatted, quote.policyNumber)
    setLoading(false)
    send(Events.DONE)
  }

  const dateFormatsMap = {
    [PartnerName.circlek]: FORMAT_YEAR_MONTH_DAY_HYPHEN,
    [PartnerName.instabank]: FORMAT_DAY_MONTH_YEAR,
    [PartnerName.digisure]: FORMAT_DAY_MONTH_YEAR,
  }
  const dateFormat = dateFormatsMap[PARTNER_NAME as PartnerName] || FORMAT_DAY_MONTH_YEAR
  const startDate: string = quote?.startDate ? format(new Date(quote.startDate), dateFormat) : ''

  const getText = () => {
    const donePage = currentState[FCT.PURCHASED as keyof FSMStateType] === FCT.PURCHASED_DONE
    const failurePage = currentState[FCT.PURCHASED as keyof FSMStateType] === FCT.PURCHASED_FAILURE

    return {
      title: failurePage ? t('fail.title') : t('title'),
      subtitle: failurePage ? null : t('subtitle', { startDate }),
      description: donePage ? t('done.description') : null,
    }
  }

  return {
    loading,
    signOut,
    mileageSubmit,
    getText,
  }
}

export default useLogic

import { CTAContext } from '../types/interface/cta.interface'
import { SIGN_IN_BANK_ID } from '../constants/route-constants'
import { TRANSLATIONS as T } from '../constants/translations-constants'
import BrandConfig from 'BrandConfig'

export const ERROR_QUOTE_CONSTRAINTS = 'ERROR_QUOTE_CONSTRAINTS'
export const ERROR_GENERAL = 'ERROR_GENERAL'
export const ERROR_QUOTE_VALIDATION = 'ERROR_QUOTE_VALIDATION'

export const CTA_TYPE_PROFILE = 'PROFILE'
export const CTA_TYPE_INSTABANK_LINK = 'INSTABANK_LINK'
export const CTA_TYPE_PURCHASED_DONE = 'PURCHASED_DONE'
export const CTA_TYPE_TIMELINE = 'TIMELINE'

export const CTA_CONTEXT: CTAContext = {
  PROFILE: {
    btnText: `${T.COMMON}:noData.btnText`,
    ctaText: `${T.COMMON}/CTA.question`,
    linkText: `${T.COMMON}/CTA.link`,
    linkPath: BrandConfig.SUPPORT_LINK,
    returnUrl: SIGN_IN_BANK_ID,
  },
  INSTABANK_LINK: {
    btnText: `${T.OFFER_PURCHASED}:CTA.link`,
    ctaText: `${T.COMMON}/CTA.question`,
    linkText: `${T.COMMON}/CTA.link`,
    linkPath: BrandConfig.SUPPORT_LINK,
    blank: true,
    returnUrl: '',
  },
  PURCHASED_DONE: {
    btnText: `${T.OFFER_PURCHASED}:CTA.link`,
    ctaText: `${T.OFFER_PURCHASED}:CTA.question`,
    linkText: `${T.OFFER_PURCHASED}:CTA.link`,
    emailText: `${T.OFFER_PURCHASED}:CTA.contactEmail`,
    linkPath: BrandConfig.SUPPORT_LINK,
    blank: true,
    returnUrl: '',
  },
  TIMELINE: {
    btnText: `CTA.link`,
    ctaText: `CTA.question`,
    linkText: `CTA.link`,
    linkPath: BrandConfig.SUPPORT_LINK,
    returnUrl: '',
  },
}

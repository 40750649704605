import React, { FC } from 'react'
import 'date-fns'
import { get } from 'lodash'
import DateFnsUtils from '@date-io/date-fns'
import { FieldRenderProps } from 'react-final-form'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { FormControl, FormHelperText, InputLabel } from '@material-ui/core'

import { IconType } from '../../../types/interface/icon.interface'
import { BrandIconShared } from '@dg-shared'
import { ParsableDate } from '@material-ui/pickers/constants/prop-types'

const useRFFCustomDatePickerStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      display: 'flex',
      marginBottom: theme.spacing(3),
    },
    inputLabel: {
      position: 'relative',
      color: theme.palette.info.dark,
      fontSize: theme.spacing(2),
      lineHeight: 1.4, // 22
    },
    datePicker: {
      display: 'flex',
      '& > div': {
        color: theme.palette.primary.dark,
      },
      marginTop: theme.spacing(2),
      marginBottom: 0,
    },
  })
)

const RFFCustomDatePicker: FC<FieldRenderProps<ParsableDate>> = ({
  input,
  meta,
  labelId,
  fieldLabel,
  fieldPlaceholder,
  fieldMinDate,
  fieldMaxDate,
  fieldInputVariant,
  formatType,
  validationArgs,
  iconColor,
}) => {
  const classes = useRFFCustomDatePickerStyles()
  const isError: boolean = (meta.error || meta.submitError) && !meta.pristine

  // Use calculated validation min date or default fieldMinDate
  const minDate = get(validationArgs, 'min') || fieldMinDate

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <FormControl error={isError} className={classes.formControl}>
        {!!fieldLabel && (
          <InputLabel id={labelId} className={classes.inputLabel} shrink>
            {fieldLabel}
          </InputLabel>
        )}
        <KeyboardDatePicker
          disableToolbar
          type={input.type || 'text'}
          variant='inline'
          inputVariant={fieldInputVariant || 'standard'}
          format={formatType}
          margin='normal'
          value={input.value || null}
          onChange={input.onChange}
          KeyboardButtonProps={{
            'aria-label': fieldLabel,
          }}
          className={classes.datePicker}
          placeholder={fieldPlaceholder}
          minDate={minDate || undefined} // null does not for datepicker
          maxDate={fieldMaxDate || undefined}
          helperText={''} // Need to disable component validation
          autoOk={true}
          keyboardIcon={<BrandIconShared type={IconType.CALENDAR} color={iconColor} />}
        />
        <FormHelperText>{meta.error}</FormHelperText>
      </FormControl>
    </MuiPickersUtilsProvider>
  )
}

export default RFFCustomDatePicker

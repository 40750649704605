import React, { FC, useEffect } from 'react'
import { get } from 'lodash'
import { useApolloClient } from '@apollo/react-hooks'

import CONFIG from '../../../config'
import { Events } from '../../../FSM/shared/constants'
import { authByBankId } from '../../../graphql/auth/authByBankId.query'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { fullStorageCleanup, redirect } from '@dg-util'
import LoadingPage from '../../qb/Loading/Page'

const Page: FC<CommonPageProps> = ({ service }) => {
  const client = useApolloClient()

  const getFormUrl = async () => {
    const link = await authByBankId(client)
    redirect(link)
  }

  const signOut = async (): Promise<void> => {
    const previousAction = get(service.state, ['transitions', '0', 'actions', '0'])
    const needSignOut = get(previousAction, 'type') === 'signOut'

    if (needSignOut) {
      sessionStorage.removeItem(CONFIG.USER_INFO_KEY_BID)
      await fullStorageCleanup(client)
    }
  }

  const getData = async () => {
    await signOut()
    await getFormUrl()
    service.send(Events.NEXT)
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <LoadingPage />
}

export default Page

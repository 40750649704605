import React, { FC } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { TRANSLATIONS as T } from '../../../constants'
import {
  withHidden,
  Typography,
  LMBgHeader,
  LDCardTwoCol,
  FSMBtnNext,
  useTranslate,
} from '@dg-shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events } from '../../../FSM/shared/constants'
import { roadsideAssistanceIcon, logoMax, logoMin } from 'BrandedImages'
import BrandConfig from 'BrandConfig'

const MobileScreen = withHidden(LMBgHeader, true)
const DesktopScreen = withHidden(LDCardTwoCol, false)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobHeaderContainer: {
      justifyContent: 'space-between',
    },
    mobContent: {
      justifyContent: 'space-evenly !important',
    },
    button: {
      fontSize: '1rem',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.contrastText,
      maxWidth: '345px',
      margin: '0 auto',
    },
    subtitle: {
      marginBottom: 'auto',
    },
    iconLink: {
      margin: '20px auto auto auto',
      textDecoration: 'none',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    icon: {
      width: '104px',
    },
  })
)

const PageRoadAssistance: FC<CommonPageProps> = ({ service }) => {
  const { t } = useTranslate(T.ROADSIDE_ASSISTANCE)
  const { send } = service
  const classes = useStyles()
  const subtitle = t('subtitle')

  const iconLinkPath = BrandConfig.FNOL.PageRoadsideAssistance.iconLink

  const Content = () => (
    <>
      <p className={classes.subtitle}>
        <Typography component='span'>{subtitle[0]} </Typography>
        <Typography component='a' href={`tel:${subtitle[1]}`} modifiers={['bold', 'unDecorated']}>
          {subtitle[1]}
        </Typography>
        <Typography component='span'> {subtitle[2]}</Typography>
      </p>
      <a href={iconLinkPath} className={classes.iconLink}>
        <Typography color='main' modifiers={['bold']}>
          {t('body')}
        </Typography>
        <img className={classes.icon} src={roadsideAssistanceIcon} alt={t('title') as string} />
      </a>
      <FSMBtnNext
        testId='next-button'
        buttonText={t(`${T.COMMON}/finished`)}
        handleClick={() => send(Events.NEXT)}
      />
    </>
  )

  return (
    <>
      <MobileScreen
        headerTitle={t('title')}
        textLogoAlt={t('title')}
        headerContainerClass={classes.mobHeaderContainer}
        pageClass={classes.mobContent}
        imageLogo={logoMin}
        brandPageBackground='roadsideAssistance'
      >
        <Content />
      </MobileScreen>

      <DesktopScreen
        imageLogo={logoMax}
        textLogoAlt={t('title')}
        brandPageBackground='roadsideAssistance'
        brandPageBackgroundCard='roadsideAssistance'
      >
        <Typography type='h2'>{t('title')}</Typography>
        <Content />
      </DesktopScreen>
    </>
  )
}

export default PageRoadAssistance

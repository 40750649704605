import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Link, IconButton } from '@material-ui/core'

import { TRANSLATIONS as T } from '../../constants/translations-constants'
import { IconType } from '../../types/interface/icon.interface'
import { useTranslate } from './hooks'

import { BrandIconShared, TranslatedTextType, Typography } from '@dg-shared'
import { isArray } from 'lodash'
import { PartnerName } from '../../types/interface/quote.interface'
import { TypographyModifierType } from '@dg-shared/typography/Typography'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customIconPosition: {
      fontSize: 'inherit',
      color: theme.palette.primary.dark,
      [theme.breakpoints.down('sm')]: {
        marginLeft: '-8px',
      },
    },
  })
)

// We need this flow types and new property here to distinguish
// between two different scopes in one application (Q&B and FNOL).
// This goTo property basically says that if it present - use
// inner application routing. In case it's undefined - all cases
// are logout. However, because we have two scopes (Q&B and FNOL)
// in the context of reusable BtnBackV2 component we need to know
// to which page to redirect. So, in case we will specify the page
// on the layout level, we will have broken logic with signOut
// request.

export interface FSMBtnBackProps {
  buttonText?: TranslatedTextType
  customClass?: string
  handleBack(): void
}

const FSMBtnBack: FC<FSMBtnBackProps> = ({ buttonText, handleBack, customClass }) => {
  const { t } = useTranslate()
  const classes = useStyles()
  const btnText = buttonText || t(`${T.COMMON}/back`)
  const brandModifiersMap: Record<PartnerName, TypographyModifierType[]> = {
    [PartnerName.circlek]: ['bold'],
    [PartnerName.instabank]: [],
    [PartnerName.digisure]: [],
  }

  const brandText = (
    <Typography
      component='span'
      color='textMain'
      modifiers={brandModifiersMap[PARTNER_NAME as PartnerName]}
    >
      {btnText}
    </Typography>
  )

  return (
    <IconButton
      onClick={handleBack}
      aria-label={isArray(btnText) ? btnText[0] : btnText}
      size='small'
      className={customClass}
    >
      <BrandIconShared type={IconType.CHEVRON_LEFT} customClass={classes.customIconPosition} />
      <Link variant='body1' color='textPrimary'>
        {brandText}
      </Link>
    </IconButton>
  )
}

export default FSMBtnBack

import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Grid, Hidden } from '@material-ui/core'

import { FlowType } from '../../../constants'
import { StylesProps } from '../../../types/interface/styles.interface'
import { BrandedHeaderShared, FSMBreadcrumbs, TranslatedTextType } from '@dg-shared'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: (props: StylesProps) => ({
      minHeight: '100vh',
      backgroundColor: props.backgroundColor, // FIXME: Remove this prop when Background color will be the same on all pages
      justifyContent: 'center',
      alignContent: 'start',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        minWidth: '750px',
        paddingBottom: theme.spacing(5),
      },
    }),
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        maxWidth: '968px',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      height: '80px',
      justifyContent: 'space-around',
    },
  })
)

export interface LDContainerV2PropsType {
  flow: FlowType
  classCustom?: string
  breadcrumbsBtnText?: TranslatedTextType
  breadcrumbsBtnPath?: string
  breadcrumbsStepPercentage?: number
  breadcrumbs?: boolean
  bgColor?: string
}

const LDContainerV2: FC<LDContainerV2PropsType> = ({
  children,
  breadcrumbsBtnText,
  breadcrumbsStepPercentage,
  breadcrumbs = false,
  bgColor = '#FFF', // FIXME: Remove this prop when Background color will be the same on all pages
}) => {
  const classes = useStyles({ backgroundColor: bgColor })

  return (
    <Grid container component='article' className={classes.container}>
      <Hidden mdUp={false} smDown={true}>
        <Grid item xs={12} className={classes.header}>
          <BrandedHeaderShared />
        </Grid>
      </Hidden>

      <Grid item xs={12}>
        {/* //FIXME: Remove !!breadcrumbsBtnText from the logic expression in case
          // to simplify the logic. For now we need it to avoid regression */}
        {/* {(breadcrumbs || !!breadcrumbsBtnText) && (
          <BreadcrumbsV2
            flow={flow}
            goTo={breadcrumbsBtnPath}
            buttonText={breadcrumbsBtnText}
            stepsPercentage={breadcrumbsStepPercentage}
          />
        )} */}
        {breadcrumbs && (
          <FSMBreadcrumbs
            buttonText={breadcrumbsBtnText}
            stepsPercentage={breadcrumbsStepPercentage}
          />
        )}
      </Grid>

      <Grid item xs={12} className={classes.bodyContainer}>
        {children}
      </Grid>
    </Grid>
  )
}
export default LDContainerV2

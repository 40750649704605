import { isNil } from 'lodash'
import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { SET_PERSON_EMAIL } from '../partyPerson.mutation'
import { PartyInput } from '../../types/generated/graphql'

export const setUserEmailMutator = async (
  client: ApolloClient<NormalizedCacheObject>,
  partyInput: PartyInput,
  rName = 'setUserEmail'
): Promise<number | null> => {
  if (isNil(partyInput.email)) return null

  const response = await client.mutate({
    mutation: SET_PERSON_EMAIL,
    variables: {
      partyInput,
    },
  })

  return response.data[rName]
}

import { useState, useEffect, useCallback, useRef } from 'react'
import { useSelector } from '@xstate/react'
import { ApolloClient } from 'apollo-client'
import { get, has } from 'lodash'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import CONFIG from '../../../config'
import { Events } from '../../../FSM/shared/constants'
import { BUILD_CONSTANTS as BC } from '../../../constants'
import { FlowServiceType } from '../../../types/interface/fsm.interface'
import { UserAuthInputNo, UserAuthInputSe } from '../../../types/generated/graphql'
import { authMutator } from '../../../graphql/auth/auth.mutator'
import { selectedQuoteSetterLocal } from '../../../graphql/quote'
import { signOutGetter } from '../../../graphql/auth/signOut.getter'
import { setItem, getUserInputParams, normalizeProductId, fullStorageCleanup } from '@dg-util'
import BrandConfig from 'BrandConfig'

import { httpClientGet as getVippsLink } from '../../../service/request.service'

interface HookAuth {
  loading: boolean
  errorType: string
  errorVipps: boolean
  vippsLink: string
  authHandler(data: UserAuthInputNo | UserAuthInputSe): void
}

let isReCaptchaReady = false

const getReCaptchaToken = async (isReCaptchaReady: boolean, key: string) => {
  if (!isReCaptchaReady) return

  try {
    return (await window.grecaptcha.execute(key, { action: 'submit' })) as string
  } catch (e) {
    console.log('Oops...')
    return
  }
}

const useLogic = (
  client: ApolloClient<NormalizedCacheObject>,
  service: FlowServiceType
): HookAuth => {
  const [loading, setLoading] = useState<boolean>(true)
  const [errorVipps, setErrorVipps] = useState<boolean>(false)
  const [vippsLink, setVippsLink] = useState<string>('')
  const eventType = useSelector(service, (state) => state.event.type)
  const { VIPPS_AUTH } = BrandConfig
  const mounted = useRef(false)

  const authHandler = useCallback(
    async (data: UserAuthInputNo | UserAuthInputSe) => {
      const reCaptchaToken = await getReCaptchaToken(isReCaptchaReady, CONFIG.RECAPTCHA_SITE_KEY)
      setLoading(true)

      const dataSession = await authMutator(client, getUserInputParams(data), reCaptchaToken)

      if (!dataSession) {
        return setLoading(false)
      }

      setItem(dataSession.token, dataSession.kid)

      if (has(data, ['regNum'])) {
        await selectedQuoteSetterLocal(client, normalizeProductId(get(data, 'regNum')))
      }

      setLoading(false)
      service.send(Events.NEXT)
    },
    // TODO: FIx with using correct deps in [] and useCallback. Left here to not break anything
    // eslint-disable-next-line
    [client]
  )

  const getVipps = async () => {
    const getVippsUrl = `${BC.REST_URL}/get-vipps-link?cid=${PARTNER_NAME}`
    const { vLink, errVipps } = await getVippsLink(getVippsUrl, { credentials: 'include' })
    const error = !!errVipps

    return error ? setErrorVipps(error) : setVippsLink(vLink)
  }

  const resetClient = useCallback(async () => {
    await fullStorageCleanup(client)

    if (window.grecaptcha.ready) {
      isReCaptchaReady = true
    }
  }, [client])

  const signOut = async () => {
    const previousAction = get(service.state, ['transitions', '0', 'actions', '0'])

    if (get(previousAction, 'type') === 'signOut') {
      await signOutGetter(client)
    }
  }

  useEffect(() => {
    mounted.current = true

    signOut()

    resetClient()
      .then(async () => {
        // Use Vipps auth if it is enabled on a brand level
        if (VIPPS_AUTH) {
          await getVipps()
        }
      })
      .finally(() => {
        // This prevents of state update on unmounted component
        if (mounted.current) {
          setLoading(false)
        }
      })

    return () => {
      setLoading(false)
      mounted.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetClient])

  return {
    errorType: eventType === Events.BACK ? null : eventType,
    loading,
    errorVipps,
    vippsLink,
    authHandler,
  }
}

export default useLogic

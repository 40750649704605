import React, { FC, useState } from 'react'
import { useApolloClient } from '@apollo/react-hooks'

import { FNOL_FORM_ID as FORM_ID, QUESTIONS, TRANSLATIONS as T } from '../../../constants'
import { Events } from '../../../FSM/shared/constants'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import {
  initiatedClaimGetterLocal,
  selectedClaimGetterLocal,
} from '../../../graphql/claim/local-selected.getter'
import { initMutator } from '../../../graphql/claim/init.mutator'
import { updateMutator } from '../../../graphql/claim/local-update.mutator'
import { useTranslate, FNOLPageWrapper, Typography } from '@dg-shared'
import FormBuilder from '@dg-shared/forms/FormBuilder'
import { useFNOLStyles } from './styles/useStyles'
import RFFCustomImageUpload, { Image } from '@dg-shared/forms/RFFCustomFileUpload'
import { uploadFiles } from '../../../graphql/claim/uploadFiles.mutator'

const PageAdditionalInfo: FC<CommonPageProps> = ({ service }) => {
  const client = useApolloClient()
  const { t } = useTranslate(T.FNOL_OTHER_ADDITIONAL_INFO)
  const FNOLClasses = useFNOLStyles({ externalLink: true })

  const [images, setImages] = useState<Array<Image>>([])

  const handleSubmit = async ({ additionalInfo }: Record<string, string>) => {
    await submitImages()
    await updateMutator(client, {
      [QUESTIONS.ANY_OTHER_INFORMATION_WE_SHOULD_KNOW_OF]: additionalInfo,
    })

    const { policyNumber, licensePlateNumber } = await selectedClaimGetterLocal(client)
    const questions = await initiatedClaimGetterLocal(client)

    await initMutator(client, policyNumber, licensePlateNumber, questions)

    service.send(Events.NEXT)
  }

  const submitImages = async () => {
    const imageList = images.map((el) => el.data_url)
    await uploadFiles(client, imageList)
  }

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t('common/back')} breadcrumbsStepPercentage={95}>
      <Typography type='h1' className={FNOLClasses.title} testId={'title'}>
        {t('title')}
      </Typography>
      <Typography className={FNOLClasses.description} testId={'subtitle'}>
        {t('subtitle')}
      </Typography>
      <FormBuilder
        id={FORM_ID.ADDITIONAL_INFO}
        classes={FNOLClasses.formContainer}
        submitHandler={handleSubmit}
        optional
      >
        <RFFCustomImageUpload images={images} setImages={setImages} />
      </FormBuilder>
    </FNOLPageWrapper>
  )
}

export default PageAdditionalInfo

import { gql } from 'graphql-tag'

export const FRAGMENT_VEHICLE_SE_ITEMS = gql`
  fragment VehicleSeItems on VehicleSe {
    country
    productName

    id

    prevInsuranceCompanyId
    policyStartDate
    coverageType
    mileageAnnual
    ageLimit
    superCover
    deductible

    priceAnnual
    priceMonthly
    priceTotal

    productId
    carBrand
    carModel
    carYear

    canVerify
    canComparePrice
    displayPrevCompanyField
    encrypted {
      iv
      content
    }
  }
`

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO: All file should be reviewed and deleted/refactored in scope of FNOL cleanup
import React, { FC, useState, useEffect } from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { endOfDay, startOfDecade } from 'date-fns'
import { get } from 'lodash'

import { ApolloClient } from 'apollo-client'
import { FNOL_FORM_ID, QUESTIONS } from '../../constants'
import { MapResult } from '../../types/interface/map.interface'
import { updateMutator } from '../../graphql/claim/local-update.mutator'
import { initiatedClaimGetterLocal } from '../../graphql/claim/local-selected.getter'
import { formatMapLocations } from '../../service/util.service'
import { RFFCustomLocationButton, MapComponent } from '@dg-shared'
import FormBuilder from '@dg-shared/forms/FormBuilder'
import { ServiceSend } from '../../types/interface/fsm.interface'
import { Events } from '../../FSM/shared/constants'

const useDocumentStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      width: '100%',
      textAlign: 'left',
      [theme.breakpoints.up('md')]: {
        maxWidth: '325px',
        margin: 'auto',
        marginTop: theme.spacing(6),
      },
    },
  })
)

interface AccidentFormValues {
  occurDate?: Date
  country?: string
  city?: string
  address?: string
}

interface AccidentFormProps {
  send: ServiceSend
}

const WhenWhereForm: FC<AccidentFormProps> = ({ send }) => {
  const classes = useDocumentStyles()
  const client = useApolloClient()

  const [selectedDataFields, setSelectedDataFields] = useState<AccidentFormValues>({})
  const [mapIsOpened, setMapIsOpened] = useState(false)

  const formatDataFromState = (data: Record<string, unknown>): AccidentFormValues | null => {
    const addressKey = QUESTIONS.WHERE_DID_IT_HAPPENED
    const dateKey = QUESTIONS.WHEN_DID_IT_HAPPENED

    const formattedAddress = get(data, addressKey, null)

    if (!formattedAddress) return null

    const [country, city, address] = formattedAddress.split(',')

    return {
      address: address,
      city: city,
      country: country,
      occurDate: data[dateKey],
    }
  }

  const getClaimLocal = async (client: ApolloClient<NormalizedCacheObject>) => {
    const [dataSelected] = await initiatedClaimGetterLocal(client)

    const formattedData = formatDataFromState(dataSelected)
    formattedData && setSelectedDataFields(formattedData)
  }

  useEffect(() => {
    ;(async () => {
      await getClaimLocal(client)
    })()
    // TODO: FIx with using correct deps in [] and useCallback. Left here to not break anything
    // eslint-disable-next-line
  }, [])

  const handleMapResult = (res: MapResult | null): void => {
    if (!res || !res?.address_components) {
      setMapIsOpened(false)
      return
    }

    const locations = formatMapLocations(res.address_components)
    setSelectedDataFields((prev) => ({ ...prev, ...locations }))
    setMapIsOpened(false)

    //FIXME: Remove later. That's mocked data just for development
    // const locations = {
    //   address: 'Test street, 66',
    //   city: 'Oslo',
    //   country: 'Norway',
    // }
  }

  const handleSubmit = async ({ address, city, country, occurDate }: AccidentFormValues) => {
    const fullAddress = `${country}, ${city}, ${address}`

    await updateMutator(client, {
      [QUESTIONS.WHEN_DID_IT_HAPPENED]: occurDate,
      [QUESTIONS.WHERE_DID_IT_HAPPENED]: fullAddress,
    })

    send(Events.NEXT)
  }

  if (mapIsOpened) {
    return <MapComponent handleClick={handleMapResult} />
  }

  return (
    <section className={classes.formContainer}>
      <RFFCustomLocationButton clickHandler={() => setMapIsOpened(true)} />
      <FormBuilder
        id={FNOL_FORM_ID.ACCIDENT}
        validationArgs={{ minDate: startOfDecade(new Date()), maxDate: endOfDay(new Date()) }}
        setFormState={({ values }) => setSelectedDataFields({ ...selectedDataFields, ...values })}
        submitHandler={handleSubmit}
        filledFieldsData={selectedDataFields}
        isFormControllable
      />
    </section>
  )
}

export default WhenWhereForm

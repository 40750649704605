import { gql } from 'graphql-tag'

import { FRAGMENT_VEHICLE_NO_ITEMS } from '../fragments/QuoteVehicleItems.fragment'
import { FRAGMENT_VEHICLE_SE_ITEMS } from '../fragments/QuoteVehicleSeItems.fragment'
import { FRAGMENT_TRAVEL_NO_ITEMS } from '../fragments/QuoteTravelItems.fragment'

export const INIT_QUOTE = gql`
  query InitQuote($productId: String) {
    initQuote(productId: $productId) {
      ... on VehicleNo {
        ...VehicleNoItems
      }
      ... on VehicleSe {
        ...VehicleSeItems
      }
      ... on TravelNo {
        ...TravelNoItems
      }
    }
  }
  ${FRAGMENT_VEHICLE_NO_ITEMS}
  ${FRAGMENT_VEHICLE_SE_ITEMS}
  ${FRAGMENT_TRAVEL_NO_ITEMS}
`
//FIXME: Temporary solution for SWEDISH PARTNER ONLY.
// Remove when payment for Sweden is integrated.
export const ACCEPT_QUOTE = gql`
  query AcceptQuote {
    accept {
      id
      policyNumber
      startDate
    }
  }
`

export const GET_DRAFTS = gql`
  query Quotes {
    quotes @client(always: true) {
      drafts {
        ... on VehicleNo {
          ...VehicleNoItems
        }
        ... on VehicleSe {
          ...VehicleSeItems
        }
        ... on TravelNo {
          ...TravelNoItems
        }
      }
    }
  }
  ${FRAGMENT_VEHICLE_NO_ITEMS}
  ${FRAGMENT_VEHICLE_SE_ITEMS}
  ${FRAGMENT_TRAVEL_NO_ITEMS}
`

export const GET_QUOTE_SELECTED = gql`
  query Quotes {
    quotes @client(always: true) {
      selected {
        ... on VehicleNo {
          ...VehicleNoItems
        }
        ... on VehicleSe {
          ...VehicleSeItems
        }
        ... on TravelNo {
          ...TravelNoItems
        }
      }
    }
  }
  ${FRAGMENT_VEHICLE_NO_ITEMS}
  ${FRAGMENT_VEHICLE_SE_ITEMS}
  ${FRAGMENT_TRAVEL_NO_ITEMS}
`

export const GET_QUOTE_PRODUCT_ID_SELECTED = gql`
  query Quotes {
    quotes @client(always: true) {
      productId
    }
  }
`

export const GET_QUOTE_SELECTED_ID = gql`
  query Quotes {
    quotes @client(always: true) {
      selected {
        id
      }
    }
  }
`

export const GET_QUOTE_DRAFT_LOCAL = gql`
  query getQuoteDraftLocal {
    getQuoteDraftLocal @client(always: true) {
      ... on VehicleNo {
        ...VehicleNoItems
      }
      ... on VehicleSe {
        ...VehicleSeItems
      }
      ... on TravelNo {
        ...TravelNoItems
      }
    }
  }
  ${FRAGMENT_VEHICLE_NO_ITEMS}
  ${FRAGMENT_VEHICLE_SE_ITEMS}
  ${FRAGMENT_TRAVEL_NO_ITEMS}
`

export const GET_QUOTE_ACCEPTED_LOCAL = gql`
  query Quotes {
    quotes @client(always: true) {
      accepted {
        id
        policyNumber
        startDate
      }
    }
  }
`

import React, { FC } from 'react'

import { Typography, FNOLButton, useTranslate, FNOLPageWrapper } from '@dg-shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events } from '../../../FSM/shared/constants'
import { TRANSLATIONS as T } from '../../../constants'
import { useFNOLStyles } from '../shared/styles/useStyles'

const PageAnimal: FC<CommonPageProps> = ({ service, currentState }) => {
  const FNOLClasses = useFNOLStyles({})
  const { t } = useTranslate(`${T.COLLISION}.animal`)
  const { send } = service

  const itemSelect = ({ eventName, label }: { eventName: Events; label: string }) => (
    <FNOLButton
      key={label}
      testId={label}
      handleClick={() => send({ type: eventName, state: currentState })}
      buttonText={t(label)}
    />
  )

  const eventItems = [
    { eventName: Events.NEXT, label: 'buttons.moose' },
    { eventName: Events.NEXT, label: 'buttons.deer' },
    { eventName: Events.NEXT, label: 'buttons.babyDeer' },
    { eventName: Events.NEXT, label: 'buttons.badger' },
    { eventName: Events.NEXT, label: 'buttons.fox' },
    { eventName: Events.NEXT, label: 'buttons.pets' },
  ]
  const ItemsList = () => <>{eventItems.map((item) => itemSelect(item))}</>

  const Content = () => (
    <>
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('title')}
      </Typography>
      <Typography testId='subtitle' html={true} className={FNOLClasses.description}>
        {t('subtitle')}
      </Typography>
      <ItemsList />
    </>
  )

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t(`${T.COMMON}/back`)} breadcrumbsStepPercentage={35}>
      <Content />
    </FNOLPageWrapper>
  )
}

export default PageAnimal

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { ErrorStylesProps } from '../../../../types/interface/styles.interface'

export const useFNOLStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(2),
    },
    description: {
      marginTop: theme.spacing(2),
    },
    link: (props?: ErrorStylesProps) => ({
      color: theme.brand.colors.secondary,
      textAlign: 'center',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: props?.externalLink ? 'underline' : 'none',
      },
    }),
    formContainer: {
      width: '100%',
      minWidth: 'auto',
      textAlign: 'left',
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(6),
      },
    },
  })
)

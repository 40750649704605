/* eslint-disable import/no-named-as-default-member */
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'

type TResult = {
  obj: string[]
  string: string
}

export type TranslatedTextType = string | string[]

export interface TFunction {
  <Key extends keyof TResult = 'string' | 'obj'>(
    str: string,
    values?: {
      [key: string]: unknown
    },
    type?: Key
  ): TResult[Key]
}

const useTranslate = (page?: string) => {
  const { t } = useTranslation()

  const translate: TFunction = (str = '', values, type) => {
    // Fallback to the null in case that there is no rule.
    const defaultValue = null as TResult[typeof type]

    // FYI: Search in the same namespace, but from other page(object)
    if (str.includes(':')) {
      const [src, key] = str.split(':')
      return i18n.exists(`${src}.${key}`) ? t(`${src}.${key}`, values) : defaultValue
    }

    if (str.includes('/')) {
      // FYI: Search in other namespace
      const [src, key] = str.split('/')
      return i18n.exists(`${src}:${key}`) ? t(`${src}:${key}`, values) : defaultValue
    }

    if (page) {
      return i18n.exists(`${page}.${str}`) ? t(`${page}.${str}`, values) : defaultValue
    }

    return i18n.exists(str) ? t(str, values) : defaultValue
  }

  return {
    t: translate,
  }
}

export default useTranslate

import { isNil } from 'lodash'
import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import { ERROR_CODE_QUOTE_GENERAL } from '../../constants'

import { ErrorCode } from '../../types/interface/error.interface'
import { SET_CLAIM_SELECTED } from './mutation'

export const setClaimSelectedMutator = async (
  client: ApolloClient<NormalizedCacheObject>,
  licensePlateNumber: string,
  policyNumber: string,
  coverageType: number,
  rName = 'setClaimSelectedLocal'
): Promise<[Record<string, unknown> | null, ErrorCode | null]> => {
  const response = await client.mutate({
    mutation: SET_CLAIM_SELECTED,
    variables: {
      licensePlateNumber,
      policyNumber,
      coverageType,
    },
  })

  if (!isNil(response.errors)) return [null, ERROR_CODE_QUOTE_GENERAL]

  return [response.data[rName], null]
}

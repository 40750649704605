import { useState, useEffect, MutableRefObject } from 'react'
import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import { get, has } from 'lodash'
import { startOfDay } from 'date-fns'

import { COOKIE_KID } from '../../../constants/auth-constants'
import { Events } from '../../../FSM/shared/constants'
import CONFIG from '../../../config'
import {
  OfferVerifyFormSubmitData,
  OfferVerifyFormData,
  VerifyFormValues,
  FormState,
} from '../../../types/interface/form.interface'
import { DateState } from '../../../types/interface/dateState.interface'
import { SessionData, SessionDataNoBID } from '../../../types/interface/session.interface'
import { ProductName, PartnerName } from '../../../types/interface/quote.interface'
import { VehicleNo, QuoteType, GraphOptionMap } from '../../../types/generated/graphql'
import { getCookieByName, defineOfferDate, formatPolicyDate, getProductName } from '@dg-util'
import {
  verifyMutator,
  acceptQuoteSetter,
  acceptMutatorLocal,
  selectedQuoteGetterLocal,
} from '../../../graphql/quote'
import { setUserEmailMutator } from '../../../graphql/party/setUserEmail.mutator'
import { getSessionStorageAuthData } from '../../../service/util.service'
import { ServiceSend } from '../../../types/interface/fsm.interface'

interface HookVerify {
  loading: boolean
  btnDisabled: boolean
  formData: OfferVerifyFormData | false
  KID: string | boolean
}

//TODO: Remove send function when payment for Swedish patrner is integrated.
const useLogic = (client: ApolloClient<NormalizedCacheObject>, send: ServiceSend): HookVerify => {
  const [formValue, setFormValue] = useState<VerifyFormValues>({})
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [quote, setQuote] = useState<QuoteType>()
  const [date, setDate] = useState<DateState>({
    min: startOfDay(new Date()),
    max: null,
    selected: null,
  })
  const parsedUserInfo: SessionData = getSessionStorageAuthData(CONFIG.USER_INFO_KEY)
  const KID: string | boolean =
    (!!parsedUserInfo && (parsedUserInfo as SessionDataNoBID).kid) || getCookieByName(COOKIE_KID)
  const isTravel = getProductName() === ProductName.TRAVEL

  const fetchQuote = async () => {
    const quote = await selectedQuoteGetterLocal(client)

    setQuote(quote)
    return quote
  }

  //FIXME: This is start date
  //FIXME: This offset is not offset - something strange
  const defineDate = (quote: QuoteType, nonDefaultCompany?: number) => {
    const definedDate = defineOfferDate(quote, nonDefaultCompany)

    setDate(definedDate)
    setFormValue((prevState) => ({ ...prevState, policyDate: definedDate.selected }))
  }

  //FYI: Set insuranceCompany value to null if we don't display this field. Correct value would be set on BFF
  const defineCompany = (quote: VehicleNo): void => {
    if (!quote.displayPrevCompanyField) {
      setFormValue((prevState) => ({
        ...prevState,
        insuranceCompany: null,
      }))
    }
  }

  const getData = async () => {
    const data: QuoteType = await fetchQuote()
    defineDate(data)
    //TODO: Clarify whether we can receive previous company just for Vehicle product for NO partner. We don't have this field for SE for sure.
    data.__typename === 'VehicleNo' && defineCompany(data as VehicleNo)
    setLoading(false)
  }

  useEffect(() => {
    getData()
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    isTravel && !!quote && defineDate(quote, Number(formValue.insuranceCompany))
    // TODO: FIx with using correct deps in [] and useCallback. Left here to not break anything
    // eslint-disable-next-line
  }, [has(formValue, 'insuranceCompany') && formValue.insuranceCompany])

  const changeHandler = ({ values }: FormState<never>): void => setFormValue(values)

  const handleSubmit = async (
    form: MutableRefObject<HTMLFormElement | null>,
    values: OfferVerifyFormSubmitData
  ) => {
    const insuranceCompanyId = get(values, 'insuranceCompany')
      ? String(values.insuranceCompany)
      : null
    const policyStartDate = formatPolicyDate(values.policyDate)

    setLoading(true)

    await setUserEmailMutator(client, { email: values.email, phoneNumber: values.phone || null })

    await verifyMutator(client, quote?.id, {
      insuranceCompanyId,
      policyStartDate,
    })

    //FIXME: Temporary solution for SWEDISH PARTNER ONLY.
    // Remove when payment for Sweden is integrated.
    if (PARTNER_NAME === PartnerName.circlek) {
      const quoteAccepted = await acceptQuoteSetter(client)
      await acceptMutatorLocal(client, quoteAccepted)
      return send(Events.NEXT)
    }

    setLoading(false)
    form.current?.submit()
    setLoading(true)
  }

  const formData = !!quote && {
    quote,
    companies: [] as Array<GraphOptionMap>,
    handleSubmit,
    changeHandler,
    date,
    formValue,
    handleBtnDisabled: setBtnDisabled,
  }

  return {
    loading,
    KID,
    formData,
    btnDisabled,
  }
}
export default useLogic

import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Hidden, Card, CardMedia, CardContent, Divider } from '@material-ui/core'

import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { OfferItemProps } from '../../../types/interface/quote.interface'
import { VehicleNo } from '../../../types/generated/graphql'
import { Typography, useTranslate, CustomCardHeader } from '@dg-shared'
import { exampleCarImg } from 'BrandedImages'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    twoCol: () => ({
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(5),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        ...theme.brand.assets.images.changeVehicleOwnerPageCard,
      },
    }),
    colLeft: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(2),
      },
      [theme.breakpoints.up('lg')]: {
        marginRight: theme.spacing(4),
      },
    },
    colRight: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(2),
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing(4),
      },
    },
    itemImage: {
      width: '100%',
      maxWidth: '220px',
      margin: 'auto',
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(2),
      },
    },
    cardTitle: {
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(2),
      },
    },
    cardSubTitle: {
      marginBottom: theme.spacing(1.5),
      '& + *': {
        marginBottom: theme.spacing(3),
      },
    },
  })
)

interface OwnershipCardProps extends OfferItemProps {
  itemData: VehicleNo
}

const OfferCard: FC<OwnershipCardProps> = ({
  itemData: { carBrand, carModel, carYear, productId, priceMonthly, priceAnnual, taxAnnual },
  colLeft,
  colRight,
  showDivider,
}) => {
  const { t } = useTranslate(T.COMMON)
  const classes = useStyles()

  return (
    <Card elevation={3}>
      <CustomCardHeader priceMonthly={priceMonthly} priceDetails={taxAnnual || priceAnnual} />
      <CardContent className={classes.twoCol}>
        <section className={classes.colLeft}>
          {/* //NOTE: Need block container for Safari when use forms */}
          <section>
            <CardMedia
              component='img'
              alt='Example Car'
              image={exampleCarImg}
              className={classes.itemImage}
            />
          </section>
          <Typography type='h2' className={classes.cardTitle}>
            {`${carYear} ${carBrand} ${carModel}`}
          </Typography>
          <Typography type='h3' className={classes.cardSubTitle}>
            {t(`registrationNum`, { regNum: productId })}
          </Typography>
          {showDivider ? (
            <Divider />
          ) : (
            <Hidden mdUp>
              <Divider />
            </Hidden>
          )}

          {colLeft}
        </section>

        <Hidden smDown>
          <div className={classes.colRight}>{colRight}</div>
        </Hidden>
      </CardContent>
    </Card>
  )
}

export default OfferCard

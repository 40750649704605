import React, { FC, useMemo } from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { isEmpty } from 'lodash'

import { CTA_TYPE_PROFILE } from '../../../constants/cta-constants'
import { PolicyItem } from '../../../types/interface/claims.interface'
import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import useLogic from './useLogic'
import { sortDocuments } from './utils'

import { useTranslate, NoDataCard, Typography, FNOLPageWrapper } from '@dg-shared'
import Loading from '../../qb/Loading/Page'
import Card from './Card'
import { CommonPageProps } from '../../../types/interface/fsm.interface'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(2),
    },
  })
)

const PageList: FC<CommonPageProps> = ({ service }) => {
  const { t } = useTranslate(T.DOCUMENTS)
  const client = useApolloClient()
  const classes = useStyles()

  const { loading, backBtn, documents, handleClick } = useLogic(client)

  const sortedDocuments = useMemo(() => sortDocuments(documents), [documents])

  const content = !isEmpty(sortedDocuments) ? (
    <div data-testid={'document-list'}>
      {sortedDocuments.map((item: PolicyItem, index: number) => (
        <Card
          key={`Card-${index}`}
          data={item}
          openFile={handleClick}
          docType={t('card.productType')}
        />
      ))}
    </div>
  ) : (
    <NoDataCard send={service.send} type={CTA_TYPE_PROFILE} />
  )

  if (loading) {
    return <Loading />
  }

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t(backBtn.text, {}, 'string')}>
      <Typography className={classes.title} type={'h1'}>
        {t('title')}
      </Typography>
      {content}
    </FNOLPageWrapper>
  )
}

export default PageList
